import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import NextLink from "next/link";
import React from "react";
import { logoColor } from "../colors";

const Footer: React.FC = () => {
  const theme = useTheme();
  const isWideScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const icons = [
    { href: "https://twitter.com/glia_computing", icon: <TwitterIcon /> },
    {
      href: "https://www.facebook.com/%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BEGlia-Computing-2159545567643295",
      icon: <FacebookIcon />,
    },
    {
      href: "https://www.linkedin.com/company/%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BEglia-computing/",
      icon: <LinkedInIcon />,
    },
  ];

  return (
    <Box
      sx={{
        bottom: 0,
        position: "static",
        width: "100%",
        zIndex: 1000,
        alignItems: "center",
        backgroundColor: "#0a0a0a",
        color: "#fff",
        marginTop: "auto",
      }}
    >
      <Container
        sx={{
          display: "flex",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          padding: isWideScreen ? "30px" : "20px 0px",
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: "flex" }} flexGrow={1}>
              <Box
                sx={{
                  flexGrow: isWideScreen ? 0 : 1,
                  display: "flex",
                }}
              >
                {icons.map((icon, i) => {
                  return (
                    <NextLink key={i} href={icon.href} passHref>
                      <IconButton
                        color="inherit"
                        sx={{
                          paddingLeft: "5px",
                          paddingRight: "5px",
                          ":hover": { color: logoColor },
                        }}
                      >
                        {icon.icon}
                      </IconButton>
                    </NextLink>
                  );
                })}
              </Box>
              <Box
                sx={{
                  flexGrow: 0,
                  display: "flex",
                }}
              >
                <NextLink
                  href={"https://www.glia-computing.com/privacy-policy.pdf"}
                  passHref
                >
                  <Button
                    sx={{
                      textTransform: "none",
                      color: "inherit",
                      fontSize: "12px",
                      ":hover": { color: logoColor },
                    }}
                  >
                    {"Privacy Policy"}
                  </Button>
                </NextLink>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: isWideScreen ? "end" : "center",
                alignItems: "center",
                height: "100%",
                ":hover": { color: logoColor },
              }}
              flexGrow={1}
            >
              <NextLink href="https://www.glia-computing.com/" passHref>
                <Button
                  sx={{
                    textTransform: "none",
                    color: "inherit",
                    fontSize: "12px",
                  }}
                >
                  {"© Glia Computing Co., Ltd. "}
                  {new Date().getFullYear()}
                </Button>
              </NextLink>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
