import Box from "@mui/material/Box";
import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const Layout: React.FC = ({ children }) => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <Box marginTop={"71.1875px"} />
      {children}
      <Footer />
    </Box>
  );
};

export default Layout;
