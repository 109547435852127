import { GraphQLClient } from "graphql-request";
import * as Dom from "graphql-request/dist/types.dom";
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  UUID: any;
  _uuid: any;
  interval: any;
  jsonb: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["Boolean"]>;
  _gt?: InputMaybe<Scalars["Boolean"]>;
  _gte?: InputMaybe<Scalars["Boolean"]>;
  _in?: InputMaybe<Array<Scalars["Boolean"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["Boolean"]>;
  _lte?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Scalars["Boolean"]>;
  _nin?: InputMaybe<Array<Scalars["Boolean"]>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["Int"]>;
  _gt?: InputMaybe<Scalars["Int"]>;
  _gte?: InputMaybe<Scalars["Int"]>;
  _in?: InputMaybe<Array<Scalars["Int"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["Int"]>;
  _lte?: InputMaybe<Scalars["Int"]>;
  _neq?: InputMaybe<Scalars["Int"]>;
  _nin?: InputMaybe<Array<Scalars["Int"]>>;
};

/** mutation root */
export type Mutation = {
  __typename?: "Mutation";
  /**
   * Archive a flow. This hides it from the Prefect UI and turns off its schedule, but does
   * not delete it or its history. Archived flows cannot be run.
   */
  archive_flow?: Maybe<Success_Payload>;
  /** Cancel a flow run if it's not already finished */
  cancel_flow_run?: Maybe<Cancel_Flow_Run_Payload>;
  /** Create a new agent config */
  create_agent_config?: Maybe<Agent_Config_Id_Payload>;
  /** Create a new hook for state change notifications. */
  create_cloud_hook?: Maybe<Cloud_Hook_Id_Payload>;
  /** Create a new flow from a serialized JSON representation. */
  create_flow?: Maybe<Flow_Id_Payload>;
  /** Create a new flow from a compressed JSON representation. Used by Prefect Core. */
  create_flow_from_compressed_string?: Maybe<Flow_Id_Payload>;
  /** Create a new flow run. */
  create_flow_run?: Maybe<Flow_Run_Id_Payload>;
  /** Create a new project. */
  create_project?: Maybe<Project_Id_Payload>;
  /** Create task run artifact. This API is still experimental. */
  create_task_run_artifact?: Maybe<Task_Run_Artifact_Id_Payload>;
  /** Create a new tenant. */
  create_tenant?: Maybe<Tenant_Id_Payload>;
  /** Delete an agent */
  delete_agent?: Maybe<Success_Payload>;
  /** delete single row from the table: "agent" */
  delete_agent_by_pk?: Maybe<Agent>;
  /** Delete an agent config */
  delete_agent_config?: Maybe<Success_Payload>;
  /** delete single row from the table: "agent_config" */
  delete_agent_config_by_pk?: Maybe<Agent_Config>;
  /** Delete a cloud hook. */
  delete_cloud_hook?: Maybe<Success_Payload>;
  /** delete single row from the table: "cloud_hook" */
  delete_cloud_hook_by_pk?: Maybe<Cloud_Hook>;
  /** delete data from the table: "edge" */
  delete_edge?: Maybe<Edge_Mutation_Response>;
  /** delete single row from the table: "edge" */
  delete_edge_by_pk?: Maybe<Edge>;
  /** Delete a flow. */
  delete_flow?: Maybe<Success_Payload>;
  /** delete single row from the table: "flow" */
  delete_flow_by_pk?: Maybe<Flow>;
  /** Delete a flow group. */
  delete_flow_group?: Maybe<Success_Payload>;
  /** delete single row from the table: "flow_group" */
  delete_flow_group_by_pk?: Maybe<Flow_Group>;
  /** Delete a flow group's schedule. */
  delete_flow_group_schedule?: Maybe<Success_Payload>;
  /** Delete a flow run. */
  delete_flow_run?: Maybe<Success_Payload>;
  /** delete single row from the table: "flow_run" */
  delete_flow_run_by_pk?: Maybe<Flow_Run>;
  /** delete data from the table: "flow_run_state" */
  delete_flow_run_state?: Maybe<Flow_Run_State_Mutation_Response>;
  /** delete single row from the table: "flow_run_state" */
  delete_flow_run_state_by_pk?: Maybe<Flow_Run_State>;
  /** delete data from the table: "log" */
  delete_log?: Maybe<Log_Mutation_Response>;
  /** Delete a message. */
  delete_message?: Maybe<Success_Payload>;
  /** delete single row from the table: "message" */
  delete_message_by_pk?: Maybe<Message>;
  /** Delete a project. */
  delete_project?: Maybe<Success_Payload>;
  /** delete single row from the table: "project" */
  delete_project_by_pk?: Maybe<Project>;
  /** delete data from the table: "task" */
  delete_task?: Maybe<Task_Mutation_Response>;
  /** delete single row from the table: "task" */
  delete_task_by_pk?: Maybe<Task>;
  /** delete data from the table: "task_run" */
  delete_task_run?: Maybe<Task_Run_Mutation_Response>;
  /** Delete task run artifact. This API is still experimental. */
  delete_task_run_artifact?: Maybe<Success_Payload>;
  /** delete single row from the table: "task_run_artifact" */
  delete_task_run_artifact_by_pk?: Maybe<Task_Run_Artifact>;
  /** delete single row from the table: "task_run" */
  delete_task_run_by_pk?: Maybe<Task_Run>;
  /** delete data from the table: "task_run_state" */
  delete_task_run_state?: Maybe<Task_Run_State_Mutation_Response>;
  /** delete single row from the table: "task_run_state" */
  delete_task_run_state_by_pk?: Maybe<Task_Run_State>;
  /** Delete a tenant. */
  delete_tenant?: Maybe<Success_Payload>;
  /** delete single row from the table: "tenant" */
  delete_tenant_by_pk?: Maybe<Tenant>;
  /** delete data from the table: "utility.traversal" */
  delete_utility_traversal?: Maybe<Utility_Traversal_Mutation_Response>;
  /** Disable heartbeats for a flow. */
  disable_flow_heartbeat?: Maybe<Success_Payload>;
  /** Disable Lazarus process resurrection for a flow. */
  disable_flow_lazarus_process?: Maybe<Success_Payload>;
  /** Enable heartbeats for a flow. */
  enable_flow_heartbeat?: Maybe<Success_Payload>;
  /** Enable Lazarus process resurrection for a flow. */
  enable_flow_lazarus_process?: Maybe<Success_Payload>;
  /**
   * Create a new task run, if it doesn't exist already. This is useful when dealing with dynamic
   * task runs, such as those created by mapping, when it is not known if the task run has been
   * attempted already.
   */
  get_or_create_task_run?: Maybe<Task_Run_Id_Payload>;
  /** Given a flow run, task, and map index, retrieve the corresponding task run id */
  get_or_create_task_run_info?: Maybe<Get_Or_Create_Task_Run_Info_Payload>;
  get_runs_in_queue?: Maybe<Runs_In_Queue_Payload>;
  /** insert data into the table: "agent" */
  insert_agent?: Maybe<Agent_Mutation_Response>;
  /** insert data into the table: "agent_config" */
  insert_agent_config?: Maybe<Agent_Config_Mutation_Response>;
  /** insert a single row into the table: "agent_config" */
  insert_agent_config_one?: Maybe<Agent_Config>;
  /** insert a single row into the table: "agent" */
  insert_agent_one?: Maybe<Agent>;
  /** insert data into the table: "cloud_hook" */
  insert_cloud_hook?: Maybe<Cloud_Hook_Mutation_Response>;
  /** insert a single row into the table: "cloud_hook" */
  insert_cloud_hook_one?: Maybe<Cloud_Hook>;
  /** insert data into the table: "edge" */
  insert_edge?: Maybe<Edge_Mutation_Response>;
  /** insert a single row into the table: "edge" */
  insert_edge_one?: Maybe<Edge>;
  /** insert data into the table: "flow" */
  insert_flow?: Maybe<Flow_Mutation_Response>;
  /** insert data into the table: "flow_group" */
  insert_flow_group?: Maybe<Flow_Group_Mutation_Response>;
  /** insert a single row into the table: "flow_group" */
  insert_flow_group_one?: Maybe<Flow_Group>;
  /** insert a single row into the table: "flow" */
  insert_flow_one?: Maybe<Flow>;
  /** insert data into the table: "flow_run" */
  insert_flow_run?: Maybe<Flow_Run_Mutation_Response>;
  /** insert a single row into the table: "flow_run" */
  insert_flow_run_one?: Maybe<Flow_Run>;
  /** insert data into the table: "flow_run_state" */
  insert_flow_run_state?: Maybe<Flow_Run_State_Mutation_Response>;
  /** insert a single row into the table: "flow_run_state" */
  insert_flow_run_state_one?: Maybe<Flow_Run_State>;
  /** insert data into the table: "log" */
  insert_log?: Maybe<Log_Mutation_Response>;
  /** insert a single row into the table: "log" */
  insert_log_one?: Maybe<Log>;
  /** insert data into the table: "message" */
  insert_message?: Maybe<Message_Mutation_Response>;
  /** insert a single row into the table: "message" */
  insert_message_one?: Maybe<Message>;
  /** insert data into the table: "project" */
  insert_project?: Maybe<Project_Mutation_Response>;
  /** insert a single row into the table: "project" */
  insert_project_one?: Maybe<Project>;
  /** insert data into the table: "task" */
  insert_task?: Maybe<Task_Mutation_Response>;
  /** insert a single row into the table: "task" */
  insert_task_one?: Maybe<Task>;
  /** insert data into the table: "task_run" */
  insert_task_run?: Maybe<Task_Run_Mutation_Response>;
  /** insert data into the table: "task_run_artifact" */
  insert_task_run_artifact?: Maybe<Task_Run_Artifact_Mutation_Response>;
  /** insert a single row into the table: "task_run_artifact" */
  insert_task_run_artifact_one?: Maybe<Task_Run_Artifact>;
  /** insert a single row into the table: "task_run" */
  insert_task_run_one?: Maybe<Task_Run>;
  /** insert data into the table: "task_run_state" */
  insert_task_run_state?: Maybe<Task_Run_State_Mutation_Response>;
  /** insert a single row into the table: "task_run_state" */
  insert_task_run_state_one?: Maybe<Task_Run_State>;
  /** insert data into the table: "tenant" */
  insert_tenant?: Maybe<Tenant_Mutation_Response>;
  /** insert a single row into the table: "tenant" */
  insert_tenant_one?: Maybe<Tenant>;
  /** insert data into the table: "utility.traversal" */
  insert_utility_traversal?: Maybe<Utility_Traversal_Mutation_Response>;
  /** insert a single row into the table: "utility.traversal" */
  insert_utility_traversal_one?: Maybe<Utility_Traversal>;
  /** Mark message as seen. */
  mark_message_as_read?: Maybe<Success_Payload>;
  /** Mark message as not seen. */
  mark_message_as_unread?: Maybe<Success_Payload>;
  /** Register an agent */
  register_agent?: Maybe<Agent_Id_Payload>;
  /** Register edges to a flow from a serialized JSON representation. */
  register_edges?: Maybe<Success_Payload>;
  /** Register tasks to a flow from a serialized JSON representation. */
  register_tasks?: Maybe<Success_Payload>;
  /** Activate a cloud hook. */
  set_cloud_hook_active?: Maybe<Success_Payload>;
  /** Deactivate a cloud hook. */
  set_cloud_hook_inactive?: Maybe<Success_Payload>;
  /** Set a default parameter for a flow group. */
  set_flow_group_default_parameters?: Maybe<Success_Payload>;
  /** Set description for a flow group. */
  set_flow_group_description?: Maybe<Success_Payload>;
  /** Set labels for a flow group. */
  set_flow_group_labels?: Maybe<Success_Payload>;
  /** Set run_config for a flow group. */
  set_flow_group_run_config?: Maybe<Success_Payload>;
  /** Set a schedule for a flow group. */
  set_flow_group_schedule?: Maybe<Success_Payload>;
  /** Update the labels for a flow run */
  set_flow_run_labels?: Maybe<Success_Payload>;
  /** Rename a flow run */
  set_flow_run_name?: Maybe<Success_Payload>;
  /** Update one or more flow run states. A valid run version must be provided. */
  set_flow_run_states?: Maybe<Set_Flow_Run_States_Payload>;
  /** Update a project's description. */
  set_project_description?: Maybe<Project_Id_Payload>;
  /** Update a project's name. */
  set_project_name?: Maybe<Project_Id_Payload>;
  /**
   * Mark a flow's schedule as active, indicating that the Prefect scheduler should
   * automatically create new runs for this flow.
   */
  set_schedule_active?: Maybe<Success_Payload>;
  /**
   * Mark a flow's schedule as inactive, indicating that the Prefect scheduler should not
   * automatically creating new runs for this flow. Any existing auto-scheduled runs that have
   * not started will be deleted.
   */
  set_schedule_inactive?: Maybe<Success_Payload>;
  /** Rename a task run */
  set_task_run_name?: Maybe<Success_Payload>;
  /** Update one or more task run states. A valid run version must be provided. */
  set_task_run_states?: Maybe<Set_Task_Run_States_Payload>;
  /** Send a test event to your webhook. */
  test_cloud_hook?: Maybe<Success_Payload>;
  /** update data of the table: "agent" */
  update_agent?: Maybe<Agent_Mutation_Response>;
  /** update single row of the table: "agent" */
  update_agent_by_pk?: Maybe<Agent>;
  /** Update an agent config's config */
  update_agent_config?: Maybe<Success_Payload>;
  /** update single row of the table: "agent_config" */
  update_agent_config_by_pk?: Maybe<Agent_Config>;
  /** update data of the table: "cloud_hook" */
  update_cloud_hook?: Maybe<Cloud_Hook_Mutation_Response>;
  /** update single row of the table: "cloud_hook" */
  update_cloud_hook_by_pk?: Maybe<Cloud_Hook>;
  /** update data of the table: "edge" */
  update_edge?: Maybe<Edge_Mutation_Response>;
  /** update single row of the table: "edge" */
  update_edge_by_pk?: Maybe<Edge>;
  /** update data of the table: "flow" */
  update_flow?: Maybe<Flow_Mutation_Response>;
  /** update single row of the table: "flow" */
  update_flow_by_pk?: Maybe<Flow>;
  /** update data of the table: "flow_group" */
  update_flow_group?: Maybe<Flow_Group_Mutation_Response>;
  /** update single row of the table: "flow_group" */
  update_flow_group_by_pk?: Maybe<Flow_Group>;
  /** Update the project to which a flow belongs. */
  update_flow_project?: Maybe<Flow_Id_Payload>;
  /** update data of the table: "flow_run" */
  update_flow_run?: Maybe<Flow_Run_Mutation_Response>;
  /** update single row of the table: "flow_run" */
  update_flow_run_by_pk?: Maybe<Flow_Run>;
  /** Update a flow run's heartbeat. This indicates the flow run is alive and is called automatically by Prefect Core. */
  update_flow_run_heartbeat?: Maybe<Success_Payload>;
  /** update data of the table: "flow_run_state" */
  update_flow_run_state?: Maybe<Flow_Run_State_Mutation_Response>;
  /** update single row of the table: "flow_run_state" */
  update_flow_run_state_by_pk?: Maybe<Flow_Run_State>;
  /** update data of the table: "log" */
  update_log?: Maybe<Log_Mutation_Response>;
  /** update data of the table: "message" */
  update_message?: Maybe<Message_Mutation_Response>;
  /** update single row of the table: "message" */
  update_message_by_pk?: Maybe<Message>;
  /** update data of the table: "project" */
  update_project?: Maybe<Project_Mutation_Response>;
  /** update single row of the table: "project" */
  update_project_by_pk?: Maybe<Project>;
  /** update data of the table: "task" */
  update_task?: Maybe<Task_Mutation_Response>;
  /** update single row of the table: "task" */
  update_task_by_pk?: Maybe<Task>;
  /** update data of the table: "task_run" */
  update_task_run?: Maybe<Task_Run_Mutation_Response>;
  /** Update task run artifact. This API is still experimental. */
  update_task_run_artifact?: Maybe<Success_Payload>;
  /** update single row of the table: "task_run_artifact" */
  update_task_run_artifact_by_pk?: Maybe<Task_Run_Artifact>;
  /** update single row of the table: "task_run" */
  update_task_run_by_pk?: Maybe<Task_Run>;
  /** Update a task run's heartbeat. This indicates the task run is alive and is called automatically by Prefect Core. */
  update_task_run_heartbeat?: Maybe<Success_Payload>;
  /** update data of the table: "task_run_state" */
  update_task_run_state?: Maybe<Task_Run_State_Mutation_Response>;
  /** update single row of the table: "task_run_state" */
  update_task_run_state_by_pk?: Maybe<Task_Run_State>;
  /** update data of the table: "tenant" */
  update_tenant?: Maybe<Tenant_Mutation_Response>;
  /** update single row of the table: "tenant" */
  update_tenant_by_pk?: Maybe<Tenant>;
  /** Update a tenant's name. */
  update_tenant_name?: Maybe<Tenant_Id_Payload>;
  /** Update a tenant's settings. */
  update_tenant_settings?: Maybe<Tenant_Id_Payload>;
  /** Update a tenant's slug. */
  update_tenant_slug?: Maybe<Tenant_Id_Payload>;
  /** update data of the table: "utility.traversal" */
  update_utility_traversal?: Maybe<Utility_Traversal_Mutation_Response>;
  /** Submit logs for flow and task runs. Called automatically by Prefect Core. */
  write_run_logs?: Maybe<Success_Payload>;
};

/** mutation root */
export type MutationArchive_FlowArgs = {
  input: Archive_Flow_Input;
};

/** mutation root */
export type MutationCancel_Flow_RunArgs = {
  input: Cancel_Flow_Run_Input;
};

/** mutation root */
export type MutationCreate_Agent_ConfigArgs = {
  input: Create_Agent_Config_Input;
};

/** mutation root */
export type MutationCreate_Cloud_HookArgs = {
  input: Create_Cloud_Hook_Input;
};

/** mutation root */
export type MutationCreate_FlowArgs = {
  input: Create_Flow_Input;
};

/** mutation root */
export type MutationCreate_Flow_From_Compressed_StringArgs = {
  input: Create_Flow_From_Compressed_String_Input;
};

/** mutation root */
export type MutationCreate_Flow_RunArgs = {
  input: Create_Flow_Run_Input;
};

/** mutation root */
export type MutationCreate_ProjectArgs = {
  input: Create_Project_Input;
};

/** mutation root */
export type MutationCreate_Task_Run_ArtifactArgs = {
  input: Create_Task_Run_Artifact_Input;
};

/** mutation root */
export type MutationCreate_TenantArgs = {
  input: Create_Tenant_Input;
};

/** mutation root */
export type MutationDelete_AgentArgs = {
  input: Delete_Agent_Input;
};

/** mutation root */
export type MutationDelete_Agent_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationDelete_Agent_ConfigArgs = {
  input: Delete_Agent_Config_Input;
};

/** mutation root */
export type MutationDelete_Agent_Config_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationDelete_Cloud_HookArgs = {
  input: Delete_Cloud_Hook_Input;
};

/** mutation root */
export type MutationDelete_Cloud_Hook_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationDelete_EdgeArgs = {
  where: Edge_Bool_Exp;
};

/** mutation root */
export type MutationDelete_Edge_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationDelete_FlowArgs = {
  input: Delete_Flow_Input;
};

/** mutation root */
export type MutationDelete_Flow_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationDelete_Flow_GroupArgs = {
  input: Delete_Flow_Group_Input;
};

/** mutation root */
export type MutationDelete_Flow_Group_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationDelete_Flow_Group_ScheduleArgs = {
  input: Delete_Flow_Group_Schedule_Input;
};

/** mutation root */
export type MutationDelete_Flow_RunArgs = {
  input: Delete_Flow_Run_Input;
};

/** mutation root */
export type MutationDelete_Flow_Run_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationDelete_Flow_Run_StateArgs = {
  where: Flow_Run_State_Bool_Exp;
};

/** mutation root */
export type MutationDelete_Flow_Run_State_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationDelete_LogArgs = {
  where: Log_Bool_Exp;
};

/** mutation root */
export type MutationDelete_MessageArgs = {
  input: Delete_Message_Input;
};

/** mutation root */
export type MutationDelete_Message_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationDelete_ProjectArgs = {
  input: Delete_Project_Input;
};

/** mutation root */
export type MutationDelete_Project_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationDelete_TaskArgs = {
  where: Task_Bool_Exp;
};

/** mutation root */
export type MutationDelete_Task_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationDelete_Task_RunArgs = {
  where: Task_Run_Bool_Exp;
};

/** mutation root */
export type MutationDelete_Task_Run_ArtifactArgs = {
  input: Delete_Task_Run_Artifact_Input;
};

/** mutation root */
export type MutationDelete_Task_Run_Artifact_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationDelete_Task_Run_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationDelete_Task_Run_StateArgs = {
  where: Task_Run_State_Bool_Exp;
};

/** mutation root */
export type MutationDelete_Task_Run_State_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationDelete_TenantArgs = {
  input: Delete_Tenant_Input;
};

/** mutation root */
export type MutationDelete_Tenant_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type MutationDelete_Utility_TraversalArgs = {
  where: Utility_Traversal_Bool_Exp;
};

/** mutation root */
export type MutationDisable_Flow_HeartbeatArgs = {
  input: Disable_Flow_Heartbeat_Input;
};

/** mutation root */
export type MutationDisable_Flow_Lazarus_ProcessArgs = {
  input: Disable_Flow_Lazarus_Process_Input;
};

/** mutation root */
export type MutationEnable_Flow_HeartbeatArgs = {
  input: Enable_Flow_Heartbeat_Input;
};

/** mutation root */
export type MutationEnable_Flow_Lazarus_ProcessArgs = {
  input: Enable_Flow_Lazarus_Process_Input;
};

/** mutation root */
export type MutationGet_Or_Create_Task_RunArgs = {
  input: Get_Or_Create_Task_Run_Input;
};

/** mutation root */
export type MutationGet_Or_Create_Task_Run_InfoArgs = {
  input: Get_Or_Create_Task_Run_Info_Input;
};

/** mutation root */
export type MutationGet_Runs_In_QueueArgs = {
  input: Get_Runs_In_Queue_Input;
};

/** mutation root */
export type MutationInsert_AgentArgs = {
  objects: Array<Agent_Insert_Input>;
  on_conflict?: InputMaybe<Agent_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Agent_ConfigArgs = {
  objects: Array<Agent_Config_Insert_Input>;
  on_conflict?: InputMaybe<Agent_Config_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Agent_Config_OneArgs = {
  object: Agent_Config_Insert_Input;
  on_conflict?: InputMaybe<Agent_Config_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Agent_OneArgs = {
  object: Agent_Insert_Input;
  on_conflict?: InputMaybe<Agent_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Cloud_HookArgs = {
  objects: Array<Cloud_Hook_Insert_Input>;
  on_conflict?: InputMaybe<Cloud_Hook_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Cloud_Hook_OneArgs = {
  object: Cloud_Hook_Insert_Input;
  on_conflict?: InputMaybe<Cloud_Hook_On_Conflict>;
};

/** mutation root */
export type MutationInsert_EdgeArgs = {
  objects: Array<Edge_Insert_Input>;
  on_conflict?: InputMaybe<Edge_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Edge_OneArgs = {
  object: Edge_Insert_Input;
  on_conflict?: InputMaybe<Edge_On_Conflict>;
};

/** mutation root */
export type MutationInsert_FlowArgs = {
  objects: Array<Flow_Insert_Input>;
  on_conflict?: InputMaybe<Flow_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Flow_GroupArgs = {
  objects: Array<Flow_Group_Insert_Input>;
  on_conflict?: InputMaybe<Flow_Group_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Flow_Group_OneArgs = {
  object: Flow_Group_Insert_Input;
  on_conflict?: InputMaybe<Flow_Group_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Flow_OneArgs = {
  object: Flow_Insert_Input;
  on_conflict?: InputMaybe<Flow_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Flow_RunArgs = {
  objects: Array<Flow_Run_Insert_Input>;
  on_conflict?: InputMaybe<Flow_Run_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Flow_Run_OneArgs = {
  object: Flow_Run_Insert_Input;
  on_conflict?: InputMaybe<Flow_Run_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Flow_Run_StateArgs = {
  objects: Array<Flow_Run_State_Insert_Input>;
  on_conflict?: InputMaybe<Flow_Run_State_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Flow_Run_State_OneArgs = {
  object: Flow_Run_State_Insert_Input;
  on_conflict?: InputMaybe<Flow_Run_State_On_Conflict>;
};

/** mutation root */
export type MutationInsert_LogArgs = {
  objects: Array<Log_Insert_Input>;
};

/** mutation root */
export type MutationInsert_Log_OneArgs = {
  object: Log_Insert_Input;
};

/** mutation root */
export type MutationInsert_MessageArgs = {
  objects: Array<Message_Insert_Input>;
  on_conflict?: InputMaybe<Message_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Message_OneArgs = {
  object: Message_Insert_Input;
  on_conflict?: InputMaybe<Message_On_Conflict>;
};

/** mutation root */
export type MutationInsert_ProjectArgs = {
  objects: Array<Project_Insert_Input>;
  on_conflict?: InputMaybe<Project_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Project_OneArgs = {
  object: Project_Insert_Input;
  on_conflict?: InputMaybe<Project_On_Conflict>;
};

/** mutation root */
export type MutationInsert_TaskArgs = {
  objects: Array<Task_Insert_Input>;
  on_conflict?: InputMaybe<Task_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Task_OneArgs = {
  object: Task_Insert_Input;
  on_conflict?: InputMaybe<Task_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Task_RunArgs = {
  objects: Array<Task_Run_Insert_Input>;
  on_conflict?: InputMaybe<Task_Run_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Task_Run_ArtifactArgs = {
  objects: Array<Task_Run_Artifact_Insert_Input>;
  on_conflict?: InputMaybe<Task_Run_Artifact_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Task_Run_Artifact_OneArgs = {
  object: Task_Run_Artifact_Insert_Input;
  on_conflict?: InputMaybe<Task_Run_Artifact_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Task_Run_OneArgs = {
  object: Task_Run_Insert_Input;
  on_conflict?: InputMaybe<Task_Run_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Task_Run_StateArgs = {
  objects: Array<Task_Run_State_Insert_Input>;
  on_conflict?: InputMaybe<Task_Run_State_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Task_Run_State_OneArgs = {
  object: Task_Run_State_Insert_Input;
  on_conflict?: InputMaybe<Task_Run_State_On_Conflict>;
};

/** mutation root */
export type MutationInsert_TenantArgs = {
  objects: Array<Tenant_Insert_Input>;
  on_conflict?: InputMaybe<Tenant_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Tenant_OneArgs = {
  object: Tenant_Insert_Input;
  on_conflict?: InputMaybe<Tenant_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Utility_TraversalArgs = {
  objects: Array<Utility_Traversal_Insert_Input>;
};

/** mutation root */
export type MutationInsert_Utility_Traversal_OneArgs = {
  object: Utility_Traversal_Insert_Input;
};

/** mutation root */
export type MutationMark_Message_As_ReadArgs = {
  input: Mark_Message_As_Read_Input;
};

/** mutation root */
export type MutationMark_Message_As_UnreadArgs = {
  input: Mark_Message_As_Unread_Input;
};

/** mutation root */
export type MutationRegister_AgentArgs = {
  input: Register_Agent_Input;
};

/** mutation root */
export type MutationRegister_EdgesArgs = {
  input: Register_Edges_Input;
};

/** mutation root */
export type MutationRegister_TasksArgs = {
  input: Register_Tasks_Input;
};

/** mutation root */
export type MutationSet_Cloud_Hook_ActiveArgs = {
  input: Set_Cloud_Hook_Active_Input;
};

/** mutation root */
export type MutationSet_Cloud_Hook_InactiveArgs = {
  input: Set_Cloud_Hook_Inactive_Input;
};

/** mutation root */
export type MutationSet_Flow_Group_Default_ParametersArgs = {
  input: Set_Flow_Group_Default_Parameters_Input;
};

/** mutation root */
export type MutationSet_Flow_Group_DescriptionArgs = {
  input: Set_Flow_Group_Description_Input;
};

/** mutation root */
export type MutationSet_Flow_Group_LabelsArgs = {
  input: Set_Flow_Group_Labels_Input;
};

/** mutation root */
export type MutationSet_Flow_Group_Run_ConfigArgs = {
  input: Set_Flow_Group_Run_Config_Input;
};

/** mutation root */
export type MutationSet_Flow_Group_ScheduleArgs = {
  input: Set_Flow_Group_Schedule_Input;
};

/** mutation root */
export type MutationSet_Flow_Run_LabelsArgs = {
  input: Set_Flow_Run_Labels_Input;
};

/** mutation root */
export type MutationSet_Flow_Run_NameArgs = {
  input: Set_Flow_Run_Name_Input;
};

/** mutation root */
export type MutationSet_Flow_Run_StatesArgs = {
  input: Set_Flow_Run_States_Input;
};

/** mutation root */
export type MutationSet_Project_DescriptionArgs = {
  input: Set_Project_Description_Input;
};

/** mutation root */
export type MutationSet_Project_NameArgs = {
  input: Set_Project_Name_Input;
};

/** mutation root */
export type MutationSet_Schedule_ActiveArgs = {
  input: Set_Schedule_Active_Input;
};

/** mutation root */
export type MutationSet_Schedule_InactiveArgs = {
  input: Set_Schedule_Inactive_Input;
};

/** mutation root */
export type MutationSet_Task_Run_NameArgs = {
  input: Set_Task_Run_Name_Input;
};

/** mutation root */
export type MutationSet_Task_Run_StatesArgs = {
  input: Set_Task_Run_States_Input;
};

/** mutation root */
export type MutationTest_Cloud_HookArgs = {
  input: Test_Cloud_Hook_Input;
};

/** mutation root */
export type MutationUpdate_AgentArgs = {
  _append?: InputMaybe<Agent_Append_Input>;
  _delete_at_path?: InputMaybe<Agent_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Agent_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Agent_Delete_Key_Input>;
  _prepend?: InputMaybe<Agent_Prepend_Input>;
  _set?: InputMaybe<Agent_Set_Input>;
  where: Agent_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_Agent_By_PkArgs = {
  _append?: InputMaybe<Agent_Append_Input>;
  _delete_at_path?: InputMaybe<Agent_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Agent_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Agent_Delete_Key_Input>;
  _prepend?: InputMaybe<Agent_Prepend_Input>;
  _set?: InputMaybe<Agent_Set_Input>;
  pk_columns: Agent_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_Agent_ConfigArgs = {
  input: Update_Agent_Config_Input;
};

/** mutation root */
export type MutationUpdate_Agent_Config_By_PkArgs = {
  _append?: InputMaybe<Agent_Config_Append_Input>;
  _delete_at_path?: InputMaybe<Agent_Config_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Agent_Config_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Agent_Config_Delete_Key_Input>;
  _prepend?: InputMaybe<Agent_Config_Prepend_Input>;
  _set?: InputMaybe<Agent_Config_Set_Input>;
  pk_columns: Agent_Config_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_Cloud_HookArgs = {
  _append?: InputMaybe<Cloud_Hook_Append_Input>;
  _delete_at_path?: InputMaybe<Cloud_Hook_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Cloud_Hook_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Cloud_Hook_Delete_Key_Input>;
  _prepend?: InputMaybe<Cloud_Hook_Prepend_Input>;
  _set?: InputMaybe<Cloud_Hook_Set_Input>;
  where: Cloud_Hook_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_Cloud_Hook_By_PkArgs = {
  _append?: InputMaybe<Cloud_Hook_Append_Input>;
  _delete_at_path?: InputMaybe<Cloud_Hook_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Cloud_Hook_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Cloud_Hook_Delete_Key_Input>;
  _prepend?: InputMaybe<Cloud_Hook_Prepend_Input>;
  _set?: InputMaybe<Cloud_Hook_Set_Input>;
  pk_columns: Cloud_Hook_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_EdgeArgs = {
  _set?: InputMaybe<Edge_Set_Input>;
  where: Edge_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_Edge_By_PkArgs = {
  _set?: InputMaybe<Edge_Set_Input>;
  pk_columns: Edge_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_FlowArgs = {
  _append?: InputMaybe<Flow_Append_Input>;
  _delete_at_path?: InputMaybe<Flow_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Flow_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Flow_Delete_Key_Input>;
  _inc?: InputMaybe<Flow_Inc_Input>;
  _prepend?: InputMaybe<Flow_Prepend_Input>;
  _set?: InputMaybe<Flow_Set_Input>;
  where: Flow_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_Flow_By_PkArgs = {
  _append?: InputMaybe<Flow_Append_Input>;
  _delete_at_path?: InputMaybe<Flow_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Flow_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Flow_Delete_Key_Input>;
  _inc?: InputMaybe<Flow_Inc_Input>;
  _prepend?: InputMaybe<Flow_Prepend_Input>;
  _set?: InputMaybe<Flow_Set_Input>;
  pk_columns: Flow_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_Flow_GroupArgs = {
  _append?: InputMaybe<Flow_Group_Append_Input>;
  _delete_at_path?: InputMaybe<Flow_Group_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Flow_Group_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Flow_Group_Delete_Key_Input>;
  _prepend?: InputMaybe<Flow_Group_Prepend_Input>;
  _set?: InputMaybe<Flow_Group_Set_Input>;
  where: Flow_Group_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_Flow_Group_By_PkArgs = {
  _append?: InputMaybe<Flow_Group_Append_Input>;
  _delete_at_path?: InputMaybe<Flow_Group_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Flow_Group_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Flow_Group_Delete_Key_Input>;
  _prepend?: InputMaybe<Flow_Group_Prepend_Input>;
  _set?: InputMaybe<Flow_Group_Set_Input>;
  pk_columns: Flow_Group_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_Flow_ProjectArgs = {
  input: Update_Flow_Project_Input;
};

/** mutation root */
export type MutationUpdate_Flow_RunArgs = {
  _append?: InputMaybe<Flow_Run_Append_Input>;
  _delete_at_path?: InputMaybe<Flow_Run_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Flow_Run_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Flow_Run_Delete_Key_Input>;
  _inc?: InputMaybe<Flow_Run_Inc_Input>;
  _prepend?: InputMaybe<Flow_Run_Prepend_Input>;
  _set?: InputMaybe<Flow_Run_Set_Input>;
  where: Flow_Run_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_Flow_Run_By_PkArgs = {
  _append?: InputMaybe<Flow_Run_Append_Input>;
  _delete_at_path?: InputMaybe<Flow_Run_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Flow_Run_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Flow_Run_Delete_Key_Input>;
  _inc?: InputMaybe<Flow_Run_Inc_Input>;
  _prepend?: InputMaybe<Flow_Run_Prepend_Input>;
  _set?: InputMaybe<Flow_Run_Set_Input>;
  pk_columns: Flow_Run_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_Flow_Run_HeartbeatArgs = {
  input: Update_Flow_Run_Heartbeat_Input;
};

/** mutation root */
export type MutationUpdate_Flow_Run_StateArgs = {
  _append?: InputMaybe<Flow_Run_State_Append_Input>;
  _delete_at_path?: InputMaybe<Flow_Run_State_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Flow_Run_State_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Flow_Run_State_Delete_Key_Input>;
  _inc?: InputMaybe<Flow_Run_State_Inc_Input>;
  _prepend?: InputMaybe<Flow_Run_State_Prepend_Input>;
  _set?: InputMaybe<Flow_Run_State_Set_Input>;
  where: Flow_Run_State_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_Flow_Run_State_By_PkArgs = {
  _append?: InputMaybe<Flow_Run_State_Append_Input>;
  _delete_at_path?: InputMaybe<Flow_Run_State_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Flow_Run_State_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Flow_Run_State_Delete_Key_Input>;
  _inc?: InputMaybe<Flow_Run_State_Inc_Input>;
  _prepend?: InputMaybe<Flow_Run_State_Prepend_Input>;
  _set?: InputMaybe<Flow_Run_State_Set_Input>;
  pk_columns: Flow_Run_State_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_LogArgs = {
  _append?: InputMaybe<Log_Append_Input>;
  _delete_at_path?: InputMaybe<Log_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Log_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Log_Delete_Key_Input>;
  _prepend?: InputMaybe<Log_Prepend_Input>;
  _set?: InputMaybe<Log_Set_Input>;
  where: Log_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_MessageArgs = {
  _append?: InputMaybe<Message_Append_Input>;
  _delete_at_path?: InputMaybe<Message_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Message_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Message_Delete_Key_Input>;
  _prepend?: InputMaybe<Message_Prepend_Input>;
  _set?: InputMaybe<Message_Set_Input>;
  where: Message_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_Message_By_PkArgs = {
  _append?: InputMaybe<Message_Append_Input>;
  _delete_at_path?: InputMaybe<Message_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Message_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Message_Delete_Key_Input>;
  _prepend?: InputMaybe<Message_Prepend_Input>;
  _set?: InputMaybe<Message_Set_Input>;
  pk_columns: Message_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_ProjectArgs = {
  _set?: InputMaybe<Project_Set_Input>;
  where: Project_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_Project_By_PkArgs = {
  _set?: InputMaybe<Project_Set_Input>;
  pk_columns: Project_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_TaskArgs = {
  _append?: InputMaybe<Task_Append_Input>;
  _delete_at_path?: InputMaybe<Task_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Task_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Task_Delete_Key_Input>;
  _inc?: InputMaybe<Task_Inc_Input>;
  _prepend?: InputMaybe<Task_Prepend_Input>;
  _set?: InputMaybe<Task_Set_Input>;
  where: Task_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_Task_By_PkArgs = {
  _append?: InputMaybe<Task_Append_Input>;
  _delete_at_path?: InputMaybe<Task_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Task_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Task_Delete_Key_Input>;
  _inc?: InputMaybe<Task_Inc_Input>;
  _prepend?: InputMaybe<Task_Prepend_Input>;
  _set?: InputMaybe<Task_Set_Input>;
  pk_columns: Task_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_Task_RunArgs = {
  _append?: InputMaybe<Task_Run_Append_Input>;
  _delete_at_path?: InputMaybe<Task_Run_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Task_Run_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Task_Run_Delete_Key_Input>;
  _inc?: InputMaybe<Task_Run_Inc_Input>;
  _prepend?: InputMaybe<Task_Run_Prepend_Input>;
  _set?: InputMaybe<Task_Run_Set_Input>;
  where: Task_Run_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_Task_Run_ArtifactArgs = {
  input: Update_Task_Run_Artifact_Input;
};

/** mutation root */
export type MutationUpdate_Task_Run_Artifact_By_PkArgs = {
  _append?: InputMaybe<Task_Run_Artifact_Append_Input>;
  _delete_at_path?: InputMaybe<Task_Run_Artifact_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Task_Run_Artifact_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Task_Run_Artifact_Delete_Key_Input>;
  _prepend?: InputMaybe<Task_Run_Artifact_Prepend_Input>;
  _set?: InputMaybe<Task_Run_Artifact_Set_Input>;
  pk_columns: Task_Run_Artifact_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_Task_Run_By_PkArgs = {
  _append?: InputMaybe<Task_Run_Append_Input>;
  _delete_at_path?: InputMaybe<Task_Run_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Task_Run_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Task_Run_Delete_Key_Input>;
  _inc?: InputMaybe<Task_Run_Inc_Input>;
  _prepend?: InputMaybe<Task_Run_Prepend_Input>;
  _set?: InputMaybe<Task_Run_Set_Input>;
  pk_columns: Task_Run_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_Task_Run_HeartbeatArgs = {
  input: Update_Task_Run_Heartbeat_Input;
};

/** mutation root */
export type MutationUpdate_Task_Run_StateArgs = {
  _append?: InputMaybe<Task_Run_State_Append_Input>;
  _delete_at_path?: InputMaybe<Task_Run_State_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Task_Run_State_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Task_Run_State_Delete_Key_Input>;
  _inc?: InputMaybe<Task_Run_State_Inc_Input>;
  _prepend?: InputMaybe<Task_Run_State_Prepend_Input>;
  _set?: InputMaybe<Task_Run_State_Set_Input>;
  where: Task_Run_State_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_Task_Run_State_By_PkArgs = {
  _append?: InputMaybe<Task_Run_State_Append_Input>;
  _delete_at_path?: InputMaybe<Task_Run_State_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Task_Run_State_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Task_Run_State_Delete_Key_Input>;
  _inc?: InputMaybe<Task_Run_State_Inc_Input>;
  _prepend?: InputMaybe<Task_Run_State_Prepend_Input>;
  _set?: InputMaybe<Task_Run_State_Set_Input>;
  pk_columns: Task_Run_State_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_TenantArgs = {
  _append?: InputMaybe<Tenant_Append_Input>;
  _delete_at_path?: InputMaybe<Tenant_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenant_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenant_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenant_Prepend_Input>;
  _set?: InputMaybe<Tenant_Set_Input>;
  where: Tenant_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_Tenant_By_PkArgs = {
  _append?: InputMaybe<Tenant_Append_Input>;
  _delete_at_path?: InputMaybe<Tenant_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenant_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenant_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenant_Prepend_Input>;
  _set?: InputMaybe<Tenant_Set_Input>;
  pk_columns: Tenant_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_Tenant_NameArgs = {
  input: Update_Tenant_Name_Input;
};

/** mutation root */
export type MutationUpdate_Tenant_SettingsArgs = {
  input: Update_Tenant_Settings_Input;
};

/** mutation root */
export type MutationUpdate_Tenant_SlugArgs = {
  input: Update_Tenant_Slug_Input;
};

/** mutation root */
export type MutationUpdate_Utility_TraversalArgs = {
  _inc?: InputMaybe<Utility_Traversal_Inc_Input>;
  _set?: InputMaybe<Utility_Traversal_Set_Input>;
  where: Utility_Traversal_Bool_Exp;
};

/** mutation root */
export type MutationWrite_Run_LogsArgs = {
  input: Write_Run_Logs_Input;
};

export type Query = {
  __typename?: "Query";
  /** fetch data from the table: "agent" */
  agent: Array<Agent>;
  /** fetch aggregated fields from the table: "agent" */
  agent_aggregate: Agent_Aggregate;
  /** fetch data from the table: "agent" using primary key columns */
  agent_by_pk?: Maybe<Agent>;
  /** fetch data from the table: "agent_config" */
  agent_config: Array<Agent_Config>;
  /** fetch aggregated fields from the table: "agent_config" */
  agent_config_aggregate: Agent_Config_Aggregate;
  /** fetch data from the table: "agent_config" using primary key columns */
  agent_config_by_pk?: Maybe<Agent_Config>;
  api?: Maybe<Api_Info>;
  /** fetch data from the table: "cloud_hook" */
  cloud_hook: Array<Cloud_Hook>;
  /** fetch aggregated fields from the table: "cloud_hook" */
  cloud_hook_aggregate: Cloud_Hook_Aggregate;
  /** fetch data from the table: "cloud_hook" using primary key columns */
  cloud_hook_by_pk?: Maybe<Cloud_Hook>;
  /** fetch data from the table: "edge" */
  edge: Array<Edge>;
  /** fetch aggregated fields from the table: "edge" */
  edge_aggregate: Edge_Aggregate;
  /** fetch data from the table: "edge" using primary key columns */
  edge_by_pk?: Maybe<Edge>;
  /** fetch data from the table: "flow" */
  flow: Array<Flow>;
  /** fetch aggregated fields from the table: "flow" */
  flow_aggregate: Flow_Aggregate;
  /** fetch data from the table: "flow" using primary key columns */
  flow_by_pk?: Maybe<Flow>;
  /** fetch data from the table: "flow_group" */
  flow_group: Array<Flow_Group>;
  /** fetch aggregated fields from the table: "flow_group" */
  flow_group_aggregate: Flow_Group_Aggregate;
  /** fetch data from the table: "flow_group" using primary key columns */
  flow_group_by_pk?: Maybe<Flow_Group>;
  /** fetch data from the table: "flow_run" */
  flow_run: Array<Flow_Run>;
  /** fetch aggregated fields from the table: "flow_run" */
  flow_run_aggregate: Flow_Run_Aggregate;
  /** fetch data from the table: "flow_run" using primary key columns */
  flow_run_by_pk?: Maybe<Flow_Run>;
  /** fetch data from the table: "flow_run_state" */
  flow_run_state: Array<Flow_Run_State>;
  /** fetch aggregated fields from the table: "flow_run_state" */
  flow_run_state_aggregate: Flow_Run_State_Aggregate;
  /** fetch data from the table: "flow_run_state" using primary key columns */
  flow_run_state_by_pk?: Maybe<Flow_Run_State>;
  /** Given a task run ID, retrieve current task run info */
  get_task_run_info?: Maybe<Task_Run_Info_Payload>;
  hello?: Maybe<Scalars["String"]>;
  /** fetch data from the table: "log" */
  log: Array<Log>;
  /** fetch aggregated fields from the table: "log" */
  log_aggregate: Log_Aggregate;
  mapped_children?: Maybe<Mapped_Children_Payload>;
  /** fetch data from the table: "message" */
  message: Array<Message>;
  /** fetch aggregated fields from the table: "message" */
  message_aggregate: Message_Aggregate;
  /** fetch data from the table: "message" using primary key columns */
  message_by_pk?: Maybe<Message>;
  /** fetch data from the table: "project" */
  project: Array<Project>;
  /** fetch aggregated fields from the table: "project" */
  project_aggregate: Project_Aggregate;
  /** fetch data from the table: "project" using primary key columns */
  project_by_pk?: Maybe<Project>;
  reference_data?: Maybe<Reference_Data>;
  /** fetch data from the table: "task" */
  task: Array<Task>;
  /** fetch aggregated fields from the table: "task" */
  task_aggregate: Task_Aggregate;
  /** fetch data from the table: "task" using primary key columns */
  task_by_pk?: Maybe<Task>;
  /** fetch data from the table: "task_run" */
  task_run: Array<Task_Run>;
  /** fetch aggregated fields from the table: "task_run" */
  task_run_aggregate: Task_Run_Aggregate;
  /** fetch data from the table: "task_run_artifact" */
  task_run_artifact: Array<Task_Run_Artifact>;
  /** fetch aggregated fields from the table: "task_run_artifact" */
  task_run_artifact_aggregate: Task_Run_Artifact_Aggregate;
  /** fetch data from the table: "task_run_artifact" using primary key columns */
  task_run_artifact_by_pk?: Maybe<Task_Run_Artifact>;
  /** fetch data from the table: "task_run" using primary key columns */
  task_run_by_pk?: Maybe<Task_Run>;
  /** fetch data from the table: "task_run_state" */
  task_run_state: Array<Task_Run_State>;
  /** fetch aggregated fields from the table: "task_run_state" */
  task_run_state_aggregate: Task_Run_State_Aggregate;
  /** fetch data from the table: "task_run_state" using primary key columns */
  task_run_state_by_pk?: Maybe<Task_Run_State>;
  /** fetch data from the table: "tenant" */
  tenant: Array<Tenant>;
  /** fetch aggregated fields from the table: "tenant" */
  tenant_aggregate: Tenant_Aggregate;
  /** fetch data from the table: "tenant" using primary key columns */
  tenant_by_pk?: Maybe<Tenant>;
  /** execute function "utility.downstream_tasks" which returns "utility.traversal" */
  utility_downstream_tasks: Array<Utility_Traversal>;
  /** execute function "utility.downstream_tasks" and query aggregates on result of table type "utility.traversal" */
  utility_downstream_tasks_aggregate: Utility_Traversal_Aggregate;
  /** fetch data from the table: "utility.traversal" */
  utility_traversal: Array<Utility_Traversal>;
  /** fetch aggregated fields from the table: "utility.traversal" */
  utility_traversal_aggregate: Utility_Traversal_Aggregate;
  /** execute function "utility.upstream_tasks" which returns "utility.traversal" */
  utility_upstream_tasks: Array<Utility_Traversal>;
  /** execute function "utility.upstream_tasks" and query aggregates on result of table type "utility.traversal" */
  utility_upstream_tasks_aggregate: Utility_Traversal_Aggregate;
};

export type QueryAgentArgs = {
  distinct_on?: InputMaybe<Array<Agent_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Agent_Order_By>>;
  where?: InputMaybe<Agent_Bool_Exp>;
};

export type QueryAgent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agent_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Agent_Order_By>>;
  where?: InputMaybe<Agent_Bool_Exp>;
};

export type QueryAgent_By_PkArgs = {
  id: Scalars["uuid"];
};

export type QueryAgent_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Agent_Config_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Agent_Config_Order_By>>;
  where?: InputMaybe<Agent_Config_Bool_Exp>;
};

export type QueryAgent_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agent_Config_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Agent_Config_Order_By>>;
  where?: InputMaybe<Agent_Config_Bool_Exp>;
};

export type QueryAgent_Config_By_PkArgs = {
  id: Scalars["uuid"];
};

export type QueryCloud_HookArgs = {
  distinct_on?: InputMaybe<Array<Cloud_Hook_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Cloud_Hook_Order_By>>;
  where?: InputMaybe<Cloud_Hook_Bool_Exp>;
};

export type QueryCloud_Hook_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cloud_Hook_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Cloud_Hook_Order_By>>;
  where?: InputMaybe<Cloud_Hook_Bool_Exp>;
};

export type QueryCloud_Hook_By_PkArgs = {
  id: Scalars["uuid"];
};

export type QueryEdgeArgs = {
  distinct_on?: InputMaybe<Array<Edge_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Edge_Order_By>>;
  where?: InputMaybe<Edge_Bool_Exp>;
};

export type QueryEdge_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Edge_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Edge_Order_By>>;
  where?: InputMaybe<Edge_Bool_Exp>;
};

export type QueryEdge_By_PkArgs = {
  id: Scalars["uuid"];
};

export type QueryFlowArgs = {
  distinct_on?: InputMaybe<Array<Flow_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Order_By>>;
  where?: InputMaybe<Flow_Bool_Exp>;
};

export type QueryFlow_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flow_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Order_By>>;
  where?: InputMaybe<Flow_Bool_Exp>;
};

export type QueryFlow_By_PkArgs = {
  id: Scalars["uuid"];
};

export type QueryFlow_GroupArgs = {
  distinct_on?: InputMaybe<Array<Flow_Group_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Group_Order_By>>;
  where?: InputMaybe<Flow_Group_Bool_Exp>;
};

export type QueryFlow_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flow_Group_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Group_Order_By>>;
  where?: InputMaybe<Flow_Group_Bool_Exp>;
};

export type QueryFlow_Group_By_PkArgs = {
  id: Scalars["uuid"];
};

export type QueryFlow_RunArgs = {
  distinct_on?: InputMaybe<Array<Flow_Run_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Run_Order_By>>;
  where?: InputMaybe<Flow_Run_Bool_Exp>;
};

export type QueryFlow_Run_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flow_Run_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Run_Order_By>>;
  where?: InputMaybe<Flow_Run_Bool_Exp>;
};

export type QueryFlow_Run_By_PkArgs = {
  id: Scalars["uuid"];
};

export type QueryFlow_Run_StateArgs = {
  distinct_on?: InputMaybe<Array<Flow_Run_State_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Run_State_Order_By>>;
  where?: InputMaybe<Flow_Run_State_Bool_Exp>;
};

export type QueryFlow_Run_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flow_Run_State_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Run_State_Order_By>>;
  where?: InputMaybe<Flow_Run_State_Bool_Exp>;
};

export type QueryFlow_Run_State_By_PkArgs = {
  id: Scalars["uuid"];
};

export type QueryGet_Task_Run_InfoArgs = {
  task_run_id: Scalars["UUID"];
};

export type QueryLogArgs = {
  distinct_on?: InputMaybe<Array<Log_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Log_Order_By>>;
  where?: InputMaybe<Log_Bool_Exp>;
};

export type QueryLog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Log_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Log_Order_By>>;
  where?: InputMaybe<Log_Bool_Exp>;
};

export type QueryMapped_ChildrenArgs = {
  task_run_id: Scalars["UUID"];
};

export type QueryMessageArgs = {
  distinct_on?: InputMaybe<Array<Message_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Order_By>>;
  where?: InputMaybe<Message_Bool_Exp>;
};

export type QueryMessage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Message_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Order_By>>;
  where?: InputMaybe<Message_Bool_Exp>;
};

export type QueryMessage_By_PkArgs = {
  id: Scalars["uuid"];
};

export type QueryProjectArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

export type QueryProject_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

export type QueryProject_By_PkArgs = {
  id: Scalars["uuid"];
};

export type QueryTaskArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

export type QueryTask_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

export type QueryTask_By_PkArgs = {
  id: Scalars["uuid"];
};

export type QueryTask_RunArgs = {
  distinct_on?: InputMaybe<Array<Task_Run_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Run_Order_By>>;
  where?: InputMaybe<Task_Run_Bool_Exp>;
};

export type QueryTask_Run_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Run_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Run_Order_By>>;
  where?: InputMaybe<Task_Run_Bool_Exp>;
};

export type QueryTask_Run_ArtifactArgs = {
  distinct_on?: InputMaybe<Array<Task_Run_Artifact_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Run_Artifact_Order_By>>;
  where?: InputMaybe<Task_Run_Artifact_Bool_Exp>;
};

export type QueryTask_Run_Artifact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Run_Artifact_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Run_Artifact_Order_By>>;
  where?: InputMaybe<Task_Run_Artifact_Bool_Exp>;
};

export type QueryTask_Run_Artifact_By_PkArgs = {
  id: Scalars["uuid"];
};

export type QueryTask_Run_By_PkArgs = {
  id: Scalars["uuid"];
};

export type QueryTask_Run_StateArgs = {
  distinct_on?: InputMaybe<Array<Task_Run_State_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Run_State_Order_By>>;
  where?: InputMaybe<Task_Run_State_Bool_Exp>;
};

export type QueryTask_Run_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Run_State_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Run_State_Order_By>>;
  where?: InputMaybe<Task_Run_State_Bool_Exp>;
};

export type QueryTask_Run_State_By_PkArgs = {
  id: Scalars["uuid"];
};

export type QueryTenantArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tenant_Order_By>>;
  where?: InputMaybe<Tenant_Bool_Exp>;
};

export type QueryTenant_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tenant_Order_By>>;
  where?: InputMaybe<Tenant_Bool_Exp>;
};

export type QueryTenant_By_PkArgs = {
  id: Scalars["uuid"];
};

export type QueryUtility_Downstream_TasksArgs = {
  args: Utility_Downstream_Tasks_Args;
  distinct_on?: InputMaybe<Array<Utility_Traversal_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Utility_Traversal_Order_By>>;
  where?: InputMaybe<Utility_Traversal_Bool_Exp>;
};

export type QueryUtility_Downstream_Tasks_AggregateArgs = {
  args: Utility_Downstream_Tasks_Args;
  distinct_on?: InputMaybe<Array<Utility_Traversal_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Utility_Traversal_Order_By>>;
  where?: InputMaybe<Utility_Traversal_Bool_Exp>;
};

export type QueryUtility_TraversalArgs = {
  distinct_on?: InputMaybe<Array<Utility_Traversal_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Utility_Traversal_Order_By>>;
  where?: InputMaybe<Utility_Traversal_Bool_Exp>;
};

export type QueryUtility_Traversal_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Utility_Traversal_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Utility_Traversal_Order_By>>;
  where?: InputMaybe<Utility_Traversal_Bool_Exp>;
};

export type QueryUtility_Upstream_TasksArgs = {
  args: Utility_Upstream_Tasks_Args;
  distinct_on?: InputMaybe<Array<Utility_Traversal_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Utility_Traversal_Order_By>>;
  where?: InputMaybe<Utility_Traversal_Bool_Exp>;
};

export type QueryUtility_Upstream_Tasks_AggregateArgs = {
  args: Utility_Upstream_Tasks_Args;
  distinct_on?: InputMaybe<Array<Utility_Traversal_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Utility_Traversal_Order_By>>;
  where?: InputMaybe<Utility_Traversal_Bool_Exp>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["String"]>;
  _gt?: InputMaybe<Scalars["String"]>;
  _gte?: InputMaybe<Scalars["String"]>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars["String"]>;
  _in?: InputMaybe<Array<Scalars["String"]>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars["String"]>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars["String"]>;
  _lt?: InputMaybe<Scalars["String"]>;
  _lte?: InputMaybe<Scalars["String"]>;
  _neq?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars["String"]>;
  _nin?: InputMaybe<Array<Scalars["String"]>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars["String"]>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars["String"]>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars["String"]>;
};

export type Subscription = {
  __typename?: "Subscription";
  /** fetch data from the table: "agent" */
  agent: Array<Agent>;
  /** fetch aggregated fields from the table: "agent" */
  agent_aggregate: Agent_Aggregate;
  /** fetch data from the table: "agent" using primary key columns */
  agent_by_pk?: Maybe<Agent>;
  /** fetch data from the table: "agent_config" */
  agent_config: Array<Agent_Config>;
  /** fetch aggregated fields from the table: "agent_config" */
  agent_config_aggregate: Agent_Config_Aggregate;
  /** fetch data from the table: "agent_config" using primary key columns */
  agent_config_by_pk?: Maybe<Agent_Config>;
  /** fetch data from the table: "cloud_hook" */
  cloud_hook: Array<Cloud_Hook>;
  /** fetch aggregated fields from the table: "cloud_hook" */
  cloud_hook_aggregate: Cloud_Hook_Aggregate;
  /** fetch data from the table: "cloud_hook" using primary key columns */
  cloud_hook_by_pk?: Maybe<Cloud_Hook>;
  /** fetch data from the table: "edge" */
  edge: Array<Edge>;
  /** fetch aggregated fields from the table: "edge" */
  edge_aggregate: Edge_Aggregate;
  /** fetch data from the table: "edge" using primary key columns */
  edge_by_pk?: Maybe<Edge>;
  /** fetch data from the table: "flow" */
  flow: Array<Flow>;
  /** fetch aggregated fields from the table: "flow" */
  flow_aggregate: Flow_Aggregate;
  /** fetch data from the table: "flow" using primary key columns */
  flow_by_pk?: Maybe<Flow>;
  /** fetch data from the table: "flow_group" */
  flow_group: Array<Flow_Group>;
  /** fetch aggregated fields from the table: "flow_group" */
  flow_group_aggregate: Flow_Group_Aggregate;
  /** fetch data from the table: "flow_group" using primary key columns */
  flow_group_by_pk?: Maybe<Flow_Group>;
  /** fetch data from the table: "flow_run" */
  flow_run: Array<Flow_Run>;
  /** fetch aggregated fields from the table: "flow_run" */
  flow_run_aggregate: Flow_Run_Aggregate;
  /** fetch data from the table: "flow_run" using primary key columns */
  flow_run_by_pk?: Maybe<Flow_Run>;
  /** fetch data from the table: "flow_run_state" */
  flow_run_state: Array<Flow_Run_State>;
  /** fetch aggregated fields from the table: "flow_run_state" */
  flow_run_state_aggregate: Flow_Run_State_Aggregate;
  /** fetch data from the table: "flow_run_state" using primary key columns */
  flow_run_state_by_pk?: Maybe<Flow_Run_State>;
  /** fetch data from the table: "log" */
  log: Array<Log>;
  /** fetch aggregated fields from the table: "log" */
  log_aggregate: Log_Aggregate;
  /** fetch data from the table: "message" */
  message: Array<Message>;
  /** fetch aggregated fields from the table: "message" */
  message_aggregate: Message_Aggregate;
  /** fetch data from the table: "message" using primary key columns */
  message_by_pk?: Maybe<Message>;
  /** fetch data from the table: "project" */
  project: Array<Project>;
  /** fetch aggregated fields from the table: "project" */
  project_aggregate: Project_Aggregate;
  /** fetch data from the table: "project" using primary key columns */
  project_by_pk?: Maybe<Project>;
  /** fetch data from the table: "task" */
  task: Array<Task>;
  /** fetch aggregated fields from the table: "task" */
  task_aggregate: Task_Aggregate;
  /** fetch data from the table: "task" using primary key columns */
  task_by_pk?: Maybe<Task>;
  /** fetch data from the table: "task_run" */
  task_run: Array<Task_Run>;
  /** fetch aggregated fields from the table: "task_run" */
  task_run_aggregate: Task_Run_Aggregate;
  /** fetch data from the table: "task_run_artifact" */
  task_run_artifact: Array<Task_Run_Artifact>;
  /** fetch aggregated fields from the table: "task_run_artifact" */
  task_run_artifact_aggregate: Task_Run_Artifact_Aggregate;
  /** fetch data from the table: "task_run_artifact" using primary key columns */
  task_run_artifact_by_pk?: Maybe<Task_Run_Artifact>;
  /** fetch data from the table: "task_run" using primary key columns */
  task_run_by_pk?: Maybe<Task_Run>;
  /** fetch data from the table: "task_run_state" */
  task_run_state: Array<Task_Run_State>;
  /** fetch aggregated fields from the table: "task_run_state" */
  task_run_state_aggregate: Task_Run_State_Aggregate;
  /** fetch data from the table: "task_run_state" using primary key columns */
  task_run_state_by_pk?: Maybe<Task_Run_State>;
  /** fetch data from the table: "tenant" */
  tenant: Array<Tenant>;
  /** fetch aggregated fields from the table: "tenant" */
  tenant_aggregate: Tenant_Aggregate;
  /** fetch data from the table: "tenant" using primary key columns */
  tenant_by_pk?: Maybe<Tenant>;
  /** execute function "utility.downstream_tasks" which returns "utility.traversal" */
  utility_downstream_tasks: Array<Utility_Traversal>;
  /** execute function "utility.downstream_tasks" and query aggregates on result of table type "utility.traversal" */
  utility_downstream_tasks_aggregate: Utility_Traversal_Aggregate;
  /** fetch data from the table: "utility.traversal" */
  utility_traversal: Array<Utility_Traversal>;
  /** fetch aggregated fields from the table: "utility.traversal" */
  utility_traversal_aggregate: Utility_Traversal_Aggregate;
  /** execute function "utility.upstream_tasks" which returns "utility.traversal" */
  utility_upstream_tasks: Array<Utility_Traversal>;
  /** execute function "utility.upstream_tasks" and query aggregates on result of table type "utility.traversal" */
  utility_upstream_tasks_aggregate: Utility_Traversal_Aggregate;
};

export type SubscriptionAgentArgs = {
  distinct_on?: InputMaybe<Array<Agent_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Agent_Order_By>>;
  where?: InputMaybe<Agent_Bool_Exp>;
};

export type SubscriptionAgent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agent_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Agent_Order_By>>;
  where?: InputMaybe<Agent_Bool_Exp>;
};

export type SubscriptionAgent_By_PkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionAgent_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Agent_Config_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Agent_Config_Order_By>>;
  where?: InputMaybe<Agent_Config_Bool_Exp>;
};

export type SubscriptionAgent_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agent_Config_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Agent_Config_Order_By>>;
  where?: InputMaybe<Agent_Config_Bool_Exp>;
};

export type SubscriptionAgent_Config_By_PkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionCloud_HookArgs = {
  distinct_on?: InputMaybe<Array<Cloud_Hook_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Cloud_Hook_Order_By>>;
  where?: InputMaybe<Cloud_Hook_Bool_Exp>;
};

export type SubscriptionCloud_Hook_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cloud_Hook_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Cloud_Hook_Order_By>>;
  where?: InputMaybe<Cloud_Hook_Bool_Exp>;
};

export type SubscriptionCloud_Hook_By_PkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionEdgeArgs = {
  distinct_on?: InputMaybe<Array<Edge_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Edge_Order_By>>;
  where?: InputMaybe<Edge_Bool_Exp>;
};

export type SubscriptionEdge_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Edge_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Edge_Order_By>>;
  where?: InputMaybe<Edge_Bool_Exp>;
};

export type SubscriptionEdge_By_PkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionFlowArgs = {
  distinct_on?: InputMaybe<Array<Flow_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Order_By>>;
  where?: InputMaybe<Flow_Bool_Exp>;
};

export type SubscriptionFlow_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flow_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Order_By>>;
  where?: InputMaybe<Flow_Bool_Exp>;
};

export type SubscriptionFlow_By_PkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionFlow_GroupArgs = {
  distinct_on?: InputMaybe<Array<Flow_Group_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Group_Order_By>>;
  where?: InputMaybe<Flow_Group_Bool_Exp>;
};

export type SubscriptionFlow_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flow_Group_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Group_Order_By>>;
  where?: InputMaybe<Flow_Group_Bool_Exp>;
};

export type SubscriptionFlow_Group_By_PkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionFlow_RunArgs = {
  distinct_on?: InputMaybe<Array<Flow_Run_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Run_Order_By>>;
  where?: InputMaybe<Flow_Run_Bool_Exp>;
};

export type SubscriptionFlow_Run_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flow_Run_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Run_Order_By>>;
  where?: InputMaybe<Flow_Run_Bool_Exp>;
};

export type SubscriptionFlow_Run_By_PkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionFlow_Run_StateArgs = {
  distinct_on?: InputMaybe<Array<Flow_Run_State_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Run_State_Order_By>>;
  where?: InputMaybe<Flow_Run_State_Bool_Exp>;
};

export type SubscriptionFlow_Run_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flow_Run_State_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Run_State_Order_By>>;
  where?: InputMaybe<Flow_Run_State_Bool_Exp>;
};

export type SubscriptionFlow_Run_State_By_PkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionLogArgs = {
  distinct_on?: InputMaybe<Array<Log_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Log_Order_By>>;
  where?: InputMaybe<Log_Bool_Exp>;
};

export type SubscriptionLog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Log_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Log_Order_By>>;
  where?: InputMaybe<Log_Bool_Exp>;
};

export type SubscriptionMessageArgs = {
  distinct_on?: InputMaybe<Array<Message_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Order_By>>;
  where?: InputMaybe<Message_Bool_Exp>;
};

export type SubscriptionMessage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Message_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Message_Order_By>>;
  where?: InputMaybe<Message_Bool_Exp>;
};

export type SubscriptionMessage_By_PkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionProjectArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

export type SubscriptionProject_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

export type SubscriptionProject_By_PkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionTaskArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

export type SubscriptionTask_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

export type SubscriptionTask_By_PkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionTask_RunArgs = {
  distinct_on?: InputMaybe<Array<Task_Run_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Run_Order_By>>;
  where?: InputMaybe<Task_Run_Bool_Exp>;
};

export type SubscriptionTask_Run_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Run_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Run_Order_By>>;
  where?: InputMaybe<Task_Run_Bool_Exp>;
};

export type SubscriptionTask_Run_ArtifactArgs = {
  distinct_on?: InputMaybe<Array<Task_Run_Artifact_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Run_Artifact_Order_By>>;
  where?: InputMaybe<Task_Run_Artifact_Bool_Exp>;
};

export type SubscriptionTask_Run_Artifact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Run_Artifact_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Run_Artifact_Order_By>>;
  where?: InputMaybe<Task_Run_Artifact_Bool_Exp>;
};

export type SubscriptionTask_Run_Artifact_By_PkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionTask_Run_By_PkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionTask_Run_StateArgs = {
  distinct_on?: InputMaybe<Array<Task_Run_State_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Run_State_Order_By>>;
  where?: InputMaybe<Task_Run_State_Bool_Exp>;
};

export type SubscriptionTask_Run_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Run_State_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Run_State_Order_By>>;
  where?: InputMaybe<Task_Run_State_Bool_Exp>;
};

export type SubscriptionTask_Run_State_By_PkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionTenantArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tenant_Order_By>>;
  where?: InputMaybe<Tenant_Bool_Exp>;
};

export type SubscriptionTenant_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tenant_Order_By>>;
  where?: InputMaybe<Tenant_Bool_Exp>;
};

export type SubscriptionTenant_By_PkArgs = {
  id: Scalars["uuid"];
};

export type SubscriptionUtility_Downstream_TasksArgs = {
  args: Utility_Downstream_Tasks_Args;
  distinct_on?: InputMaybe<Array<Utility_Traversal_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Utility_Traversal_Order_By>>;
  where?: InputMaybe<Utility_Traversal_Bool_Exp>;
};

export type SubscriptionUtility_Downstream_Tasks_AggregateArgs = {
  args: Utility_Downstream_Tasks_Args;
  distinct_on?: InputMaybe<Array<Utility_Traversal_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Utility_Traversal_Order_By>>;
  where?: InputMaybe<Utility_Traversal_Bool_Exp>;
};

export type SubscriptionUtility_TraversalArgs = {
  distinct_on?: InputMaybe<Array<Utility_Traversal_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Utility_Traversal_Order_By>>;
  where?: InputMaybe<Utility_Traversal_Bool_Exp>;
};

export type SubscriptionUtility_Traversal_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Utility_Traversal_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Utility_Traversal_Order_By>>;
  where?: InputMaybe<Utility_Traversal_Bool_Exp>;
};

export type SubscriptionUtility_Upstream_TasksArgs = {
  args: Utility_Upstream_Tasks_Args;
  distinct_on?: InputMaybe<Array<Utility_Traversal_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Utility_Traversal_Order_By>>;
  where?: InputMaybe<Utility_Traversal_Bool_Exp>;
};

export type SubscriptionUtility_Upstream_Tasks_AggregateArgs = {
  args: Utility_Upstream_Tasks_Args;
  distinct_on?: InputMaybe<Array<Utility_Traversal_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Utility_Traversal_Order_By>>;
  where?: InputMaybe<Utility_Traversal_Bool_Exp>;
};

/** columns and relationships of "agent" */
export type Agent = {
  __typename?: "agent";
  /** An object relationship */
  agent_config?: Maybe<Agent_Config>;
  agent_config_id?: Maybe<Scalars["uuid"]>;
  core_version?: Maybe<Scalars["String"]>;
  created: Scalars["timestamptz"];
  /** An array relationship */
  flow_runs: Array<Flow_Run>;
  /** An aggregate relationship */
  flow_runs_aggregate: Flow_Run_Aggregate;
  id: Scalars["uuid"];
  labels: Scalars["jsonb"];
  last_queried?: Maybe<Scalars["timestamptz"]>;
  name?: Maybe<Scalars["String"]>;
  tenant_id: Scalars["uuid"];
  type?: Maybe<Scalars["String"]>;
  updated: Scalars["timestamptz"];
};

/** columns and relationships of "agent" */
export type AgentFlow_RunsArgs = {
  distinct_on?: InputMaybe<Array<Flow_Run_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Run_Order_By>>;
  where?: InputMaybe<Flow_Run_Bool_Exp>;
};

/** columns and relationships of "agent" */
export type AgentFlow_Runs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flow_Run_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Run_Order_By>>;
  where?: InputMaybe<Flow_Run_Bool_Exp>;
};

/** columns and relationships of "agent" */
export type AgentLabelsArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "agent" */
export type Agent_Aggregate = {
  __typename?: "agent_aggregate";
  aggregate?: Maybe<Agent_Aggregate_Fields>;
  nodes: Array<Agent>;
};

/** aggregate fields of "agent" */
export type Agent_Aggregate_Fields = {
  __typename?: "agent_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Agent_Max_Fields>;
  min?: Maybe<Agent_Min_Fields>;
};

/** aggregate fields of "agent" */
export type Agent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Agent_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "agent" */
export type Agent_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Agent_Max_Order_By>;
  min?: InputMaybe<Agent_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Agent_Append_Input = {
  labels?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "agent" */
export type Agent_Arr_Rel_Insert_Input = {
  data: Array<Agent_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Agent_On_Conflict>;
};

/** Boolean expression to filter rows from the table "agent". All fields are combined with a logical 'AND'. */
export type Agent_Bool_Exp = {
  _and?: InputMaybe<Array<Agent_Bool_Exp>>;
  _not?: InputMaybe<Agent_Bool_Exp>;
  _or?: InputMaybe<Array<Agent_Bool_Exp>>;
  agent_config?: InputMaybe<Agent_Config_Bool_Exp>;
  agent_config_id?: InputMaybe<Uuid_Comparison_Exp>;
  core_version?: InputMaybe<String_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  flow_runs?: InputMaybe<Flow_Run_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  labels?: InputMaybe<Jsonb_Comparison_Exp>;
  last_queried?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** columns and relationships of "agent_config" */
export type Agent_Config = {
  __typename?: "agent_config";
  /** An array relationship */
  agents: Array<Agent>;
  /** An aggregate relationship */
  agents_aggregate: Agent_Aggregate;
  created: Scalars["timestamptz"];
  id: Scalars["uuid"];
  name?: Maybe<Scalars["String"]>;
  settings: Scalars["jsonb"];
  tenant_id: Scalars["uuid"];
  updated: Scalars["timestamptz"];
};

/** columns and relationships of "agent_config" */
export type Agent_ConfigAgentsArgs = {
  distinct_on?: InputMaybe<Array<Agent_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Agent_Order_By>>;
  where?: InputMaybe<Agent_Bool_Exp>;
};

/** columns and relationships of "agent_config" */
export type Agent_ConfigAgents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agent_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Agent_Order_By>>;
  where?: InputMaybe<Agent_Bool_Exp>;
};

/** columns and relationships of "agent_config" */
export type Agent_ConfigSettingsArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "agent_config" */
export type Agent_Config_Aggregate = {
  __typename?: "agent_config_aggregate";
  aggregate?: Maybe<Agent_Config_Aggregate_Fields>;
  nodes: Array<Agent_Config>;
};

/** aggregate fields of "agent_config" */
export type Agent_Config_Aggregate_Fields = {
  __typename?: "agent_config_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Agent_Config_Max_Fields>;
  min?: Maybe<Agent_Config_Min_Fields>;
};

/** aggregate fields of "agent_config" */
export type Agent_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Agent_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Agent_Config_Append_Input = {
  settings?: InputMaybe<Scalars["jsonb"]>;
};

/** Boolean expression to filter rows from the table "agent_config". All fields are combined with a logical 'AND'. */
export type Agent_Config_Bool_Exp = {
  _and?: InputMaybe<Array<Agent_Config_Bool_Exp>>;
  _not?: InputMaybe<Agent_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Agent_Config_Bool_Exp>>;
  agents?: InputMaybe<Agent_Bool_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  settings?: InputMaybe<Jsonb_Comparison_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "agent_config" */
export enum Agent_Config_Constraint {
  /** unique or primary key constraint */
  AgentConfigPkey = "agent_config_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Agent_Config_Delete_At_Path_Input = {
  settings?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Agent_Config_Delete_Elem_Input = {
  settings?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Agent_Config_Delete_Key_Input = {
  settings?: InputMaybe<Scalars["String"]>;
};

export type Agent_Config_Id_Payload = {
  __typename?: "agent_config_id_payload";
  id?: Maybe<Scalars["UUID"]>;
};

/** input type for inserting data into table "agent_config" */
export type Agent_Config_Insert_Input = {
  agents?: InputMaybe<Agent_Arr_Rel_Insert_Input>;
  created?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  settings?: InputMaybe<Scalars["jsonb"]>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Agent_Config_Max_Fields = {
  __typename?: "agent_config_max_fields";
  created?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Agent_Config_Min_Fields = {
  __typename?: "agent_config_min_fields";
  created?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "agent_config" */
export type Agent_Config_Mutation_Response = {
  __typename?: "agent_config_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Agent_Config>;
};

/** input type for inserting object relation for remote table "agent_config" */
export type Agent_Config_Obj_Rel_Insert_Input = {
  data: Agent_Config_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Agent_Config_On_Conflict>;
};

/** on conflict condition type for table "agent_config" */
export type Agent_Config_On_Conflict = {
  constraint: Agent_Config_Constraint;
  update_columns?: Array<Agent_Config_Update_Column>;
  where?: InputMaybe<Agent_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "agent_config". */
export type Agent_Config_Order_By = {
  agents_aggregate?: InputMaybe<Agent_Aggregate_Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  settings?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: agent_config */
export type Agent_Config_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Agent_Config_Prepend_Input = {
  settings?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "agent_config" */
export enum Agent_Config_Select_Column {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Settings = "settings",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Updated = "updated",
}

/** input type for updating data in table "agent_config" */
export type Agent_Config_Set_Input = {
  created?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  settings?: InputMaybe<Scalars["jsonb"]>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "agent_config" */
export enum Agent_Config_Update_Column {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Settings = "settings",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Updated = "updated",
}

/** unique or primary key constraints on table "agent" */
export enum Agent_Constraint {
  /** unique or primary key constraint */
  AgentPkey = "agent_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Agent_Delete_At_Path_Input = {
  labels?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Agent_Delete_Elem_Input = {
  labels?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Agent_Delete_Key_Input = {
  labels?: InputMaybe<Scalars["String"]>;
};

export type Agent_Id_Payload = {
  __typename?: "agent_id_payload";
  id?: Maybe<Scalars["UUID"]>;
};

/** input type for inserting data into table "agent" */
export type Agent_Insert_Input = {
  agent_config?: InputMaybe<Agent_Config_Obj_Rel_Insert_Input>;
  agent_config_id?: InputMaybe<Scalars["uuid"]>;
  core_version?: InputMaybe<Scalars["String"]>;
  created?: InputMaybe<Scalars["timestamptz"]>;
  flow_runs?: InputMaybe<Flow_Run_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["uuid"]>;
  labels?: InputMaybe<Scalars["jsonb"]>;
  last_queried?: InputMaybe<Scalars["timestamptz"]>;
  name?: InputMaybe<Scalars["String"]>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  type?: InputMaybe<Scalars["String"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Agent_Max_Fields = {
  __typename?: "agent_max_fields";
  agent_config_id?: Maybe<Scalars["uuid"]>;
  core_version?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_queried?: Maybe<Scalars["timestamptz"]>;
  name?: Maybe<Scalars["String"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  type?: Maybe<Scalars["String"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "agent" */
export type Agent_Max_Order_By = {
  agent_config_id?: InputMaybe<Order_By>;
  core_version?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_queried?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Agent_Min_Fields = {
  __typename?: "agent_min_fields";
  agent_config_id?: Maybe<Scalars["uuid"]>;
  core_version?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_queried?: Maybe<Scalars["timestamptz"]>;
  name?: Maybe<Scalars["String"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  type?: Maybe<Scalars["String"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "agent" */
export type Agent_Min_Order_By = {
  agent_config_id?: InputMaybe<Order_By>;
  core_version?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_queried?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "agent" */
export type Agent_Mutation_Response = {
  __typename?: "agent_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Agent>;
};

/** input type for inserting object relation for remote table "agent" */
export type Agent_Obj_Rel_Insert_Input = {
  data: Agent_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Agent_On_Conflict>;
};

/** on conflict condition type for table "agent" */
export type Agent_On_Conflict = {
  constraint: Agent_Constraint;
  update_columns?: Array<Agent_Update_Column>;
  where?: InputMaybe<Agent_Bool_Exp>;
};

/** Ordering options when selecting data from "agent". */
export type Agent_Order_By = {
  agent_config?: InputMaybe<Agent_Config_Order_By>;
  agent_config_id?: InputMaybe<Order_By>;
  core_version?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  flow_runs_aggregate?: InputMaybe<Flow_Run_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  labels?: InputMaybe<Order_By>;
  last_queried?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: agent */
export type Agent_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Agent_Prepend_Input = {
  labels?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "agent" */
export enum Agent_Select_Column {
  /** column name */
  AgentConfigId = "agent_config_id",
  /** column name */
  CoreVersion = "core_version",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Labels = "labels",
  /** column name */
  LastQueried = "last_queried",
  /** column name */
  Name = "name",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Type = "type",
  /** column name */
  Updated = "updated",
}

/** input type for updating data in table "agent" */
export type Agent_Set_Input = {
  agent_config_id?: InputMaybe<Scalars["uuid"]>;
  core_version?: InputMaybe<Scalars["String"]>;
  created?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  labels?: InputMaybe<Scalars["jsonb"]>;
  last_queried?: InputMaybe<Scalars["timestamptz"]>;
  name?: InputMaybe<Scalars["String"]>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  type?: InputMaybe<Scalars["String"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "agent" */
export enum Agent_Update_Column {
  /** column name */
  AgentConfigId = "agent_config_id",
  /** column name */
  CoreVersion = "core_version",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Labels = "labels",
  /** column name */
  LastQueried = "last_queried",
  /** column name */
  Name = "name",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Type = "type",
  /** column name */
  Updated = "updated",
}

export type Api_Info = {
  __typename?: "api_info";
  backend?: Maybe<Scalars["String"]>;
  core_version?: Maybe<Scalars["String"]>;
  mode?: Maybe<Scalars["String"]>;
  release_timestamp?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["String"]>;
};

export type Archive_Flow_Input = {
  /** The ID of the flow to archive */
  flow_id: Scalars["UUID"];
};

export type Cancel_Flow_Run_Input = {
  flow_run_id: Scalars["UUID"];
};

export type Cancel_Flow_Run_Payload = {
  __typename?: "cancel_flow_run_payload";
  state?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "cloud_hook" */
export type Cloud_Hook = {
  __typename?: "cloud_hook";
  active: Scalars["Boolean"];
  config: Scalars["jsonb"];
  created: Scalars["timestamptz"];
  id: Scalars["uuid"];
  name?: Maybe<Scalars["String"]>;
  states?: Maybe<Scalars["jsonb"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  type: Scalars["String"];
  updated: Scalars["timestamptz"];
  version_group_id?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "cloud_hook" */
export type Cloud_HookConfigArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "cloud_hook" */
export type Cloud_HookStatesArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "cloud_hook" */
export type Cloud_Hook_Aggregate = {
  __typename?: "cloud_hook_aggregate";
  aggregate?: Maybe<Cloud_Hook_Aggregate_Fields>;
  nodes: Array<Cloud_Hook>;
};

/** aggregate fields of "cloud_hook" */
export type Cloud_Hook_Aggregate_Fields = {
  __typename?: "cloud_hook_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Cloud_Hook_Max_Fields>;
  min?: Maybe<Cloud_Hook_Min_Fields>;
};

/** aggregate fields of "cloud_hook" */
export type Cloud_Hook_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cloud_Hook_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Cloud_Hook_Append_Input = {
  config?: InputMaybe<Scalars["jsonb"]>;
  states?: InputMaybe<Scalars["jsonb"]>;
};

/** Boolean expression to filter rows from the table "cloud_hook". All fields are combined with a logical 'AND'. */
export type Cloud_Hook_Bool_Exp = {
  _and?: InputMaybe<Array<Cloud_Hook_Bool_Exp>>;
  _not?: InputMaybe<Cloud_Hook_Bool_Exp>;
  _or?: InputMaybe<Array<Cloud_Hook_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  config?: InputMaybe<Jsonb_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  states?: InputMaybe<Jsonb_Comparison_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  version_group_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "cloud_hook" */
export enum Cloud_Hook_Constraint {
  /** unique or primary key constraint */
  CloudHookPkey = "cloud_hook_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Cloud_Hook_Delete_At_Path_Input = {
  config?: InputMaybe<Array<Scalars["String"]>>;
  states?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Cloud_Hook_Delete_Elem_Input = {
  config?: InputMaybe<Scalars["Int"]>;
  states?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Cloud_Hook_Delete_Key_Input = {
  config?: InputMaybe<Scalars["String"]>;
  states?: InputMaybe<Scalars["String"]>;
};

export type Cloud_Hook_Id_Payload = {
  __typename?: "cloud_hook_id_payload";
  id?: Maybe<Scalars["UUID"]>;
};

/** input type for inserting data into table "cloud_hook" */
export type Cloud_Hook_Insert_Input = {
  active?: InputMaybe<Scalars["Boolean"]>;
  config?: InputMaybe<Scalars["jsonb"]>;
  created?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  states?: InputMaybe<Scalars["jsonb"]>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  type?: InputMaybe<Scalars["String"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
  version_group_id?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Cloud_Hook_Max_Fields = {
  __typename?: "cloud_hook_max_fields";
  created?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  type?: Maybe<Scalars["String"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
  version_group_id?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Cloud_Hook_Min_Fields = {
  __typename?: "cloud_hook_min_fields";
  created?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  type?: Maybe<Scalars["String"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
  version_group_id?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "cloud_hook" */
export type Cloud_Hook_Mutation_Response = {
  __typename?: "cloud_hook_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Cloud_Hook>;
};

/** on conflict condition type for table "cloud_hook" */
export type Cloud_Hook_On_Conflict = {
  constraint: Cloud_Hook_Constraint;
  update_columns?: Array<Cloud_Hook_Update_Column>;
  where?: InputMaybe<Cloud_Hook_Bool_Exp>;
};

/** Ordering options when selecting data from "cloud_hook". */
export type Cloud_Hook_Order_By = {
  active?: InputMaybe<Order_By>;
  config?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  states?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  version_group_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: cloud_hook */
export type Cloud_Hook_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Cloud_Hook_Prepend_Input = {
  config?: InputMaybe<Scalars["jsonb"]>;
  states?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "cloud_hook" */
export enum Cloud_Hook_Select_Column {
  /** column name */
  Active = "active",
  /** column name */
  Config = "config",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  States = "states",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Type = "type",
  /** column name */
  Updated = "updated",
  /** column name */
  VersionGroupId = "version_group_id",
}

/** input type for updating data in table "cloud_hook" */
export type Cloud_Hook_Set_Input = {
  active?: InputMaybe<Scalars["Boolean"]>;
  config?: InputMaybe<Scalars["jsonb"]>;
  created?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  states?: InputMaybe<Scalars["jsonb"]>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  type?: InputMaybe<Scalars["String"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
  version_group_id?: InputMaybe<Scalars["String"]>;
};

export enum Cloud_Hook_Type {
  Pagerduty = "PAGERDUTY",
  PrefectMessage = "PREFECT_MESSAGE",
  SlackWebhook = "SLACK_WEBHOOK",
  Twilio = "TWILIO",
  Webhook = "WEBHOOK",
}

/** update columns of table "cloud_hook" */
export enum Cloud_Hook_Update_Column {
  /** column name */
  Active = "active",
  /** column name */
  Config = "config",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  States = "states",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Type = "type",
  /** column name */
  Updated = "updated",
  /** column name */
  VersionGroupId = "version_group_id",
}

export type Create_Agent_Config_Input = {
  /** Agent config name */
  name?: InputMaybe<Scalars["String"]>;
  /** Optional agent settings */
  settings?: InputMaybe<Scalars["JSON"]>;
  /** The tenant */
  tenant_id: Scalars["UUID"];
};

export type Create_Cloud_Hook_Input = {
  config?: InputMaybe<Scalars["JSON"]>;
  name?: InputMaybe<Scalars["String"]>;
  states: Array<Scalars["String"]>;
  tenant_id: Scalars["UUID"];
  type: Cloud_Hook_Type;
  version_group_id?: InputMaybe<Scalars["String"]>;
};

export type Create_Flow_From_Compressed_String_Input = {
  /** An optional description of this Flow */
  description?: InputMaybe<Scalars["String"]>;
  /** An optional idempotency key for this flow to prevent multiple sequential creations within the version group */
  idempotency_key?: InputMaybe<Scalars["String"]>;
  /** The ID of the project to associate with this flow */
  project_id: Scalars["UUID"];
  /** The serialized representation of this flow (usually created from flow.serialize()) */
  serialized_flow: Scalars["String"];
  /** A boolean specifying whether to begin scheduling runs for this flow immediately */
  set_schedule_active?: InputMaybe<Scalars["Boolean"]>;
  /** An optional string specifying the version group - if provided, this flow will be added to the given version group; when not provided the flow will be versioned by name / project. */
  version_group_id?: InputMaybe<Scalars["String"]>;
};

export type Create_Flow_Input = {
  /** An optional description of this Flow */
  description?: InputMaybe<Scalars["String"]>;
  /** An optional idempotency key for this flow to prevent multiple sequential creations within the version group */
  idempotency_key?: InputMaybe<Scalars["String"]>;
  /** The ID of the project to associate with this flow */
  project_id: Scalars["UUID"];
  /** The serialized representation of this flow (usually created from flow.serialize()) */
  serialized_flow: Scalars["JSON"];
  /** A boolean specifying whether to begin scheduling runs for this flow immediately */
  set_schedule_active?: InputMaybe<Scalars["Boolean"]>;
  /** An optional string specifying the version group - if provided, this flow will be added to the given version group; when not provided the flow will be versioned by name / project. */
  version_group_id?: InputMaybe<Scalars["String"]>;
};

export type Create_Flow_Run_Input = {
  /** A JSON payload of key / value pairs to be placed in Prefect context for this run */
  context?: InputMaybe<Scalars["JSON"]>;
  /** The ID of the flow to schedule a run for */
  flow_id?: InputMaybe<Scalars["UUID"]>;
  /** An optional name to provide this run - useful for searching in the UI or crafting GraphQL queries */
  flow_run_name?: InputMaybe<Scalars["String"]>;
  /** An optional idempotency key for this run - if provided, multiple calls with this same key will not produce additional runs */
  idempotency_key?: InputMaybe<Scalars["String"]>;
  /** A list of labels to apply to the newly created run */
  labels?: InputMaybe<Array<Scalars["String"]>>;
  /** A JSON payload specifying parameter values for any parameters in this flow */
  parameters?: InputMaybe<Scalars["JSON"]>;
  /** A run config override to apply to the newly created run */
  run_config?: InputMaybe<Scalars["JSON"]>;
  /** An optional start time to schedule this run for - if not provided, will be scheduled immediately */
  scheduled_start_time?: InputMaybe<Scalars["DateTime"]>;
  /** The version group to schedule a run for - if provided, the unique unarchived version in this version group will be run; this input is useful as a stable API for running a regularly updated flow */
  version_group_id?: InputMaybe<Scalars["String"]>;
};

export type Create_Project_Input = {
  /** An optional description of this project - can be updated later with setProjectDescription */
  description?: InputMaybe<Scalars["String"]>;
  /** The name of the project to create */
  name: Scalars["String"];
  /** The tenant */
  tenant_id: Scalars["UUID"];
};

export type Create_Task_Run_Artifact_Input = {
  data: Scalars["JSON"];
  kind: Scalars["String"];
  task_run_id: Scalars["UUID"];
  tenant_id?: InputMaybe<Scalars["UUID"]>;
};

export type Create_Tenant_Input = {
  name: Scalars["String"];
  slug: Scalars["String"];
};

export type Cron_Clock_Input = {
  /** A cron string representing how often the flow should run */
  cron: Scalars["String"];
  /** Parameter defaults associated with this clock */
  parameter_defaults?: InputMaybe<Scalars["JSON"]>;
};

export type Delete_Agent_Config_Input = {
  /** The ID of the agent config to delete */
  agent_config_id: Scalars["UUID"];
};

export type Delete_Agent_Input = {
  /** The ID of the agent to delete */
  agent_id: Scalars["UUID"];
};

export type Delete_Cloud_Hook_Input = {
  cloud_hook_id: Scalars["UUID"];
};

export type Delete_Flow_Group_Input = {
  /** The ID of the flow group to delete */
  flow_group_id: Scalars["UUID"];
};

export type Delete_Flow_Group_Schedule_Input = {
  /** The ID of the flow group to update */
  flow_group_id: Scalars["UUID"];
};

export type Delete_Flow_Input = {
  /** The ID of the flow to delete */
  flow_id: Scalars["UUID"];
};

export type Delete_Flow_Run_Input = {
  flow_run_id: Scalars["UUID"];
};

export type Delete_Message_Input = {
  message_id: Scalars["UUID"];
};

export type Delete_Project_Input = {
  /** The ID of the project to delete */
  project_id: Scalars["UUID"];
};

export type Delete_Task_Run_Artifact_Input = {
  task_run_artifact_id: Scalars["UUID"];
};

export type Delete_Tenant_Input = {
  confirm: Scalars["Boolean"];
  tenant_id: Scalars["UUID"];
};

export type Disable_Flow_Heartbeat_Input = {
  /** The ID of the flow to update */
  flow_id: Scalars["UUID"];
};

export type Disable_Flow_Lazarus_Process_Input = {
  /** The ID of the flow to update */
  flow_id: Scalars["UUID"];
};

/** columns and relationships of "edge" */
export type Edge = {
  __typename?: "edge";
  created: Scalars["timestamptz"];
  /** An object relationship */
  downstream_task: Task;
  downstream_task_id: Scalars["uuid"];
  /** An object relationship */
  flow: Flow;
  flow_id: Scalars["uuid"];
  id: Scalars["uuid"];
  key?: Maybe<Scalars["String"]>;
  mapped: Scalars["Boolean"];
  tenant_id?: Maybe<Scalars["uuid"]>;
  updated: Scalars["timestamptz"];
  /** An object relationship */
  upstream_task: Task;
  upstream_task_id: Scalars["uuid"];
};

/** aggregated selection of "edge" */
export type Edge_Aggregate = {
  __typename?: "edge_aggregate";
  aggregate?: Maybe<Edge_Aggregate_Fields>;
  nodes: Array<Edge>;
};

/** aggregate fields of "edge" */
export type Edge_Aggregate_Fields = {
  __typename?: "edge_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Edge_Max_Fields>;
  min?: Maybe<Edge_Min_Fields>;
};

/** aggregate fields of "edge" */
export type Edge_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Edge_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "edge" */
export type Edge_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Edge_Max_Order_By>;
  min?: InputMaybe<Edge_Min_Order_By>;
};

/** input type for inserting array relation for remote table "edge" */
export type Edge_Arr_Rel_Insert_Input = {
  data: Array<Edge_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Edge_On_Conflict>;
};

/** Boolean expression to filter rows from the table "edge". All fields are combined with a logical 'AND'. */
export type Edge_Bool_Exp = {
  _and?: InputMaybe<Array<Edge_Bool_Exp>>;
  _not?: InputMaybe<Edge_Bool_Exp>;
  _or?: InputMaybe<Array<Edge_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  downstream_task?: InputMaybe<Task_Bool_Exp>;
  downstream_task_id?: InputMaybe<Uuid_Comparison_Exp>;
  flow?: InputMaybe<Flow_Bool_Exp>;
  flow_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  mapped?: InputMaybe<Boolean_Comparison_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  upstream_task?: InputMaybe<Task_Bool_Exp>;
  upstream_task_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "edge" */
export enum Edge_Constraint {
  /** unique or primary key constraint */
  EdgeFlowIdTaskIdsKey = "edge_flow_id_task_ids_key",
  /** unique or primary key constraint */
  EdgePkey = "edge_pkey",
}

/** input type for inserting data into table "edge" */
export type Edge_Insert_Input = {
  created?: InputMaybe<Scalars["timestamptz"]>;
  downstream_task?: InputMaybe<Task_Obj_Rel_Insert_Input>;
  downstream_task_id?: InputMaybe<Scalars["uuid"]>;
  flow?: InputMaybe<Flow_Obj_Rel_Insert_Input>;
  flow_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  key?: InputMaybe<Scalars["String"]>;
  mapped?: InputMaybe<Scalars["Boolean"]>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
  upstream_task?: InputMaybe<Task_Obj_Rel_Insert_Input>;
  upstream_task_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Edge_Max_Fields = {
  __typename?: "edge_max_fields";
  created?: Maybe<Scalars["timestamptz"]>;
  downstream_task_id?: Maybe<Scalars["uuid"]>;
  flow_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  key?: Maybe<Scalars["String"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
  upstream_task_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "edge" */
export type Edge_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  downstream_task_id?: InputMaybe<Order_By>;
  flow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  upstream_task_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Edge_Min_Fields = {
  __typename?: "edge_min_fields";
  created?: Maybe<Scalars["timestamptz"]>;
  downstream_task_id?: Maybe<Scalars["uuid"]>;
  flow_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  key?: Maybe<Scalars["String"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
  upstream_task_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "edge" */
export type Edge_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  downstream_task_id?: InputMaybe<Order_By>;
  flow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  upstream_task_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "edge" */
export type Edge_Mutation_Response = {
  __typename?: "edge_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Edge>;
};

/** on conflict condition type for table "edge" */
export type Edge_On_Conflict = {
  constraint: Edge_Constraint;
  update_columns?: Array<Edge_Update_Column>;
  where?: InputMaybe<Edge_Bool_Exp>;
};

/** Ordering options when selecting data from "edge". */
export type Edge_Order_By = {
  created?: InputMaybe<Order_By>;
  downstream_task?: InputMaybe<Task_Order_By>;
  downstream_task_id?: InputMaybe<Order_By>;
  flow?: InputMaybe<Flow_Order_By>;
  flow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  mapped?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  upstream_task?: InputMaybe<Task_Order_By>;
  upstream_task_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: edge */
export type Edge_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "edge" */
export enum Edge_Select_Column {
  /** column name */
  Created = "created",
  /** column name */
  DownstreamTaskId = "downstream_task_id",
  /** column name */
  FlowId = "flow_id",
  /** column name */
  Id = "id",
  /** column name */
  Key = "key",
  /** column name */
  Mapped = "mapped",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Updated = "updated",
  /** column name */
  UpstreamTaskId = "upstream_task_id",
}

/** input type for updating data in table "edge" */
export type Edge_Set_Input = {
  created?: InputMaybe<Scalars["timestamptz"]>;
  downstream_task_id?: InputMaybe<Scalars["uuid"]>;
  flow_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  key?: InputMaybe<Scalars["String"]>;
  mapped?: InputMaybe<Scalars["Boolean"]>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
  upstream_task_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "edge" */
export enum Edge_Update_Column {
  /** column name */
  Created = "created",
  /** column name */
  DownstreamTaskId = "downstream_task_id",
  /** column name */
  FlowId = "flow_id",
  /** column name */
  Id = "id",
  /** column name */
  Key = "key",
  /** column name */
  Mapped = "mapped",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Updated = "updated",
  /** column name */
  UpstreamTaskId = "upstream_task_id",
}

export type Enable_Flow_Heartbeat_Input = {
  /** The ID of the flow to update */
  flow_id: Scalars["UUID"];
};

export type Enable_Flow_Lazarus_Process_Input = {
  /** The ID of the flow to update */
  flow_id: Scalars["UUID"];
};

/** columns and relationships of "flow" */
export type Flow = {
  __typename?: "flow";
  archived: Scalars["Boolean"];
  core_version?: Maybe<Scalars["String"]>;
  created: Scalars["timestamptz"];
  description?: Maybe<Scalars["String"]>;
  /** An array relationship */
  edges: Array<Edge>;
  /** An aggregate relationship */
  edges_aggregate: Edge_Aggregate;
  environment?: Maybe<Scalars["jsonb"]>;
  /** An object relationship */
  flow_group: Flow_Group;
  flow_group_id: Scalars["uuid"];
  /** An array relationship */
  flow_runs: Array<Flow_Run>;
  /** An aggregate relationship */
  flow_runs_aggregate: Flow_Run_Aggregate;
  id: Scalars["uuid"];
  is_schedule_active: Scalars["Boolean"];
  name: Scalars["String"];
  parameters?: Maybe<Scalars["jsonb"]>;
  /** An object relationship */
  project: Project;
  project_id: Scalars["uuid"];
  run_config?: Maybe<Scalars["jsonb"]>;
  schedule?: Maybe<Scalars["jsonb"]>;
  serialized_flow?: Maybe<Scalars["jsonb"]>;
  settings: Scalars["jsonb"];
  storage?: Maybe<Scalars["jsonb"]>;
  /** An array relationship */
  tasks: Array<Task>;
  /** An aggregate relationship */
  tasks_aggregate: Task_Aggregate;
  /** An object relationship */
  tenant?: Maybe<Tenant>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  updated: Scalars["timestamptz"];
  version?: Maybe<Scalars["Int"]>;
  version_group_id?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "flow" */
export type FlowEdgesArgs = {
  distinct_on?: InputMaybe<Array<Edge_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Edge_Order_By>>;
  where?: InputMaybe<Edge_Bool_Exp>;
};

/** columns and relationships of "flow" */
export type FlowEdges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Edge_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Edge_Order_By>>;
  where?: InputMaybe<Edge_Bool_Exp>;
};

/** columns and relationships of "flow" */
export type FlowEnvironmentArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "flow" */
export type FlowFlow_RunsArgs = {
  distinct_on?: InputMaybe<Array<Flow_Run_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Run_Order_By>>;
  where?: InputMaybe<Flow_Run_Bool_Exp>;
};

/** columns and relationships of "flow" */
export type FlowFlow_Runs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flow_Run_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Run_Order_By>>;
  where?: InputMaybe<Flow_Run_Bool_Exp>;
};

/** columns and relationships of "flow" */
export type FlowParametersArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "flow" */
export type FlowRun_ConfigArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "flow" */
export type FlowScheduleArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "flow" */
export type FlowSerialized_FlowArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "flow" */
export type FlowSettingsArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "flow" */
export type FlowStorageArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "flow" */
export type FlowTasksArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** columns and relationships of "flow" */
export type FlowTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** aggregated selection of "flow" */
export type Flow_Aggregate = {
  __typename?: "flow_aggregate";
  aggregate?: Maybe<Flow_Aggregate_Fields>;
  nodes: Array<Flow>;
};

/** aggregate fields of "flow" */
export type Flow_Aggregate_Fields = {
  __typename?: "flow_aggregate_fields";
  avg?: Maybe<Flow_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Flow_Max_Fields>;
  min?: Maybe<Flow_Min_Fields>;
  stddev?: Maybe<Flow_Stddev_Fields>;
  stddev_pop?: Maybe<Flow_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Flow_Stddev_Samp_Fields>;
  sum?: Maybe<Flow_Sum_Fields>;
  var_pop?: Maybe<Flow_Var_Pop_Fields>;
  var_samp?: Maybe<Flow_Var_Samp_Fields>;
  variance?: Maybe<Flow_Variance_Fields>;
};

/** aggregate fields of "flow" */
export type Flow_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Flow_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "flow" */
export type Flow_Aggregate_Order_By = {
  avg?: InputMaybe<Flow_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Flow_Max_Order_By>;
  min?: InputMaybe<Flow_Min_Order_By>;
  stddev?: InputMaybe<Flow_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Flow_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Flow_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Flow_Sum_Order_By>;
  var_pop?: InputMaybe<Flow_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Flow_Var_Samp_Order_By>;
  variance?: InputMaybe<Flow_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Flow_Append_Input = {
  environment?: InputMaybe<Scalars["jsonb"]>;
  parameters?: InputMaybe<Scalars["jsonb"]>;
  run_config?: InputMaybe<Scalars["jsonb"]>;
  schedule?: InputMaybe<Scalars["jsonb"]>;
  serialized_flow?: InputMaybe<Scalars["jsonb"]>;
  settings?: InputMaybe<Scalars["jsonb"]>;
  storage?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "flow" */
export type Flow_Arr_Rel_Insert_Input = {
  data: Array<Flow_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Flow_On_Conflict>;
};

/** aggregate avg on columns */
export type Flow_Avg_Fields = {
  __typename?: "flow_avg_fields";
  version?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "flow" */
export type Flow_Avg_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "flow". All fields are combined with a logical 'AND'. */
export type Flow_Bool_Exp = {
  _and?: InputMaybe<Array<Flow_Bool_Exp>>;
  _not?: InputMaybe<Flow_Bool_Exp>;
  _or?: InputMaybe<Array<Flow_Bool_Exp>>;
  archived?: InputMaybe<Boolean_Comparison_Exp>;
  core_version?: InputMaybe<String_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  edges?: InputMaybe<Edge_Bool_Exp>;
  environment?: InputMaybe<Jsonb_Comparison_Exp>;
  flow_group?: InputMaybe<Flow_Group_Bool_Exp>;
  flow_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  flow_runs?: InputMaybe<Flow_Run_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_schedule_active?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  parameters?: InputMaybe<Jsonb_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  run_config?: InputMaybe<Jsonb_Comparison_Exp>;
  schedule?: InputMaybe<Jsonb_Comparison_Exp>;
  serialized_flow?: InputMaybe<Jsonb_Comparison_Exp>;
  settings?: InputMaybe<Jsonb_Comparison_Exp>;
  storage?: InputMaybe<Jsonb_Comparison_Exp>;
  tasks?: InputMaybe<Task_Bool_Exp>;
  tenant?: InputMaybe<Tenant_Bool_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Int_Comparison_Exp>;
  version_group_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "flow" */
export enum Flow_Constraint {
  /** unique or primary key constraint */
  FlowPkey = "flow_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Flow_Delete_At_Path_Input = {
  environment?: InputMaybe<Array<Scalars["String"]>>;
  parameters?: InputMaybe<Array<Scalars["String"]>>;
  run_config?: InputMaybe<Array<Scalars["String"]>>;
  schedule?: InputMaybe<Array<Scalars["String"]>>;
  serialized_flow?: InputMaybe<Array<Scalars["String"]>>;
  settings?: InputMaybe<Array<Scalars["String"]>>;
  storage?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Flow_Delete_Elem_Input = {
  environment?: InputMaybe<Scalars["Int"]>;
  parameters?: InputMaybe<Scalars["Int"]>;
  run_config?: InputMaybe<Scalars["Int"]>;
  schedule?: InputMaybe<Scalars["Int"]>;
  serialized_flow?: InputMaybe<Scalars["Int"]>;
  settings?: InputMaybe<Scalars["Int"]>;
  storage?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Flow_Delete_Key_Input = {
  environment?: InputMaybe<Scalars["String"]>;
  parameters?: InputMaybe<Scalars["String"]>;
  run_config?: InputMaybe<Scalars["String"]>;
  schedule?: InputMaybe<Scalars["String"]>;
  serialized_flow?: InputMaybe<Scalars["String"]>;
  settings?: InputMaybe<Scalars["String"]>;
  storage?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "flow_group" */
export type Flow_Group = {
  __typename?: "flow_group";
  created: Scalars["timestamptz"];
  default_parameters: Scalars["jsonb"];
  description?: Maybe<Scalars["String"]>;
  /** An array relationship */
  flows: Array<Flow>;
  /** An aggregate relationship */
  flows_aggregate: Flow_Aggregate;
  id: Scalars["uuid"];
  labels?: Maybe<Scalars["jsonb"]>;
  name?: Maybe<Scalars["String"]>;
  run_config?: Maybe<Scalars["jsonb"]>;
  schedule?: Maybe<Scalars["jsonb"]>;
  settings: Scalars["jsonb"];
  /** An object relationship */
  tenant?: Maybe<Tenant>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  updated: Scalars["timestamptz"];
};

/** columns and relationships of "flow_group" */
export type Flow_GroupDefault_ParametersArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "flow_group" */
export type Flow_GroupFlowsArgs = {
  distinct_on?: InputMaybe<Array<Flow_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Order_By>>;
  where?: InputMaybe<Flow_Bool_Exp>;
};

/** columns and relationships of "flow_group" */
export type Flow_GroupFlows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flow_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Order_By>>;
  where?: InputMaybe<Flow_Bool_Exp>;
};

/** columns and relationships of "flow_group" */
export type Flow_GroupLabelsArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "flow_group" */
export type Flow_GroupRun_ConfigArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "flow_group" */
export type Flow_GroupScheduleArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "flow_group" */
export type Flow_GroupSettingsArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "flow_group" */
export type Flow_Group_Aggregate = {
  __typename?: "flow_group_aggregate";
  aggregate?: Maybe<Flow_Group_Aggregate_Fields>;
  nodes: Array<Flow_Group>;
};

/** aggregate fields of "flow_group" */
export type Flow_Group_Aggregate_Fields = {
  __typename?: "flow_group_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Flow_Group_Max_Fields>;
  min?: Maybe<Flow_Group_Min_Fields>;
};

/** aggregate fields of "flow_group" */
export type Flow_Group_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Flow_Group_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "flow_group" */
export type Flow_Group_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Flow_Group_Max_Order_By>;
  min?: InputMaybe<Flow_Group_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Flow_Group_Append_Input = {
  default_parameters?: InputMaybe<Scalars["jsonb"]>;
  labels?: InputMaybe<Scalars["jsonb"]>;
  run_config?: InputMaybe<Scalars["jsonb"]>;
  schedule?: InputMaybe<Scalars["jsonb"]>;
  settings?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "flow_group" */
export type Flow_Group_Arr_Rel_Insert_Input = {
  data: Array<Flow_Group_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Flow_Group_On_Conflict>;
};

/** Boolean expression to filter rows from the table "flow_group". All fields are combined with a logical 'AND'. */
export type Flow_Group_Bool_Exp = {
  _and?: InputMaybe<Array<Flow_Group_Bool_Exp>>;
  _not?: InputMaybe<Flow_Group_Bool_Exp>;
  _or?: InputMaybe<Array<Flow_Group_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  default_parameters?: InputMaybe<Jsonb_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  flows?: InputMaybe<Flow_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  labels?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  run_config?: InputMaybe<Jsonb_Comparison_Exp>;
  schedule?: InputMaybe<Jsonb_Comparison_Exp>;
  settings?: InputMaybe<Jsonb_Comparison_Exp>;
  tenant?: InputMaybe<Tenant_Bool_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "flow_group" */
export enum Flow_Group_Constraint {
  /** unique or primary key constraint */
  FlowGroupPkey = "flow_group_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Flow_Group_Delete_At_Path_Input = {
  default_parameters?: InputMaybe<Array<Scalars["String"]>>;
  labels?: InputMaybe<Array<Scalars["String"]>>;
  run_config?: InputMaybe<Array<Scalars["String"]>>;
  schedule?: InputMaybe<Array<Scalars["String"]>>;
  settings?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Flow_Group_Delete_Elem_Input = {
  default_parameters?: InputMaybe<Scalars["Int"]>;
  labels?: InputMaybe<Scalars["Int"]>;
  run_config?: InputMaybe<Scalars["Int"]>;
  schedule?: InputMaybe<Scalars["Int"]>;
  settings?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Flow_Group_Delete_Key_Input = {
  default_parameters?: InputMaybe<Scalars["String"]>;
  labels?: InputMaybe<Scalars["String"]>;
  run_config?: InputMaybe<Scalars["String"]>;
  schedule?: InputMaybe<Scalars["String"]>;
  settings?: InputMaybe<Scalars["String"]>;
};

/** input type for inserting data into table "flow_group" */
export type Flow_Group_Insert_Input = {
  created?: InputMaybe<Scalars["timestamptz"]>;
  default_parameters?: InputMaybe<Scalars["jsonb"]>;
  description?: InputMaybe<Scalars["String"]>;
  flows?: InputMaybe<Flow_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["uuid"]>;
  labels?: InputMaybe<Scalars["jsonb"]>;
  name?: InputMaybe<Scalars["String"]>;
  run_config?: InputMaybe<Scalars["jsonb"]>;
  schedule?: InputMaybe<Scalars["jsonb"]>;
  settings?: InputMaybe<Scalars["jsonb"]>;
  tenant?: InputMaybe<Tenant_Obj_Rel_Insert_Input>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Flow_Group_Max_Fields = {
  __typename?: "flow_group_max_fields";
  created?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "flow_group" */
export type Flow_Group_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Flow_Group_Min_Fields = {
  __typename?: "flow_group_min_fields";
  created?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "flow_group" */
export type Flow_Group_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "flow_group" */
export type Flow_Group_Mutation_Response = {
  __typename?: "flow_group_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Flow_Group>;
};

/** input type for inserting object relation for remote table "flow_group" */
export type Flow_Group_Obj_Rel_Insert_Input = {
  data: Flow_Group_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Flow_Group_On_Conflict>;
};

/** on conflict condition type for table "flow_group" */
export type Flow_Group_On_Conflict = {
  constraint: Flow_Group_Constraint;
  update_columns?: Array<Flow_Group_Update_Column>;
  where?: InputMaybe<Flow_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "flow_group". */
export type Flow_Group_Order_By = {
  created?: InputMaybe<Order_By>;
  default_parameters?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  flows_aggregate?: InputMaybe<Flow_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  labels?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  run_config?: InputMaybe<Order_By>;
  schedule?: InputMaybe<Order_By>;
  settings?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Tenant_Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: flow_group */
export type Flow_Group_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Flow_Group_Prepend_Input = {
  default_parameters?: InputMaybe<Scalars["jsonb"]>;
  labels?: InputMaybe<Scalars["jsonb"]>;
  run_config?: InputMaybe<Scalars["jsonb"]>;
  schedule?: InputMaybe<Scalars["jsonb"]>;
  settings?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "flow_group" */
export enum Flow_Group_Select_Column {
  /** column name */
  Created = "created",
  /** column name */
  DefaultParameters = "default_parameters",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Labels = "labels",
  /** column name */
  Name = "name",
  /** column name */
  RunConfig = "run_config",
  /** column name */
  Schedule = "schedule",
  /** column name */
  Settings = "settings",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Updated = "updated",
}

/** input type for updating data in table "flow_group" */
export type Flow_Group_Set_Input = {
  created?: InputMaybe<Scalars["timestamptz"]>;
  default_parameters?: InputMaybe<Scalars["jsonb"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  labels?: InputMaybe<Scalars["jsonb"]>;
  name?: InputMaybe<Scalars["String"]>;
  run_config?: InputMaybe<Scalars["jsonb"]>;
  schedule?: InputMaybe<Scalars["jsonb"]>;
  settings?: InputMaybe<Scalars["jsonb"]>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "flow_group" */
export enum Flow_Group_Update_Column {
  /** column name */
  Created = "created",
  /** column name */
  DefaultParameters = "default_parameters",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Labels = "labels",
  /** column name */
  Name = "name",
  /** column name */
  RunConfig = "run_config",
  /** column name */
  Schedule = "schedule",
  /** column name */
  Settings = "settings",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Updated = "updated",
}

export type Flow_Id_Payload = {
  __typename?: "flow_id_payload";
  id?: Maybe<Scalars["UUID"]>;
};

/** input type for incrementing numeric columns in table "flow" */
export type Flow_Inc_Input = {
  version?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "flow" */
export type Flow_Insert_Input = {
  archived?: InputMaybe<Scalars["Boolean"]>;
  core_version?: InputMaybe<Scalars["String"]>;
  created?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  edges?: InputMaybe<Edge_Arr_Rel_Insert_Input>;
  environment?: InputMaybe<Scalars["jsonb"]>;
  flow_group?: InputMaybe<Flow_Group_Obj_Rel_Insert_Input>;
  flow_group_id?: InputMaybe<Scalars["uuid"]>;
  flow_runs?: InputMaybe<Flow_Run_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_schedule_active?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  parameters?: InputMaybe<Scalars["jsonb"]>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars["uuid"]>;
  run_config?: InputMaybe<Scalars["jsonb"]>;
  schedule?: InputMaybe<Scalars["jsonb"]>;
  serialized_flow?: InputMaybe<Scalars["jsonb"]>;
  settings?: InputMaybe<Scalars["jsonb"]>;
  storage?: InputMaybe<Scalars["jsonb"]>;
  tasks?: InputMaybe<Task_Arr_Rel_Insert_Input>;
  tenant?: InputMaybe<Tenant_Obj_Rel_Insert_Input>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
  version?: InputMaybe<Scalars["Int"]>;
  version_group_id?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Flow_Max_Fields = {
  __typename?: "flow_max_fields";
  core_version?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  flow_group_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  project_id?: Maybe<Scalars["uuid"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
  version?: Maybe<Scalars["Int"]>;
  version_group_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "flow" */
export type Flow_Max_Order_By = {
  core_version?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  flow_group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  version_group_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Flow_Min_Fields = {
  __typename?: "flow_min_fields";
  core_version?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  flow_group_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  project_id?: Maybe<Scalars["uuid"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
  version?: Maybe<Scalars["Int"]>;
  version_group_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "flow" */
export type Flow_Min_Order_By = {
  core_version?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  flow_group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  version_group_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "flow" */
export type Flow_Mutation_Response = {
  __typename?: "flow_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Flow>;
};

/** input type for inserting object relation for remote table "flow" */
export type Flow_Obj_Rel_Insert_Input = {
  data: Flow_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Flow_On_Conflict>;
};

/** on conflict condition type for table "flow" */
export type Flow_On_Conflict = {
  constraint: Flow_Constraint;
  update_columns?: Array<Flow_Update_Column>;
  where?: InputMaybe<Flow_Bool_Exp>;
};

/** Ordering options when selecting data from "flow". */
export type Flow_Order_By = {
  archived?: InputMaybe<Order_By>;
  core_version?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  edges_aggregate?: InputMaybe<Edge_Aggregate_Order_By>;
  environment?: InputMaybe<Order_By>;
  flow_group?: InputMaybe<Flow_Group_Order_By>;
  flow_group_id?: InputMaybe<Order_By>;
  flow_runs_aggregate?: InputMaybe<Flow_Run_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  is_schedule_active?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parameters?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  run_config?: InputMaybe<Order_By>;
  schedule?: InputMaybe<Order_By>;
  serialized_flow?: InputMaybe<Order_By>;
  settings?: InputMaybe<Order_By>;
  storage?: InputMaybe<Order_By>;
  tasks_aggregate?: InputMaybe<Task_Aggregate_Order_By>;
  tenant?: InputMaybe<Tenant_Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  version_group_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: flow */
export type Flow_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Flow_Prepend_Input = {
  environment?: InputMaybe<Scalars["jsonb"]>;
  parameters?: InputMaybe<Scalars["jsonb"]>;
  run_config?: InputMaybe<Scalars["jsonb"]>;
  schedule?: InputMaybe<Scalars["jsonb"]>;
  serialized_flow?: InputMaybe<Scalars["jsonb"]>;
  settings?: InputMaybe<Scalars["jsonb"]>;
  storage?: InputMaybe<Scalars["jsonb"]>;
};

/** columns and relationships of "flow_run" */
export type Flow_Run = {
  __typename?: "flow_run";
  /** An object relationship */
  agent?: Maybe<Agent>;
  agent_id?: Maybe<Scalars["uuid"]>;
  auto_scheduled: Scalars["Boolean"];
  context?: Maybe<Scalars["jsonb"]>;
  created: Scalars["timestamptz"];
  end_time?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  flow: Flow;
  flow_id: Scalars["uuid"];
  heartbeat?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  idempotency_key?: Maybe<Scalars["String"]>;
  labels: Scalars["jsonb"];
  /** An array relationship */
  logs: Array<Log>;
  /** An aggregate relationship */
  logs_aggregate: Log_Aggregate;
  name?: Maybe<Scalars["String"]>;
  parameters?: Maybe<Scalars["jsonb"]>;
  run_config?: Maybe<Scalars["jsonb"]>;
  scheduled_start_time: Scalars["timestamptz"];
  serialized_state?: Maybe<Scalars["jsonb"]>;
  start_time?: Maybe<Scalars["timestamptz"]>;
  state?: Maybe<Scalars["String"]>;
  state_message?: Maybe<Scalars["String"]>;
  state_result?: Maybe<Scalars["jsonb"]>;
  state_start_time?: Maybe<Scalars["timestamptz"]>;
  state_timestamp?: Maybe<Scalars["timestamptz"]>;
  /** An array relationship */
  states: Array<Flow_Run_State>;
  /** An aggregate relationship */
  states_aggregate: Flow_Run_State_Aggregate;
  /** An array relationship */
  task_runs: Array<Task_Run>;
  /** An aggregate relationship */
  task_runs_aggregate: Task_Run_Aggregate;
  /** An object relationship */
  tenant?: Maybe<Tenant>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  times_resurrected?: Maybe<Scalars["Int"]>;
  updated: Scalars["timestamptz"];
  version: Scalars["Int"];
};

/** columns and relationships of "flow_run" */
export type Flow_RunContextArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "flow_run" */
export type Flow_RunLabelsArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "flow_run" */
export type Flow_RunLogsArgs = {
  distinct_on?: InputMaybe<Array<Log_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Log_Order_By>>;
  where?: InputMaybe<Log_Bool_Exp>;
};

/** columns and relationships of "flow_run" */
export type Flow_RunLogs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Log_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Log_Order_By>>;
  where?: InputMaybe<Log_Bool_Exp>;
};

/** columns and relationships of "flow_run" */
export type Flow_RunParametersArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "flow_run" */
export type Flow_RunRun_ConfigArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "flow_run" */
export type Flow_RunSerialized_StateArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "flow_run" */
export type Flow_RunState_ResultArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "flow_run" */
export type Flow_RunStatesArgs = {
  distinct_on?: InputMaybe<Array<Flow_Run_State_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Run_State_Order_By>>;
  where?: InputMaybe<Flow_Run_State_Bool_Exp>;
};

/** columns and relationships of "flow_run" */
export type Flow_RunStates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flow_Run_State_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Run_State_Order_By>>;
  where?: InputMaybe<Flow_Run_State_Bool_Exp>;
};

/** columns and relationships of "flow_run" */
export type Flow_RunTask_RunsArgs = {
  distinct_on?: InputMaybe<Array<Task_Run_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Run_Order_By>>;
  where?: InputMaybe<Task_Run_Bool_Exp>;
};

/** columns and relationships of "flow_run" */
export type Flow_RunTask_Runs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Run_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Run_Order_By>>;
  where?: InputMaybe<Task_Run_Bool_Exp>;
};

/** aggregated selection of "flow_run" */
export type Flow_Run_Aggregate = {
  __typename?: "flow_run_aggregate";
  aggregate?: Maybe<Flow_Run_Aggregate_Fields>;
  nodes: Array<Flow_Run>;
};

/** aggregate fields of "flow_run" */
export type Flow_Run_Aggregate_Fields = {
  __typename?: "flow_run_aggregate_fields";
  avg?: Maybe<Flow_Run_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Flow_Run_Max_Fields>;
  min?: Maybe<Flow_Run_Min_Fields>;
  stddev?: Maybe<Flow_Run_Stddev_Fields>;
  stddev_pop?: Maybe<Flow_Run_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Flow_Run_Stddev_Samp_Fields>;
  sum?: Maybe<Flow_Run_Sum_Fields>;
  var_pop?: Maybe<Flow_Run_Var_Pop_Fields>;
  var_samp?: Maybe<Flow_Run_Var_Samp_Fields>;
  variance?: Maybe<Flow_Run_Variance_Fields>;
};

/** aggregate fields of "flow_run" */
export type Flow_Run_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Flow_Run_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "flow_run" */
export type Flow_Run_Aggregate_Order_By = {
  avg?: InputMaybe<Flow_Run_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Flow_Run_Max_Order_By>;
  min?: InputMaybe<Flow_Run_Min_Order_By>;
  stddev?: InputMaybe<Flow_Run_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Flow_Run_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Flow_Run_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Flow_Run_Sum_Order_By>;
  var_pop?: InputMaybe<Flow_Run_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Flow_Run_Var_Samp_Order_By>;
  variance?: InputMaybe<Flow_Run_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Flow_Run_Append_Input = {
  context?: InputMaybe<Scalars["jsonb"]>;
  labels?: InputMaybe<Scalars["jsonb"]>;
  parameters?: InputMaybe<Scalars["jsonb"]>;
  run_config?: InputMaybe<Scalars["jsonb"]>;
  serialized_state?: InputMaybe<Scalars["jsonb"]>;
  state_result?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "flow_run" */
export type Flow_Run_Arr_Rel_Insert_Input = {
  data: Array<Flow_Run_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Flow_Run_On_Conflict>;
};

/** aggregate avg on columns */
export type Flow_Run_Avg_Fields = {
  __typename?: "flow_run_avg_fields";
  times_resurrected?: Maybe<Scalars["Float"]>;
  version?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "flow_run" */
export type Flow_Run_Avg_Order_By = {
  times_resurrected?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "flow_run". All fields are combined with a logical 'AND'. */
export type Flow_Run_Bool_Exp = {
  _and?: InputMaybe<Array<Flow_Run_Bool_Exp>>;
  _not?: InputMaybe<Flow_Run_Bool_Exp>;
  _or?: InputMaybe<Array<Flow_Run_Bool_Exp>>;
  agent?: InputMaybe<Agent_Bool_Exp>;
  agent_id?: InputMaybe<Uuid_Comparison_Exp>;
  auto_scheduled?: InputMaybe<Boolean_Comparison_Exp>;
  context?: InputMaybe<Jsonb_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  end_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  flow?: InputMaybe<Flow_Bool_Exp>;
  flow_id?: InputMaybe<Uuid_Comparison_Exp>;
  heartbeat?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  idempotency_key?: InputMaybe<String_Comparison_Exp>;
  labels?: InputMaybe<Jsonb_Comparison_Exp>;
  logs?: InputMaybe<Log_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  parameters?: InputMaybe<Jsonb_Comparison_Exp>;
  run_config?: InputMaybe<Jsonb_Comparison_Exp>;
  scheduled_start_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  serialized_state?: InputMaybe<Jsonb_Comparison_Exp>;
  start_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  state_message?: InputMaybe<String_Comparison_Exp>;
  state_result?: InputMaybe<Jsonb_Comparison_Exp>;
  state_start_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  state_timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  states?: InputMaybe<Flow_Run_State_Bool_Exp>;
  task_runs?: InputMaybe<Task_Run_Bool_Exp>;
  tenant?: InputMaybe<Tenant_Bool_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  times_resurrected?: InputMaybe<Int_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "flow_run" */
export enum Flow_Run_Constraint {
  /** unique or primary key constraint */
  FlowRunPkey = "flow_run_pkey",
  /** unique or primary key constraint */
  IxFlowRunIdempotencyKeyUnique = "ix_flow_run_idempotency_key_unique",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Flow_Run_Delete_At_Path_Input = {
  context?: InputMaybe<Array<Scalars["String"]>>;
  labels?: InputMaybe<Array<Scalars["String"]>>;
  parameters?: InputMaybe<Array<Scalars["String"]>>;
  run_config?: InputMaybe<Array<Scalars["String"]>>;
  serialized_state?: InputMaybe<Array<Scalars["String"]>>;
  state_result?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Flow_Run_Delete_Elem_Input = {
  context?: InputMaybe<Scalars["Int"]>;
  labels?: InputMaybe<Scalars["Int"]>;
  parameters?: InputMaybe<Scalars["Int"]>;
  run_config?: InputMaybe<Scalars["Int"]>;
  serialized_state?: InputMaybe<Scalars["Int"]>;
  state_result?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Flow_Run_Delete_Key_Input = {
  context?: InputMaybe<Scalars["String"]>;
  labels?: InputMaybe<Scalars["String"]>;
  parameters?: InputMaybe<Scalars["String"]>;
  run_config?: InputMaybe<Scalars["String"]>;
  serialized_state?: InputMaybe<Scalars["String"]>;
  state_result?: InputMaybe<Scalars["String"]>;
};

export type Flow_Run_Id_Payload = {
  __typename?: "flow_run_id_payload";
  id?: Maybe<Scalars["UUID"]>;
};

/** input type for incrementing numeric columns in table "flow_run" */
export type Flow_Run_Inc_Input = {
  times_resurrected?: InputMaybe<Scalars["Int"]>;
  version?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "flow_run" */
export type Flow_Run_Insert_Input = {
  agent?: InputMaybe<Agent_Obj_Rel_Insert_Input>;
  agent_id?: InputMaybe<Scalars["uuid"]>;
  auto_scheduled?: InputMaybe<Scalars["Boolean"]>;
  context?: InputMaybe<Scalars["jsonb"]>;
  created?: InputMaybe<Scalars["timestamptz"]>;
  end_time?: InputMaybe<Scalars["timestamptz"]>;
  flow?: InputMaybe<Flow_Obj_Rel_Insert_Input>;
  flow_id?: InputMaybe<Scalars["uuid"]>;
  heartbeat?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  idempotency_key?: InputMaybe<Scalars["String"]>;
  labels?: InputMaybe<Scalars["jsonb"]>;
  logs?: InputMaybe<Log_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars["String"]>;
  parameters?: InputMaybe<Scalars["jsonb"]>;
  run_config?: InputMaybe<Scalars["jsonb"]>;
  scheduled_start_time?: InputMaybe<Scalars["timestamptz"]>;
  serialized_state?: InputMaybe<Scalars["jsonb"]>;
  start_time?: InputMaybe<Scalars["timestamptz"]>;
  state?: InputMaybe<Scalars["String"]>;
  state_message?: InputMaybe<Scalars["String"]>;
  state_result?: InputMaybe<Scalars["jsonb"]>;
  state_start_time?: InputMaybe<Scalars["timestamptz"]>;
  state_timestamp?: InputMaybe<Scalars["timestamptz"]>;
  states?: InputMaybe<Flow_Run_State_Arr_Rel_Insert_Input>;
  task_runs?: InputMaybe<Task_Run_Arr_Rel_Insert_Input>;
  tenant?: InputMaybe<Tenant_Obj_Rel_Insert_Input>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  times_resurrected?: InputMaybe<Scalars["Int"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
  version?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Flow_Run_Max_Fields = {
  __typename?: "flow_run_max_fields";
  agent_id?: Maybe<Scalars["uuid"]>;
  created?: Maybe<Scalars["timestamptz"]>;
  end_time?: Maybe<Scalars["timestamptz"]>;
  flow_id?: Maybe<Scalars["uuid"]>;
  heartbeat?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  idempotency_key?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  scheduled_start_time?: Maybe<Scalars["timestamptz"]>;
  start_time?: Maybe<Scalars["timestamptz"]>;
  state?: Maybe<Scalars["String"]>;
  state_message?: Maybe<Scalars["String"]>;
  state_start_time?: Maybe<Scalars["timestamptz"]>;
  state_timestamp?: Maybe<Scalars["timestamptz"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  times_resurrected?: Maybe<Scalars["Int"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
  version?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "flow_run" */
export type Flow_Run_Max_Order_By = {
  agent_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  flow_id?: InputMaybe<Order_By>;
  heartbeat?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  idempotency_key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  scheduled_start_time?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  state_message?: InputMaybe<Order_By>;
  state_start_time?: InputMaybe<Order_By>;
  state_timestamp?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  times_resurrected?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Flow_Run_Min_Fields = {
  __typename?: "flow_run_min_fields";
  agent_id?: Maybe<Scalars["uuid"]>;
  created?: Maybe<Scalars["timestamptz"]>;
  end_time?: Maybe<Scalars["timestamptz"]>;
  flow_id?: Maybe<Scalars["uuid"]>;
  heartbeat?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  idempotency_key?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  scheduled_start_time?: Maybe<Scalars["timestamptz"]>;
  start_time?: Maybe<Scalars["timestamptz"]>;
  state?: Maybe<Scalars["String"]>;
  state_message?: Maybe<Scalars["String"]>;
  state_start_time?: Maybe<Scalars["timestamptz"]>;
  state_timestamp?: Maybe<Scalars["timestamptz"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  times_resurrected?: Maybe<Scalars["Int"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
  version?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "flow_run" */
export type Flow_Run_Min_Order_By = {
  agent_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  flow_id?: InputMaybe<Order_By>;
  heartbeat?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  idempotency_key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  scheduled_start_time?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  state_message?: InputMaybe<Order_By>;
  state_start_time?: InputMaybe<Order_By>;
  state_timestamp?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  times_resurrected?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "flow_run" */
export type Flow_Run_Mutation_Response = {
  __typename?: "flow_run_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Flow_Run>;
};

/** input type for inserting object relation for remote table "flow_run" */
export type Flow_Run_Obj_Rel_Insert_Input = {
  data: Flow_Run_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Flow_Run_On_Conflict>;
};

/** on conflict condition type for table "flow_run" */
export type Flow_Run_On_Conflict = {
  constraint: Flow_Run_Constraint;
  update_columns?: Array<Flow_Run_Update_Column>;
  where?: InputMaybe<Flow_Run_Bool_Exp>;
};

/** Ordering options when selecting data from "flow_run". */
export type Flow_Run_Order_By = {
  agent?: InputMaybe<Agent_Order_By>;
  agent_id?: InputMaybe<Order_By>;
  auto_scheduled?: InputMaybe<Order_By>;
  context?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  flow?: InputMaybe<Flow_Order_By>;
  flow_id?: InputMaybe<Order_By>;
  heartbeat?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  idempotency_key?: InputMaybe<Order_By>;
  labels?: InputMaybe<Order_By>;
  logs_aggregate?: InputMaybe<Log_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  parameters?: InputMaybe<Order_By>;
  run_config?: InputMaybe<Order_By>;
  scheduled_start_time?: InputMaybe<Order_By>;
  serialized_state?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  state_message?: InputMaybe<Order_By>;
  state_result?: InputMaybe<Order_By>;
  state_start_time?: InputMaybe<Order_By>;
  state_timestamp?: InputMaybe<Order_By>;
  states_aggregate?: InputMaybe<Flow_Run_State_Aggregate_Order_By>;
  task_runs_aggregate?: InputMaybe<Task_Run_Aggregate_Order_By>;
  tenant?: InputMaybe<Tenant_Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  times_resurrected?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: flow_run */
export type Flow_Run_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Flow_Run_Prepend_Input = {
  context?: InputMaybe<Scalars["jsonb"]>;
  labels?: InputMaybe<Scalars["jsonb"]>;
  parameters?: InputMaybe<Scalars["jsonb"]>;
  run_config?: InputMaybe<Scalars["jsonb"]>;
  serialized_state?: InputMaybe<Scalars["jsonb"]>;
  state_result?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "flow_run" */
export enum Flow_Run_Select_Column {
  /** column name */
  AgentId = "agent_id",
  /** column name */
  AutoScheduled = "auto_scheduled",
  /** column name */
  Context = "context",
  /** column name */
  Created = "created",
  /** column name */
  EndTime = "end_time",
  /** column name */
  FlowId = "flow_id",
  /** column name */
  Heartbeat = "heartbeat",
  /** column name */
  Id = "id",
  /** column name */
  IdempotencyKey = "idempotency_key",
  /** column name */
  Labels = "labels",
  /** column name */
  Name = "name",
  /** column name */
  Parameters = "parameters",
  /** column name */
  RunConfig = "run_config",
  /** column name */
  ScheduledStartTime = "scheduled_start_time",
  /** column name */
  SerializedState = "serialized_state",
  /** column name */
  StartTime = "start_time",
  /** column name */
  State = "state",
  /** column name */
  StateMessage = "state_message",
  /** column name */
  StateResult = "state_result",
  /** column name */
  StateStartTime = "state_start_time",
  /** column name */
  StateTimestamp = "state_timestamp",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  TimesResurrected = "times_resurrected",
  /** column name */
  Updated = "updated",
  /** column name */
  Version = "version",
}

/** input type for updating data in table "flow_run" */
export type Flow_Run_Set_Input = {
  agent_id?: InputMaybe<Scalars["uuid"]>;
  auto_scheduled?: InputMaybe<Scalars["Boolean"]>;
  context?: InputMaybe<Scalars["jsonb"]>;
  created?: InputMaybe<Scalars["timestamptz"]>;
  end_time?: InputMaybe<Scalars["timestamptz"]>;
  flow_id?: InputMaybe<Scalars["uuid"]>;
  heartbeat?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  idempotency_key?: InputMaybe<Scalars["String"]>;
  labels?: InputMaybe<Scalars["jsonb"]>;
  name?: InputMaybe<Scalars["String"]>;
  parameters?: InputMaybe<Scalars["jsonb"]>;
  run_config?: InputMaybe<Scalars["jsonb"]>;
  scheduled_start_time?: InputMaybe<Scalars["timestamptz"]>;
  serialized_state?: InputMaybe<Scalars["jsonb"]>;
  start_time?: InputMaybe<Scalars["timestamptz"]>;
  state?: InputMaybe<Scalars["String"]>;
  state_message?: InputMaybe<Scalars["String"]>;
  state_result?: InputMaybe<Scalars["jsonb"]>;
  state_start_time?: InputMaybe<Scalars["timestamptz"]>;
  state_timestamp?: InputMaybe<Scalars["timestamptz"]>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  times_resurrected?: InputMaybe<Scalars["Int"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
  version?: InputMaybe<Scalars["Int"]>;
};

/** columns and relationships of "flow_run_state" */
export type Flow_Run_State = {
  __typename?: "flow_run_state";
  created: Scalars["timestamptz"];
  /** An object relationship */
  flow_run: Flow_Run;
  flow_run_id: Scalars["uuid"];
  id: Scalars["uuid"];
  message?: Maybe<Scalars["String"]>;
  result?: Maybe<Scalars["jsonb"]>;
  serialized_state: Scalars["jsonb"];
  start_time?: Maybe<Scalars["timestamptz"]>;
  state: Scalars["String"];
  tenant_id?: Maybe<Scalars["uuid"]>;
  timestamp: Scalars["timestamptz"];
  updated: Scalars["timestamptz"];
  version?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "flow_run_state" */
export type Flow_Run_StateResultArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "flow_run_state" */
export type Flow_Run_StateSerialized_StateArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "flow_run_state" */
export type Flow_Run_State_Aggregate = {
  __typename?: "flow_run_state_aggregate";
  aggregate?: Maybe<Flow_Run_State_Aggregate_Fields>;
  nodes: Array<Flow_Run_State>;
};

/** aggregate fields of "flow_run_state" */
export type Flow_Run_State_Aggregate_Fields = {
  __typename?: "flow_run_state_aggregate_fields";
  avg?: Maybe<Flow_Run_State_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Flow_Run_State_Max_Fields>;
  min?: Maybe<Flow_Run_State_Min_Fields>;
  stddev?: Maybe<Flow_Run_State_Stddev_Fields>;
  stddev_pop?: Maybe<Flow_Run_State_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Flow_Run_State_Stddev_Samp_Fields>;
  sum?: Maybe<Flow_Run_State_Sum_Fields>;
  var_pop?: Maybe<Flow_Run_State_Var_Pop_Fields>;
  var_samp?: Maybe<Flow_Run_State_Var_Samp_Fields>;
  variance?: Maybe<Flow_Run_State_Variance_Fields>;
};

/** aggregate fields of "flow_run_state" */
export type Flow_Run_State_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Flow_Run_State_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "flow_run_state" */
export type Flow_Run_State_Aggregate_Order_By = {
  avg?: InputMaybe<Flow_Run_State_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Flow_Run_State_Max_Order_By>;
  min?: InputMaybe<Flow_Run_State_Min_Order_By>;
  stddev?: InputMaybe<Flow_Run_State_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Flow_Run_State_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Flow_Run_State_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Flow_Run_State_Sum_Order_By>;
  var_pop?: InputMaybe<Flow_Run_State_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Flow_Run_State_Var_Samp_Order_By>;
  variance?: InputMaybe<Flow_Run_State_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Flow_Run_State_Append_Input = {
  result?: InputMaybe<Scalars["jsonb"]>;
  serialized_state?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "flow_run_state" */
export type Flow_Run_State_Arr_Rel_Insert_Input = {
  data: Array<Flow_Run_State_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Flow_Run_State_On_Conflict>;
};

/** aggregate avg on columns */
export type Flow_Run_State_Avg_Fields = {
  __typename?: "flow_run_state_avg_fields";
  version?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "flow_run_state" */
export type Flow_Run_State_Avg_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "flow_run_state". All fields are combined with a logical 'AND'. */
export type Flow_Run_State_Bool_Exp = {
  _and?: InputMaybe<Array<Flow_Run_State_Bool_Exp>>;
  _not?: InputMaybe<Flow_Run_State_Bool_Exp>;
  _or?: InputMaybe<Array<Flow_Run_State_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  flow_run?: InputMaybe<Flow_Run_Bool_Exp>;
  flow_run_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  result?: InputMaybe<Jsonb_Comparison_Exp>;
  serialized_state?: InputMaybe<Jsonb_Comparison_Exp>;
  start_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "flow_run_state" */
export enum Flow_Run_State_Constraint {
  /** unique or primary key constraint */
  FlowRunStatePkey = "flow_run_state_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Flow_Run_State_Delete_At_Path_Input = {
  result?: InputMaybe<Array<Scalars["String"]>>;
  serialized_state?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Flow_Run_State_Delete_Elem_Input = {
  result?: InputMaybe<Scalars["Int"]>;
  serialized_state?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Flow_Run_State_Delete_Key_Input = {
  result?: InputMaybe<Scalars["String"]>;
  serialized_state?: InputMaybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "flow_run_state" */
export type Flow_Run_State_Inc_Input = {
  version?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "flow_run_state" */
export type Flow_Run_State_Insert_Input = {
  created?: InputMaybe<Scalars["timestamptz"]>;
  flow_run?: InputMaybe<Flow_Run_Obj_Rel_Insert_Input>;
  flow_run_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  message?: InputMaybe<Scalars["String"]>;
  result?: InputMaybe<Scalars["jsonb"]>;
  serialized_state?: InputMaybe<Scalars["jsonb"]>;
  start_time?: InputMaybe<Scalars["timestamptz"]>;
  state?: InputMaybe<Scalars["String"]>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  timestamp?: InputMaybe<Scalars["timestamptz"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
  version?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Flow_Run_State_Max_Fields = {
  __typename?: "flow_run_state_max_fields";
  created?: Maybe<Scalars["timestamptz"]>;
  flow_run_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  message?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["timestamptz"]>;
  state?: Maybe<Scalars["String"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  timestamp?: Maybe<Scalars["timestamptz"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
  version?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "flow_run_state" */
export type Flow_Run_State_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  flow_run_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Flow_Run_State_Min_Fields = {
  __typename?: "flow_run_state_min_fields";
  created?: Maybe<Scalars["timestamptz"]>;
  flow_run_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  message?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["timestamptz"]>;
  state?: Maybe<Scalars["String"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  timestamp?: Maybe<Scalars["timestamptz"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
  version?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "flow_run_state" */
export type Flow_Run_State_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  flow_run_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "flow_run_state" */
export type Flow_Run_State_Mutation_Response = {
  __typename?: "flow_run_state_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Flow_Run_State>;
};

/** on conflict condition type for table "flow_run_state" */
export type Flow_Run_State_On_Conflict = {
  constraint: Flow_Run_State_Constraint;
  update_columns?: Array<Flow_Run_State_Update_Column>;
  where?: InputMaybe<Flow_Run_State_Bool_Exp>;
};

/** Ordering options when selecting data from "flow_run_state". */
export type Flow_Run_State_Order_By = {
  created?: InputMaybe<Order_By>;
  flow_run?: InputMaybe<Flow_Run_Order_By>;
  flow_run_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  serialized_state?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: flow_run_state */
export type Flow_Run_State_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Flow_Run_State_Prepend_Input = {
  result?: InputMaybe<Scalars["jsonb"]>;
  serialized_state?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "flow_run_state" */
export enum Flow_Run_State_Select_Column {
  /** column name */
  Created = "created",
  /** column name */
  FlowRunId = "flow_run_id",
  /** column name */
  Id = "id",
  /** column name */
  Message = "message",
  /** column name */
  Result = "result",
  /** column name */
  SerializedState = "serialized_state",
  /** column name */
  StartTime = "start_time",
  /** column name */
  State = "state",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Timestamp = "timestamp",
  /** column name */
  Updated = "updated",
  /** column name */
  Version = "version",
}

/** input type for updating data in table "flow_run_state" */
export type Flow_Run_State_Set_Input = {
  created?: InputMaybe<Scalars["timestamptz"]>;
  flow_run_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  message?: InputMaybe<Scalars["String"]>;
  result?: InputMaybe<Scalars["jsonb"]>;
  serialized_state?: InputMaybe<Scalars["jsonb"]>;
  start_time?: InputMaybe<Scalars["timestamptz"]>;
  state?: InputMaybe<Scalars["String"]>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  timestamp?: InputMaybe<Scalars["timestamptz"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
  version?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Flow_Run_State_Stddev_Fields = {
  __typename?: "flow_run_state_stddev_fields";
  version?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "flow_run_state" */
export type Flow_Run_State_Stddev_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Flow_Run_State_Stddev_Pop_Fields = {
  __typename?: "flow_run_state_stddev_pop_fields";
  version?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "flow_run_state" */
export type Flow_Run_State_Stddev_Pop_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Flow_Run_State_Stddev_Samp_Fields = {
  __typename?: "flow_run_state_stddev_samp_fields";
  version?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "flow_run_state" */
export type Flow_Run_State_Stddev_Samp_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Flow_Run_State_Sum_Fields = {
  __typename?: "flow_run_state_sum_fields";
  version?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "flow_run_state" */
export type Flow_Run_State_Sum_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** update columns of table "flow_run_state" */
export enum Flow_Run_State_Update_Column {
  /** column name */
  Created = "created",
  /** column name */
  FlowRunId = "flow_run_id",
  /** column name */
  Id = "id",
  /** column name */
  Message = "message",
  /** column name */
  Result = "result",
  /** column name */
  SerializedState = "serialized_state",
  /** column name */
  StartTime = "start_time",
  /** column name */
  State = "state",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Timestamp = "timestamp",
  /** column name */
  Updated = "updated",
  /** column name */
  Version = "version",
}

/** aggregate var_pop on columns */
export type Flow_Run_State_Var_Pop_Fields = {
  __typename?: "flow_run_state_var_pop_fields";
  version?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "flow_run_state" */
export type Flow_Run_State_Var_Pop_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Flow_Run_State_Var_Samp_Fields = {
  __typename?: "flow_run_state_var_samp_fields";
  version?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "flow_run_state" */
export type Flow_Run_State_Var_Samp_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Flow_Run_State_Variance_Fields = {
  __typename?: "flow_run_state_variance_fields";
  version?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "flow_run_state" */
export type Flow_Run_State_Variance_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev on columns */
export type Flow_Run_Stddev_Fields = {
  __typename?: "flow_run_stddev_fields";
  times_resurrected?: Maybe<Scalars["Float"]>;
  version?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "flow_run" */
export type Flow_Run_Stddev_Order_By = {
  times_resurrected?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Flow_Run_Stddev_Pop_Fields = {
  __typename?: "flow_run_stddev_pop_fields";
  times_resurrected?: Maybe<Scalars["Float"]>;
  version?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "flow_run" */
export type Flow_Run_Stddev_Pop_Order_By = {
  times_resurrected?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Flow_Run_Stddev_Samp_Fields = {
  __typename?: "flow_run_stddev_samp_fields";
  times_resurrected?: Maybe<Scalars["Float"]>;
  version?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "flow_run" */
export type Flow_Run_Stddev_Samp_Order_By = {
  times_resurrected?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Flow_Run_Sum_Fields = {
  __typename?: "flow_run_sum_fields";
  times_resurrected?: Maybe<Scalars["Int"]>;
  version?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "flow_run" */
export type Flow_Run_Sum_Order_By = {
  times_resurrected?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "flow_run" */
export enum Flow_Run_Update_Column {
  /** column name */
  AgentId = "agent_id",
  /** column name */
  AutoScheduled = "auto_scheduled",
  /** column name */
  Context = "context",
  /** column name */
  Created = "created",
  /** column name */
  EndTime = "end_time",
  /** column name */
  FlowId = "flow_id",
  /** column name */
  Heartbeat = "heartbeat",
  /** column name */
  Id = "id",
  /** column name */
  IdempotencyKey = "idempotency_key",
  /** column name */
  Labels = "labels",
  /** column name */
  Name = "name",
  /** column name */
  Parameters = "parameters",
  /** column name */
  RunConfig = "run_config",
  /** column name */
  ScheduledStartTime = "scheduled_start_time",
  /** column name */
  SerializedState = "serialized_state",
  /** column name */
  StartTime = "start_time",
  /** column name */
  State = "state",
  /** column name */
  StateMessage = "state_message",
  /** column name */
  StateResult = "state_result",
  /** column name */
  StateStartTime = "state_start_time",
  /** column name */
  StateTimestamp = "state_timestamp",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  TimesResurrected = "times_resurrected",
  /** column name */
  Updated = "updated",
  /** column name */
  Version = "version",
}

/** aggregate var_pop on columns */
export type Flow_Run_Var_Pop_Fields = {
  __typename?: "flow_run_var_pop_fields";
  times_resurrected?: Maybe<Scalars["Float"]>;
  version?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "flow_run" */
export type Flow_Run_Var_Pop_Order_By = {
  times_resurrected?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Flow_Run_Var_Samp_Fields = {
  __typename?: "flow_run_var_samp_fields";
  times_resurrected?: Maybe<Scalars["Float"]>;
  version?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "flow_run" */
export type Flow_Run_Var_Samp_Order_By = {
  times_resurrected?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Flow_Run_Variance_Fields = {
  __typename?: "flow_run_variance_fields";
  times_resurrected?: Maybe<Scalars["Float"]>;
  version?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "flow_run" */
export type Flow_Run_Variance_Order_By = {
  times_resurrected?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** select columns of table "flow" */
export enum Flow_Select_Column {
  /** column name */
  Archived = "archived",
  /** column name */
  CoreVersion = "core_version",
  /** column name */
  Created = "created",
  /** column name */
  Description = "description",
  /** column name */
  Environment = "environment",
  /** column name */
  FlowGroupId = "flow_group_id",
  /** column name */
  Id = "id",
  /** column name */
  IsScheduleActive = "is_schedule_active",
  /** column name */
  Name = "name",
  /** column name */
  Parameters = "parameters",
  /** column name */
  ProjectId = "project_id",
  /** column name */
  RunConfig = "run_config",
  /** column name */
  Schedule = "schedule",
  /** column name */
  SerializedFlow = "serialized_flow",
  /** column name */
  Settings = "settings",
  /** column name */
  Storage = "storage",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Updated = "updated",
  /** column name */
  Version = "version",
  /** column name */
  VersionGroupId = "version_group_id",
}

/** input type for updating data in table "flow" */
export type Flow_Set_Input = {
  archived?: InputMaybe<Scalars["Boolean"]>;
  core_version?: InputMaybe<Scalars["String"]>;
  created?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  environment?: InputMaybe<Scalars["jsonb"]>;
  flow_group_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_schedule_active?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  parameters?: InputMaybe<Scalars["jsonb"]>;
  project_id?: InputMaybe<Scalars["uuid"]>;
  run_config?: InputMaybe<Scalars["jsonb"]>;
  schedule?: InputMaybe<Scalars["jsonb"]>;
  serialized_flow?: InputMaybe<Scalars["jsonb"]>;
  settings?: InputMaybe<Scalars["jsonb"]>;
  storage?: InputMaybe<Scalars["jsonb"]>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
  version?: InputMaybe<Scalars["Int"]>;
  version_group_id?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Flow_Stddev_Fields = {
  __typename?: "flow_stddev_fields";
  version?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "flow" */
export type Flow_Stddev_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Flow_Stddev_Pop_Fields = {
  __typename?: "flow_stddev_pop_fields";
  version?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "flow" */
export type Flow_Stddev_Pop_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Flow_Stddev_Samp_Fields = {
  __typename?: "flow_stddev_samp_fields";
  version?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "flow" */
export type Flow_Stddev_Samp_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Flow_Sum_Fields = {
  __typename?: "flow_sum_fields";
  version?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "flow" */
export type Flow_Sum_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** update columns of table "flow" */
export enum Flow_Update_Column {
  /** column name */
  Archived = "archived",
  /** column name */
  CoreVersion = "core_version",
  /** column name */
  Created = "created",
  /** column name */
  Description = "description",
  /** column name */
  Environment = "environment",
  /** column name */
  FlowGroupId = "flow_group_id",
  /** column name */
  Id = "id",
  /** column name */
  IsScheduleActive = "is_schedule_active",
  /** column name */
  Name = "name",
  /** column name */
  Parameters = "parameters",
  /** column name */
  ProjectId = "project_id",
  /** column name */
  RunConfig = "run_config",
  /** column name */
  Schedule = "schedule",
  /** column name */
  SerializedFlow = "serialized_flow",
  /** column name */
  Settings = "settings",
  /** column name */
  Storage = "storage",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Updated = "updated",
  /** column name */
  Version = "version",
  /** column name */
  VersionGroupId = "version_group_id",
}

/** aggregate var_pop on columns */
export type Flow_Var_Pop_Fields = {
  __typename?: "flow_var_pop_fields";
  version?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "flow" */
export type Flow_Var_Pop_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Flow_Var_Samp_Fields = {
  __typename?: "flow_var_samp_fields";
  version?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "flow" */
export type Flow_Var_Samp_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Flow_Variance_Fields = {
  __typename?: "flow_variance_fields";
  version?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "flow" */
export type Flow_Variance_Order_By = {
  version?: InputMaybe<Order_By>;
};

export type Get_Or_Create_Mapped_Task_Run_Children_Input = {
  flow_run_id: Scalars["UUID"];
  max_map_index: Scalars["Int"];
  task_id: Scalars["UUID"];
};

export type Get_Or_Create_Task_Run_Info_Input = {
  flow_run_id: Scalars["UUID"];
  map_index?: InputMaybe<Scalars["Int"]>;
  task_id: Scalars["UUID"];
};

export type Get_Or_Create_Task_Run_Info_Payload = {
  __typename?: "get_or_create_task_run_info_payload";
  id?: Maybe<Scalars["UUID"]>;
  serialized_state?: Maybe<Scalars["JSON"]>;
  state?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["Int"]>;
};

export type Get_Or_Create_Task_Run_Input = {
  flow_run_id: Scalars["UUID"];
  map_index?: InputMaybe<Scalars["Int"]>;
  task_id: Scalars["UUID"];
};

export type Get_Runs_In_Queue_Input = {
  before?: InputMaybe<Scalars["DateTime"]>;
  labels?: InputMaybe<Array<Scalars["String"]>>;
  tenant_id: Scalars["UUID"];
};

export type Interval_Clock_Input = {
  /** The interval between runs of this flow specified in seconds */
  interval: Scalars["Int"];
  /** Parameter defaults associated with this clock */
  parameter_defaults?: InputMaybe<Scalars["JSON"]>;
};

/** Boolean expression to compare columns of type "interval". All fields are combined with logical 'AND'. */
export type Interval_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["interval"]>;
  _gt?: InputMaybe<Scalars["interval"]>;
  _gte?: InputMaybe<Scalars["interval"]>;
  _in?: InputMaybe<Array<Scalars["interval"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["interval"]>;
  _lte?: InputMaybe<Scalars["interval"]>;
  _neq?: InputMaybe<Scalars["interval"]>;
  _nin?: InputMaybe<Array<Scalars["interval"]>>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars["jsonb"]>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars["jsonb"]>;
  _eq?: InputMaybe<Scalars["jsonb"]>;
  _gt?: InputMaybe<Scalars["jsonb"]>;
  _gte?: InputMaybe<Scalars["jsonb"]>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars["String"]>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars["String"]>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars["String"]>>;
  _in?: InputMaybe<Array<Scalars["jsonb"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["jsonb"]>;
  _lte?: InputMaybe<Scalars["jsonb"]>;
  _neq?: InputMaybe<Scalars["jsonb"]>;
  _nin?: InputMaybe<Array<Scalars["jsonb"]>>;
};

/** columns and relationships of "log" */
export type Log = {
  __typename?: "log";
  created: Scalars["timestamptz"];
  flow_run_id?: Maybe<Scalars["uuid"]>;
  id: Scalars["uuid"];
  info?: Maybe<Scalars["jsonb"]>;
  is_loaded_from_archive?: Maybe<Scalars["Boolean"]>;
  level?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  task_run_id?: Maybe<Scalars["uuid"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  timestamp: Scalars["timestamptz"];
  updated: Scalars["timestamptz"];
};

/** columns and relationships of "log" */
export type LogInfoArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "log" */
export type Log_Aggregate = {
  __typename?: "log_aggregate";
  aggregate?: Maybe<Log_Aggregate_Fields>;
  nodes: Array<Log>;
};

/** aggregate fields of "log" */
export type Log_Aggregate_Fields = {
  __typename?: "log_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Log_Max_Fields>;
  min?: Maybe<Log_Min_Fields>;
};

/** aggregate fields of "log" */
export type Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Log_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "log" */
export type Log_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Log_Max_Order_By>;
  min?: InputMaybe<Log_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Log_Append_Input = {
  info?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "log" */
export type Log_Arr_Rel_Insert_Input = {
  data: Array<Log_Insert_Input>;
};

/** Boolean expression to filter rows from the table "log". All fields are combined with a logical 'AND'. */
export type Log_Bool_Exp = {
  _and?: InputMaybe<Array<Log_Bool_Exp>>;
  _not?: InputMaybe<Log_Bool_Exp>;
  _or?: InputMaybe<Array<Log_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  flow_run_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  info?: InputMaybe<Jsonb_Comparison_Exp>;
  is_loaded_from_archive?: InputMaybe<Boolean_Comparison_Exp>;
  level?: InputMaybe<String_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  task_run_id?: InputMaybe<Uuid_Comparison_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Log_Delete_At_Path_Input = {
  info?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Log_Delete_Elem_Input = {
  info?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Log_Delete_Key_Input = {
  info?: InputMaybe<Scalars["String"]>;
};

/** input type for inserting data into table "log" */
export type Log_Insert_Input = {
  created?: InputMaybe<Scalars["timestamptz"]>;
  flow_run_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  info?: InputMaybe<Scalars["jsonb"]>;
  is_loaded_from_archive?: InputMaybe<Scalars["Boolean"]>;
  level?: InputMaybe<Scalars["String"]>;
  message?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  task_run_id?: InputMaybe<Scalars["uuid"]>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  timestamp?: InputMaybe<Scalars["timestamptz"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
};

export enum Log_Level {
  Critical = "CRITICAL",
  Debug = "DEBUG",
  Error = "ERROR",
  Info = "INFO",
  Warning = "WARNING",
}

/** aggregate max on columns */
export type Log_Max_Fields = {
  __typename?: "log_max_fields";
  created?: Maybe<Scalars["timestamptz"]>;
  flow_run_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  level?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  task_run_id?: Maybe<Scalars["uuid"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  timestamp?: Maybe<Scalars["timestamptz"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "log" */
export type Log_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  flow_run_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  task_run_id?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Log_Min_Fields = {
  __typename?: "log_min_fields";
  created?: Maybe<Scalars["timestamptz"]>;
  flow_run_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  level?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  task_run_id?: Maybe<Scalars["uuid"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  timestamp?: Maybe<Scalars["timestamptz"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "log" */
export type Log_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  flow_run_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  task_run_id?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "log" */
export type Log_Mutation_Response = {
  __typename?: "log_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Log>;
};

/** Ordering options when selecting data from "log". */
export type Log_Order_By = {
  created?: InputMaybe<Order_By>;
  flow_run_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  info?: InputMaybe<Order_By>;
  is_loaded_from_archive?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  task_run_id?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Log_Prepend_Input = {
  info?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "log" */
export enum Log_Select_Column {
  /** column name */
  Created = "created",
  /** column name */
  FlowRunId = "flow_run_id",
  /** column name */
  Id = "id",
  /** column name */
  Info = "info",
  /** column name */
  IsLoadedFromArchive = "is_loaded_from_archive",
  /** column name */
  Level = "level",
  /** column name */
  Message = "message",
  /** column name */
  Name = "name",
  /** column name */
  TaskRunId = "task_run_id",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Timestamp = "timestamp",
  /** column name */
  Updated = "updated",
}

/** input type for updating data in table "log" */
export type Log_Set_Input = {
  created?: InputMaybe<Scalars["timestamptz"]>;
  flow_run_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  info?: InputMaybe<Scalars["jsonb"]>;
  is_loaded_from_archive?: InputMaybe<Scalars["Boolean"]>;
  level?: InputMaybe<Scalars["String"]>;
  message?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  task_run_id?: InputMaybe<Scalars["uuid"]>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  timestamp?: InputMaybe<Scalars["timestamptz"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
};

export type Mapped_Children_Payload = {
  __typename?: "mapped_children_payload";
  max_end_time?: Maybe<Scalars["DateTime"]>;
  min_start_time?: Maybe<Scalars["DateTime"]>;
  state_counts?: Maybe<Scalars["JSON"]>;
};

export type Mark_Message_As_Read_Input = {
  message_id: Scalars["UUID"];
};

export type Mark_Message_As_Unread_Input = {
  message_id: Scalars["UUID"];
};

/** columns and relationships of "message" */
export type Message = {
  __typename?: "message";
  content: Scalars["jsonb"];
  created: Scalars["timestamptz"];
  id: Scalars["uuid"];
  read: Scalars["Boolean"];
  tenant_id: Scalars["uuid"];
  text?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updated: Scalars["timestamptz"];
};

/** columns and relationships of "message" */
export type MessageContentArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "message" */
export type Message_Aggregate = {
  __typename?: "message_aggregate";
  aggregate?: Maybe<Message_Aggregate_Fields>;
  nodes: Array<Message>;
};

/** aggregate fields of "message" */
export type Message_Aggregate_Fields = {
  __typename?: "message_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Message_Max_Fields>;
  min?: Maybe<Message_Min_Fields>;
};

/** aggregate fields of "message" */
export type Message_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Message_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Message_Append_Input = {
  content?: InputMaybe<Scalars["jsonb"]>;
};

/** Boolean expression to filter rows from the table "message". All fields are combined with a logical 'AND'. */
export type Message_Bool_Exp = {
  _and?: InputMaybe<Array<Message_Bool_Exp>>;
  _not?: InputMaybe<Message_Bool_Exp>;
  _or?: InputMaybe<Array<Message_Bool_Exp>>;
  content?: InputMaybe<Jsonb_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  read?: InputMaybe<Boolean_Comparison_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "message" */
export enum Message_Constraint {
  /** unique or primary key constraint */
  MessagePkey = "message_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Message_Delete_At_Path_Input = {
  content?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Message_Delete_Elem_Input = {
  content?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Message_Delete_Key_Input = {
  content?: InputMaybe<Scalars["String"]>;
};

/** input type for inserting data into table "message" */
export type Message_Insert_Input = {
  content?: InputMaybe<Scalars["jsonb"]>;
  created?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  read?: InputMaybe<Scalars["Boolean"]>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  text?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Message_Max_Fields = {
  __typename?: "message_max_fields";
  created?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  text?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Message_Min_Fields = {
  __typename?: "message_min_fields";
  created?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  text?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "message" */
export type Message_Mutation_Response = {
  __typename?: "message_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Message>;
};

/** on conflict condition type for table "message" */
export type Message_On_Conflict = {
  constraint: Message_Constraint;
  update_columns?: Array<Message_Update_Column>;
  where?: InputMaybe<Message_Bool_Exp>;
};

/** Ordering options when selecting data from "message". */
export type Message_Order_By = {
  content?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  read?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: message */
export type Message_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Message_Prepend_Input = {
  content?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "message" */
export enum Message_Select_Column {
  /** column name */
  Content = "content",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Read = "read",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Text = "text",
  /** column name */
  Type = "type",
  /** column name */
  Updated = "updated",
}

/** input type for updating data in table "message" */
export type Message_Set_Input = {
  content?: InputMaybe<Scalars["jsonb"]>;
  created?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  read?: InputMaybe<Scalars["Boolean"]>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  text?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "message" */
export enum Message_Update_Column {
  /** column name */
  Content = "content",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Read = "read",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Text = "text",
  /** column name */
  Type = "type",
  /** column name */
  Updated = "updated",
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = "asc",
  /** in ascending order, nulls first */
  AscNullsFirst = "asc_nulls_first",
  /** in ascending order, nulls last */
  AscNullsLast = "asc_nulls_last",
  /** in descending order, nulls first */
  Desc = "desc",
  /** in descending order, nulls first */
  DescNullsFirst = "desc_nulls_first",
  /** in descending order, nulls last */
  DescNullsLast = "desc_nulls_last",
}

/** columns and relationships of "project" */
export type Project = {
  __typename?: "project";
  created: Scalars["timestamptz"];
  description?: Maybe<Scalars["String"]>;
  /** An array relationship */
  flows: Array<Flow>;
  /** An aggregate relationship */
  flows_aggregate: Flow_Aggregate;
  id: Scalars["uuid"];
  name: Scalars["String"];
  /** An object relationship */
  tenant?: Maybe<Tenant>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  updated: Scalars["timestamptz"];
};

/** columns and relationships of "project" */
export type ProjectFlowsArgs = {
  distinct_on?: InputMaybe<Array<Flow_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Order_By>>;
  where?: InputMaybe<Flow_Bool_Exp>;
};

/** columns and relationships of "project" */
export type ProjectFlows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flow_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Order_By>>;
  where?: InputMaybe<Flow_Bool_Exp>;
};

/** aggregated selection of "project" */
export type Project_Aggregate = {
  __typename?: "project_aggregate";
  aggregate?: Maybe<Project_Aggregate_Fields>;
  nodes: Array<Project>;
};

/** aggregate fields of "project" */
export type Project_Aggregate_Fields = {
  __typename?: "project_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Project_Max_Fields>;
  min?: Maybe<Project_Min_Fields>;
};

/** aggregate fields of "project" */
export type Project_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "project" */
export type Project_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Max_Order_By>;
  min?: InputMaybe<Project_Min_Order_By>;
};

/** input type for inserting array relation for remote table "project" */
export type Project_Arr_Rel_Insert_Input = {
  data: Array<Project_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Project_On_Conflict>;
};

/** Boolean expression to filter rows from the table "project". All fields are combined with a logical 'AND'. */
export type Project_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Bool_Exp>>;
  _not?: InputMaybe<Project_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  flows?: InputMaybe<Flow_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<Tenant_Bool_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "project" */
export enum Project_Constraint {
  /** unique or primary key constraint */
  ProjectPkey = "project_pkey",
  /** unique or primary key constraint */
  ProjectTenantIdNameKey = "project_tenant_id_name_key",
}

export type Project_Id_Payload = {
  __typename?: "project_id_payload";
  id?: Maybe<Scalars["UUID"]>;
};

/** input type for inserting data into table "project" */
export type Project_Insert_Input = {
  created?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  flows?: InputMaybe<Flow_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  tenant?: InputMaybe<Tenant_Obj_Rel_Insert_Input>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Project_Max_Fields = {
  __typename?: "project_max_fields";
  created?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "project" */
export type Project_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Min_Fields = {
  __typename?: "project_min_fields";
  created?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "project" */
export type Project_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "project" */
export type Project_Mutation_Response = {
  __typename?: "project_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Project>;
};

/** input type for inserting object relation for remote table "project" */
export type Project_Obj_Rel_Insert_Input = {
  data: Project_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Project_On_Conflict>;
};

/** on conflict condition type for table "project" */
export type Project_On_Conflict = {
  constraint: Project_Constraint;
  update_columns?: Array<Project_Update_Column>;
  where?: InputMaybe<Project_Bool_Exp>;
};

/** Ordering options when selecting data from "project". */
export type Project_Order_By = {
  created?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  flows_aggregate?: InputMaybe<Flow_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Tenant_Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project */
export type Project_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "project" */
export enum Project_Select_Column {
  /** column name */
  Created = "created",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Updated = "updated",
}

/** input type for updating data in table "project" */
export type Project_Set_Input = {
  created?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "project" */
export enum Project_Update_Column {
  /** column name */
  Created = "created",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Updated = "updated",
}

/** Reference data. */
export type Reference_Data = {
  __typename?: "reference_data";
  state_hierarchy: Scalars["JSON"];
  state_info: Scalars["JSON"];
};

export type Register_Agent_Input = {
  /** agent config id */
  agent_config_id?: InputMaybe<Scalars["UUID"]>;
  /** instance labels */
  labels?: InputMaybe<Array<Scalars["String"]>>;
  /** instance name */
  name?: InputMaybe<Scalars["String"]>;
  /** the tenant */
  tenant_id?: InputMaybe<Scalars["UUID"]>;
  /** instance type */
  type: Scalars["String"];
};

export type Register_Edges_Input = {
  /** The ID of the flow to register edges with */
  flow_id: Scalars["UUID"];
  /** The serialized representation of edges */
  serialized_edges: Scalars["JSON"];
};

export type Register_Tasks_Input = {
  /** The ID of the flow to register tasks with */
  flow_id: Scalars["UUID"];
  /** The serialized representation of tasks */
  serialized_tasks: Scalars["JSON"];
};

export type Runs_In_Queue_Payload = {
  __typename?: "runs_in_queue_payload";
  flow_run_ids?: Maybe<Array<Scalars["String"]>>;
};

export type Set_Cloud_Hook_Active_Input = {
  cloud_hook_id: Scalars["UUID"];
};

export type Set_Cloud_Hook_Inactive_Input = {
  cloud_hook_id: Scalars["UUID"];
};

export type Set_Flow_Group_Default_Parameters_Input = {
  /** The ID of the flow group to update */
  flow_group_id: Scalars["UUID"];
  /** Key/value pairs default parameters and their values. */
  parameters: Scalars["JSON"];
};

export type Set_Flow_Group_Description_Input = {
  /** Description to associate with the flow group */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the flow group to update */
  flow_group_id: Scalars["UUID"];
};

export type Set_Flow_Group_Labels_Input = {
  /** The ID of the flow group to update */
  flow_group_id: Scalars["UUID"];
  /** Labels to associate with the flow group */
  labels?: InputMaybe<Array<Scalars["String"]>>;
};

export type Set_Flow_Group_Run_Config_Input = {
  /** The ID of the flow group to update */
  flow_group_id: Scalars["UUID"];
  /** The serialized run config to associate with the flow group */
  run_config?: InputMaybe<Scalars["JSON"]>;
};

export type Set_Flow_Group_Schedule_Input = {
  /** A list of cron clocks for the schedule */
  cron_clocks?: InputMaybe<Array<Cron_Clock_Input>>;
  /** The ID of the flow group to update */
  flow_group_id: Scalars["UUID"];
  /** A list of interval clocks for the schedule */
  interval_clocks?: InputMaybe<Array<Interval_Clock_Input>>;
  /** An optional timezone to use for the schedule */
  timezone?: InputMaybe<Scalars["String"]>;
};

export type Set_Flow_Run_Labels_Input = {
  /** The ID of the flow run */
  flow_run_id: Scalars["UUID"];
  /** The new labels */
  labels: Array<Scalars["String"]>;
};

export type Set_Flow_Run_Name_Input = {
  /** The ID of the flow run */
  flow_run_id: Scalars["UUID"];
  /** The new name */
  name: Scalars["String"];
};

export type Set_Flow_Run_State_Input = {
  flow_run_id: Scalars["UUID"];
  state: Scalars["JSON"];
  version?: InputMaybe<Scalars["Int"]>;
};

export type Set_Flow_Run_State_Payload = {
  __typename?: "set_flow_run_state_payload";
  id?: Maybe<Scalars["UUID"]>;
  message?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Set_Flow_Run_States_Input = {
  states: Array<Set_Flow_Run_State_Input>;
};

export type Set_Flow_Run_States_Payload = {
  __typename?: "set_flow_run_states_payload";
  states?: Maybe<Array<Set_Flow_Run_State_Payload>>;
};

export type Set_Project_Description_Input = {
  /** The new description of this project. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the project to update */
  project_id: Scalars["UUID"];
};

export type Set_Project_Name_Input = {
  /** The new name for this project */
  name: Scalars["String"];
  /** The ID of the project to update */
  project_id: Scalars["UUID"];
};

export type Set_Schedule_Active_Input = {
  /** A flow ID whose schedule will be set to active */
  flow_id: Scalars["UUID"];
};

export type Set_Schedule_Inactive_Input = {
  /** A flow ID whose schedule will be set to active */
  flow_id: Scalars["UUID"];
};

export type Set_Task_Run_Name_Input = {
  /** The new name */
  name: Scalars["String"];
  /** The ID of the task run */
  task_run_id: Scalars["UUID"];
};

export type Set_Task_Run_State_Input = {
  flow_run_version?: InputMaybe<Scalars["Int"]>;
  state: Scalars["JSON"];
  task_run_id: Scalars["UUID"];
  version?: InputMaybe<Scalars["Int"]>;
};

export type Set_Task_Run_State_Payload = {
  __typename?: "set_task_run_state_payload";
  id?: Maybe<Scalars["UUID"]>;
  message?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Set_Task_Run_States_Input = {
  states: Array<Set_Task_Run_State_Input>;
};

export type Set_Task_Run_States_Payload = {
  __typename?: "set_task_run_states_payload";
  states?: Maybe<Array<Set_Task_Run_State_Payload>>;
};

export type Success_Payload = {
  __typename?: "success_payload";
  /** If populated, contains an error message explaining what went wrong with the mutation */
  error?: Maybe<Scalars["String"]>;
  /** A boolean specifying whether this mutation was successful */
  success?: Maybe<Scalars["Boolean"]>;
};

/** columns and relationships of "task" */
export type Task = {
  __typename?: "task";
  auto_generated: Scalars["Boolean"];
  cache_key?: Maybe<Scalars["String"]>;
  created: Scalars["timestamptz"];
  description?: Maybe<Scalars["String"]>;
  /** An array relationship */
  downstream_edges: Array<Edge>;
  /** An aggregate relationship */
  downstream_edges_aggregate: Edge_Aggregate;
  /** An object relationship */
  flow: Flow;
  flow_id: Scalars["uuid"];
  id: Scalars["uuid"];
  is_reference_task: Scalars["Boolean"];
  is_root_task: Scalars["Boolean"];
  is_terminal_task: Scalars["Boolean"];
  mapped: Scalars["Boolean"];
  max_retries?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  retry_delay?: Maybe<Scalars["interval"]>;
  slug?: Maybe<Scalars["String"]>;
  tags: Scalars["jsonb"];
  /** An array relationship */
  task_runs: Array<Task_Run>;
  /** An aggregate relationship */
  task_runs_aggregate: Task_Run_Aggregate;
  tenant_id?: Maybe<Scalars["uuid"]>;
  trigger?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updated: Scalars["timestamptz"];
  /** An array relationship */
  upstream_edges: Array<Edge>;
  /** An aggregate relationship */
  upstream_edges_aggregate: Edge_Aggregate;
};

/** columns and relationships of "task" */
export type TaskDownstream_EdgesArgs = {
  distinct_on?: InputMaybe<Array<Edge_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Edge_Order_By>>;
  where?: InputMaybe<Edge_Bool_Exp>;
};

/** columns and relationships of "task" */
export type TaskDownstream_Edges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Edge_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Edge_Order_By>>;
  where?: InputMaybe<Edge_Bool_Exp>;
};

/** columns and relationships of "task" */
export type TaskTagsArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "task" */
export type TaskTask_RunsArgs = {
  distinct_on?: InputMaybe<Array<Task_Run_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Run_Order_By>>;
  where?: InputMaybe<Task_Run_Bool_Exp>;
};

/** columns and relationships of "task" */
export type TaskTask_Runs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Run_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Run_Order_By>>;
  where?: InputMaybe<Task_Run_Bool_Exp>;
};

/** columns and relationships of "task" */
export type TaskUpstream_EdgesArgs = {
  distinct_on?: InputMaybe<Array<Edge_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Edge_Order_By>>;
  where?: InputMaybe<Edge_Bool_Exp>;
};

/** columns and relationships of "task" */
export type TaskUpstream_Edges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Edge_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Edge_Order_By>>;
  where?: InputMaybe<Edge_Bool_Exp>;
};

/** aggregated selection of "task" */
export type Task_Aggregate = {
  __typename?: "task_aggregate";
  aggregate?: Maybe<Task_Aggregate_Fields>;
  nodes: Array<Task>;
};

/** aggregate fields of "task" */
export type Task_Aggregate_Fields = {
  __typename?: "task_aggregate_fields";
  avg?: Maybe<Task_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Task_Max_Fields>;
  min?: Maybe<Task_Min_Fields>;
  stddev?: Maybe<Task_Stddev_Fields>;
  stddev_pop?: Maybe<Task_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Task_Stddev_Samp_Fields>;
  sum?: Maybe<Task_Sum_Fields>;
  var_pop?: Maybe<Task_Var_Pop_Fields>;
  var_samp?: Maybe<Task_Var_Samp_Fields>;
  variance?: Maybe<Task_Variance_Fields>;
};

/** aggregate fields of "task" */
export type Task_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "task" */
export type Task_Aggregate_Order_By = {
  avg?: InputMaybe<Task_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_Max_Order_By>;
  min?: InputMaybe<Task_Min_Order_By>;
  stddev?: InputMaybe<Task_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Task_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Task_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Task_Sum_Order_By>;
  var_pop?: InputMaybe<Task_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Task_Var_Samp_Order_By>;
  variance?: InputMaybe<Task_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Task_Append_Input = {
  tags?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "task" */
export type Task_Arr_Rel_Insert_Input = {
  data: Array<Task_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Task_On_Conflict>;
};

/** aggregate avg on columns */
export type Task_Avg_Fields = {
  __typename?: "task_avg_fields";
  max_retries?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "task" */
export type Task_Avg_Order_By = {
  max_retries?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "task". All fields are combined with a logical 'AND'. */
export type Task_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Bool_Exp>>;
  _not?: InputMaybe<Task_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Bool_Exp>>;
  auto_generated?: InputMaybe<Boolean_Comparison_Exp>;
  cache_key?: InputMaybe<String_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  downstream_edges?: InputMaybe<Edge_Bool_Exp>;
  flow?: InputMaybe<Flow_Bool_Exp>;
  flow_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_reference_task?: InputMaybe<Boolean_Comparison_Exp>;
  is_root_task?: InputMaybe<Boolean_Comparison_Exp>;
  is_terminal_task?: InputMaybe<Boolean_Comparison_Exp>;
  mapped?: InputMaybe<Boolean_Comparison_Exp>;
  max_retries?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  retry_delay?: InputMaybe<Interval_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  tags?: InputMaybe<Jsonb_Comparison_Exp>;
  task_runs?: InputMaybe<Task_Run_Bool_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  trigger?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  upstream_edges?: InputMaybe<Edge_Bool_Exp>;
};

/** unique or primary key constraints on table "task" */
export enum Task_Constraint {
  /** unique or primary key constraint */
  TaskFlowIdSlugKey = "task_flow_id_slug_key",
  /** unique or primary key constraint */
  TaskPkey = "task_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Task_Delete_At_Path_Input = {
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Task_Delete_Elem_Input = {
  tags?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Task_Delete_Key_Input = {
  tags?: InputMaybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "task" */
export type Task_Inc_Input = {
  max_retries?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "task" */
export type Task_Insert_Input = {
  auto_generated?: InputMaybe<Scalars["Boolean"]>;
  cache_key?: InputMaybe<Scalars["String"]>;
  created?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  downstream_edges?: InputMaybe<Edge_Arr_Rel_Insert_Input>;
  flow?: InputMaybe<Flow_Obj_Rel_Insert_Input>;
  flow_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_reference_task?: InputMaybe<Scalars["Boolean"]>;
  is_root_task?: InputMaybe<Scalars["Boolean"]>;
  is_terminal_task?: InputMaybe<Scalars["Boolean"]>;
  mapped?: InputMaybe<Scalars["Boolean"]>;
  max_retries?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  retry_delay?: InputMaybe<Scalars["interval"]>;
  slug?: InputMaybe<Scalars["String"]>;
  tags?: InputMaybe<Scalars["jsonb"]>;
  task_runs?: InputMaybe<Task_Run_Arr_Rel_Insert_Input>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  trigger?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
  upstream_edges?: InputMaybe<Edge_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Task_Max_Fields = {
  __typename?: "task_max_fields";
  cache_key?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  flow_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  max_retries?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  trigger?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "task" */
export type Task_Max_Order_By = {
  cache_key?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  flow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_retries?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  trigger?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Min_Fields = {
  __typename?: "task_min_fields";
  cache_key?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  flow_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  max_retries?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  trigger?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "task" */
export type Task_Min_Order_By = {
  cache_key?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  flow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_retries?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  trigger?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task" */
export type Task_Mutation_Response = {
  __typename?: "task_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Task>;
};

/** input type for inserting object relation for remote table "task" */
export type Task_Obj_Rel_Insert_Input = {
  data: Task_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Task_On_Conflict>;
};

/** on conflict condition type for table "task" */
export type Task_On_Conflict = {
  constraint: Task_Constraint;
  update_columns?: Array<Task_Update_Column>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** Ordering options when selecting data from "task". */
export type Task_Order_By = {
  auto_generated?: InputMaybe<Order_By>;
  cache_key?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  downstream_edges_aggregate?: InputMaybe<Edge_Aggregate_Order_By>;
  flow?: InputMaybe<Flow_Order_By>;
  flow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_reference_task?: InputMaybe<Order_By>;
  is_root_task?: InputMaybe<Order_By>;
  is_terminal_task?: InputMaybe<Order_By>;
  mapped?: InputMaybe<Order_By>;
  max_retries?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  retry_delay?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  task_runs_aggregate?: InputMaybe<Task_Run_Aggregate_Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  trigger?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  upstream_edges_aggregate?: InputMaybe<Edge_Aggregate_Order_By>;
};

/** primary key columns input for table: task */
export type Task_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Task_Prepend_Input = {
  tags?: InputMaybe<Scalars["jsonb"]>;
};

/** columns and relationships of "task_run" */
export type Task_Run = {
  __typename?: "task_run";
  cache_key?: Maybe<Scalars["String"]>;
  created: Scalars["timestamptz"];
  end_time?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  flow_run: Flow_Run;
  flow_run_id: Scalars["uuid"];
  heartbeat?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An array relationship */
  logs: Array<Log>;
  /** An aggregate relationship */
  logs_aggregate: Log_Aggregate;
  map_index: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  serialized_state?: Maybe<Scalars["jsonb"]>;
  start_time?: Maybe<Scalars["timestamptz"]>;
  state?: Maybe<Scalars["String"]>;
  state_message?: Maybe<Scalars["String"]>;
  state_result?: Maybe<Scalars["jsonb"]>;
  state_start_time?: Maybe<Scalars["timestamptz"]>;
  state_timestamp?: Maybe<Scalars["timestamptz"]>;
  /** An array relationship */
  states: Array<Task_Run_State>;
  /** An aggregate relationship */
  states_aggregate: Task_Run_State_Aggregate;
  /** An object relationship */
  task: Task;
  task_id: Scalars["uuid"];
  /** An object relationship */
  tenant?: Maybe<Tenant>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  updated: Scalars["timestamptz"];
  version: Scalars["Int"];
};

/** columns and relationships of "task_run" */
export type Task_RunLogsArgs = {
  distinct_on?: InputMaybe<Array<Log_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Log_Order_By>>;
  where?: InputMaybe<Log_Bool_Exp>;
};

/** columns and relationships of "task_run" */
export type Task_RunLogs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Log_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Log_Order_By>>;
  where?: InputMaybe<Log_Bool_Exp>;
};

/** columns and relationships of "task_run" */
export type Task_RunSerialized_StateArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "task_run" */
export type Task_RunState_ResultArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "task_run" */
export type Task_RunStatesArgs = {
  distinct_on?: InputMaybe<Array<Task_Run_State_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Run_State_Order_By>>;
  where?: InputMaybe<Task_Run_State_Bool_Exp>;
};

/** columns and relationships of "task_run" */
export type Task_RunStates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Run_State_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Task_Run_State_Order_By>>;
  where?: InputMaybe<Task_Run_State_Bool_Exp>;
};

/** aggregated selection of "task_run" */
export type Task_Run_Aggregate = {
  __typename?: "task_run_aggregate";
  aggregate?: Maybe<Task_Run_Aggregate_Fields>;
  nodes: Array<Task_Run>;
};

/** aggregate fields of "task_run" */
export type Task_Run_Aggregate_Fields = {
  __typename?: "task_run_aggregate_fields";
  avg?: Maybe<Task_Run_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Task_Run_Max_Fields>;
  min?: Maybe<Task_Run_Min_Fields>;
  stddev?: Maybe<Task_Run_Stddev_Fields>;
  stddev_pop?: Maybe<Task_Run_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Task_Run_Stddev_Samp_Fields>;
  sum?: Maybe<Task_Run_Sum_Fields>;
  var_pop?: Maybe<Task_Run_Var_Pop_Fields>;
  var_samp?: Maybe<Task_Run_Var_Samp_Fields>;
  variance?: Maybe<Task_Run_Variance_Fields>;
};

/** aggregate fields of "task_run" */
export type Task_Run_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Run_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "task_run" */
export type Task_Run_Aggregate_Order_By = {
  avg?: InputMaybe<Task_Run_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_Run_Max_Order_By>;
  min?: InputMaybe<Task_Run_Min_Order_By>;
  stddev?: InputMaybe<Task_Run_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Task_Run_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Task_Run_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Task_Run_Sum_Order_By>;
  var_pop?: InputMaybe<Task_Run_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Task_Run_Var_Samp_Order_By>;
  variance?: InputMaybe<Task_Run_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Task_Run_Append_Input = {
  serialized_state?: InputMaybe<Scalars["jsonb"]>;
  state_result?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "task_run" */
export type Task_Run_Arr_Rel_Insert_Input = {
  data: Array<Task_Run_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Task_Run_On_Conflict>;
};

/** columns and relationships of "task_run_artifact" */
export type Task_Run_Artifact = {
  __typename?: "task_run_artifact";
  created: Scalars["timestamptz"];
  data: Scalars["jsonb"];
  id: Scalars["uuid"];
  kind: Scalars["String"];
  /** An object relationship */
  task_run: Task_Run;
  task_run_id: Scalars["uuid"];
  tenant_id: Scalars["uuid"];
  updated: Scalars["timestamptz"];
};

/** columns and relationships of "task_run_artifact" */
export type Task_Run_ArtifactDataArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "task_run_artifact" */
export type Task_Run_Artifact_Aggregate = {
  __typename?: "task_run_artifact_aggregate";
  aggregate?: Maybe<Task_Run_Artifact_Aggregate_Fields>;
  nodes: Array<Task_Run_Artifact>;
};

/** aggregate fields of "task_run_artifact" */
export type Task_Run_Artifact_Aggregate_Fields = {
  __typename?: "task_run_artifact_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Task_Run_Artifact_Max_Fields>;
  min?: Maybe<Task_Run_Artifact_Min_Fields>;
};

/** aggregate fields of "task_run_artifact" */
export type Task_Run_Artifact_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Run_Artifact_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Task_Run_Artifact_Append_Input = {
  data?: InputMaybe<Scalars["jsonb"]>;
};

/** Boolean expression to filter rows from the table "task_run_artifact". All fields are combined with a logical 'AND'. */
export type Task_Run_Artifact_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Run_Artifact_Bool_Exp>>;
  _not?: InputMaybe<Task_Run_Artifact_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Run_Artifact_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  task_run?: InputMaybe<Task_Run_Bool_Exp>;
  task_run_id?: InputMaybe<Uuid_Comparison_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_run_artifact" */
export enum Task_Run_Artifact_Constraint {
  /** unique or primary key constraint */
  TaskRunArtifactPkey = "task_run_artifact_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Task_Run_Artifact_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Task_Run_Artifact_Delete_Elem_Input = {
  data?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Task_Run_Artifact_Delete_Key_Input = {
  data?: InputMaybe<Scalars["String"]>;
};

export type Task_Run_Artifact_Id_Payload = {
  __typename?: "task_run_artifact_id_payload";
  id?: Maybe<Scalars["UUID"]>;
};

/** input type for inserting data into table "task_run_artifact" */
export type Task_Run_Artifact_Insert_Input = {
  created?: InputMaybe<Scalars["timestamptz"]>;
  data?: InputMaybe<Scalars["jsonb"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  task_run?: InputMaybe<Task_Run_Obj_Rel_Insert_Input>;
  task_run_id?: InputMaybe<Scalars["uuid"]>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Task_Run_Artifact_Max_Fields = {
  __typename?: "task_run_artifact_max_fields";
  created?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  kind?: Maybe<Scalars["String"]>;
  task_run_id?: Maybe<Scalars["uuid"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Task_Run_Artifact_Min_Fields = {
  __typename?: "task_run_artifact_min_fields";
  created?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  kind?: Maybe<Scalars["String"]>;
  task_run_id?: Maybe<Scalars["uuid"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "task_run_artifact" */
export type Task_Run_Artifact_Mutation_Response = {
  __typename?: "task_run_artifact_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Run_Artifact>;
};

/** on conflict condition type for table "task_run_artifact" */
export type Task_Run_Artifact_On_Conflict = {
  constraint: Task_Run_Artifact_Constraint;
  update_columns?: Array<Task_Run_Artifact_Update_Column>;
  where?: InputMaybe<Task_Run_Artifact_Bool_Exp>;
};

/** Ordering options when selecting data from "task_run_artifact". */
export type Task_Run_Artifact_Order_By = {
  created?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  task_run?: InputMaybe<Task_Run_Order_By>;
  task_run_id?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task_run_artifact */
export type Task_Run_Artifact_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Task_Run_Artifact_Prepend_Input = {
  data?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "task_run_artifact" */
export enum Task_Run_Artifact_Select_Column {
  /** column name */
  Created = "created",
  /** column name */
  Data = "data",
  /** column name */
  Id = "id",
  /** column name */
  Kind = "kind",
  /** column name */
  TaskRunId = "task_run_id",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Updated = "updated",
}

/** input type for updating data in table "task_run_artifact" */
export type Task_Run_Artifact_Set_Input = {
  created?: InputMaybe<Scalars["timestamptz"]>;
  data?: InputMaybe<Scalars["jsonb"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  task_run_id?: InputMaybe<Scalars["uuid"]>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "task_run_artifact" */
export enum Task_Run_Artifact_Update_Column {
  /** column name */
  Created = "created",
  /** column name */
  Data = "data",
  /** column name */
  Id = "id",
  /** column name */
  Kind = "kind",
  /** column name */
  TaskRunId = "task_run_id",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Updated = "updated",
}

/** aggregate avg on columns */
export type Task_Run_Avg_Fields = {
  __typename?: "task_run_avg_fields";
  map_index?: Maybe<Scalars["Float"]>;
  version?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "task_run" */
export type Task_Run_Avg_Order_By = {
  map_index?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "task_run". All fields are combined with a logical 'AND'. */
export type Task_Run_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Run_Bool_Exp>>;
  _not?: InputMaybe<Task_Run_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Run_Bool_Exp>>;
  cache_key?: InputMaybe<String_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  end_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  flow_run?: InputMaybe<Flow_Run_Bool_Exp>;
  flow_run_id?: InputMaybe<Uuid_Comparison_Exp>;
  heartbeat?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  logs?: InputMaybe<Log_Bool_Exp>;
  map_index?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  serialized_state?: InputMaybe<Jsonb_Comparison_Exp>;
  start_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  state_message?: InputMaybe<String_Comparison_Exp>;
  state_result?: InputMaybe<Jsonb_Comparison_Exp>;
  state_start_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  state_timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  states?: InputMaybe<Task_Run_State_Bool_Exp>;
  task?: InputMaybe<Task_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
  tenant?: InputMaybe<Tenant_Bool_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_run" */
export enum Task_Run_Constraint {
  /** unique or primary key constraint */
  TaskRunPkey = "task_run_pkey",
  /** unique or primary key constraint */
  TaskRunUniqueIdentifierKey = "task_run_unique_identifier_key",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Task_Run_Delete_At_Path_Input = {
  serialized_state?: InputMaybe<Array<Scalars["String"]>>;
  state_result?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Task_Run_Delete_Elem_Input = {
  serialized_state?: InputMaybe<Scalars["Int"]>;
  state_result?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Task_Run_Delete_Key_Input = {
  serialized_state?: InputMaybe<Scalars["String"]>;
  state_result?: InputMaybe<Scalars["String"]>;
};

export type Task_Run_Id_Payload = {
  __typename?: "task_run_id_payload";
  id?: Maybe<Scalars["UUID"]>;
};

/** input type for incrementing numeric columns in table "task_run" */
export type Task_Run_Inc_Input = {
  map_index?: InputMaybe<Scalars["Int"]>;
  version?: InputMaybe<Scalars["Int"]>;
};

export type Task_Run_Info_Payload = {
  __typename?: "task_run_info_payload";
  id?: Maybe<Scalars["UUID"]>;
  serialized_state?: Maybe<Scalars["JSON"]>;
  state?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "task_run" */
export type Task_Run_Insert_Input = {
  cache_key?: InputMaybe<Scalars["String"]>;
  created?: InputMaybe<Scalars["timestamptz"]>;
  end_time?: InputMaybe<Scalars["timestamptz"]>;
  flow_run?: InputMaybe<Flow_Run_Obj_Rel_Insert_Input>;
  flow_run_id?: InputMaybe<Scalars["uuid"]>;
  heartbeat?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  logs?: InputMaybe<Log_Arr_Rel_Insert_Input>;
  map_index?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  serialized_state?: InputMaybe<Scalars["jsonb"]>;
  start_time?: InputMaybe<Scalars["timestamptz"]>;
  state?: InputMaybe<Scalars["String"]>;
  state_message?: InputMaybe<Scalars["String"]>;
  state_result?: InputMaybe<Scalars["jsonb"]>;
  state_start_time?: InputMaybe<Scalars["timestamptz"]>;
  state_timestamp?: InputMaybe<Scalars["timestamptz"]>;
  states?: InputMaybe<Task_Run_State_Arr_Rel_Insert_Input>;
  task?: InputMaybe<Task_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars["uuid"]>;
  tenant?: InputMaybe<Tenant_Obj_Rel_Insert_Input>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
  version?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Task_Run_Max_Fields = {
  __typename?: "task_run_max_fields";
  cache_key?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["timestamptz"]>;
  end_time?: Maybe<Scalars["timestamptz"]>;
  flow_run_id?: Maybe<Scalars["uuid"]>;
  heartbeat?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  map_index?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["timestamptz"]>;
  state?: Maybe<Scalars["String"]>;
  state_message?: Maybe<Scalars["String"]>;
  state_start_time?: Maybe<Scalars["timestamptz"]>;
  state_timestamp?: Maybe<Scalars["timestamptz"]>;
  task_id?: Maybe<Scalars["uuid"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
  version?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "task_run" */
export type Task_Run_Max_Order_By = {
  cache_key?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  flow_run_id?: InputMaybe<Order_By>;
  heartbeat?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  map_index?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  state_message?: InputMaybe<Order_By>;
  state_start_time?: InputMaybe<Order_By>;
  state_timestamp?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Run_Min_Fields = {
  __typename?: "task_run_min_fields";
  cache_key?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["timestamptz"]>;
  end_time?: Maybe<Scalars["timestamptz"]>;
  flow_run_id?: Maybe<Scalars["uuid"]>;
  heartbeat?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  map_index?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["timestamptz"]>;
  state?: Maybe<Scalars["String"]>;
  state_message?: Maybe<Scalars["String"]>;
  state_start_time?: Maybe<Scalars["timestamptz"]>;
  state_timestamp?: Maybe<Scalars["timestamptz"]>;
  task_id?: Maybe<Scalars["uuid"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
  version?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "task_run" */
export type Task_Run_Min_Order_By = {
  cache_key?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  flow_run_id?: InputMaybe<Order_By>;
  heartbeat?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  map_index?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  state_message?: InputMaybe<Order_By>;
  state_start_time?: InputMaybe<Order_By>;
  state_timestamp?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task_run" */
export type Task_Run_Mutation_Response = {
  __typename?: "task_run_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Run>;
};

/** input type for inserting object relation for remote table "task_run" */
export type Task_Run_Obj_Rel_Insert_Input = {
  data: Task_Run_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Task_Run_On_Conflict>;
};

/** on conflict condition type for table "task_run" */
export type Task_Run_On_Conflict = {
  constraint: Task_Run_Constraint;
  update_columns?: Array<Task_Run_Update_Column>;
  where?: InputMaybe<Task_Run_Bool_Exp>;
};

/** Ordering options when selecting data from "task_run". */
export type Task_Run_Order_By = {
  cache_key?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  flow_run?: InputMaybe<Flow_Run_Order_By>;
  flow_run_id?: InputMaybe<Order_By>;
  heartbeat?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logs_aggregate?: InputMaybe<Log_Aggregate_Order_By>;
  map_index?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  serialized_state?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  state_message?: InputMaybe<Order_By>;
  state_result?: InputMaybe<Order_By>;
  state_start_time?: InputMaybe<Order_By>;
  state_timestamp?: InputMaybe<Order_By>;
  states_aggregate?: InputMaybe<Task_Run_State_Aggregate_Order_By>;
  task?: InputMaybe<Task_Order_By>;
  task_id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Tenant_Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task_run */
export type Task_Run_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Task_Run_Prepend_Input = {
  serialized_state?: InputMaybe<Scalars["jsonb"]>;
  state_result?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "task_run" */
export enum Task_Run_Select_Column {
  /** column name */
  CacheKey = "cache_key",
  /** column name */
  Created = "created",
  /** column name */
  EndTime = "end_time",
  /** column name */
  FlowRunId = "flow_run_id",
  /** column name */
  Heartbeat = "heartbeat",
  /** column name */
  Id = "id",
  /** column name */
  MapIndex = "map_index",
  /** column name */
  Name = "name",
  /** column name */
  SerializedState = "serialized_state",
  /** column name */
  StartTime = "start_time",
  /** column name */
  State = "state",
  /** column name */
  StateMessage = "state_message",
  /** column name */
  StateResult = "state_result",
  /** column name */
  StateStartTime = "state_start_time",
  /** column name */
  StateTimestamp = "state_timestamp",
  /** column name */
  TaskId = "task_id",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Updated = "updated",
  /** column name */
  Version = "version",
}

/** input type for updating data in table "task_run" */
export type Task_Run_Set_Input = {
  cache_key?: InputMaybe<Scalars["String"]>;
  created?: InputMaybe<Scalars["timestamptz"]>;
  end_time?: InputMaybe<Scalars["timestamptz"]>;
  flow_run_id?: InputMaybe<Scalars["uuid"]>;
  heartbeat?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  map_index?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  serialized_state?: InputMaybe<Scalars["jsonb"]>;
  start_time?: InputMaybe<Scalars["timestamptz"]>;
  state?: InputMaybe<Scalars["String"]>;
  state_message?: InputMaybe<Scalars["String"]>;
  state_result?: InputMaybe<Scalars["jsonb"]>;
  state_start_time?: InputMaybe<Scalars["timestamptz"]>;
  state_timestamp?: InputMaybe<Scalars["timestamptz"]>;
  task_id?: InputMaybe<Scalars["uuid"]>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
  version?: InputMaybe<Scalars["Int"]>;
};

/** columns and relationships of "task_run_state" */
export type Task_Run_State = {
  __typename?: "task_run_state";
  created: Scalars["timestamptz"];
  id: Scalars["uuid"];
  message?: Maybe<Scalars["String"]>;
  result?: Maybe<Scalars["jsonb"]>;
  serialized_state: Scalars["jsonb"];
  start_time?: Maybe<Scalars["timestamptz"]>;
  state: Scalars["String"];
  /** An object relationship */
  task_run: Task_Run;
  task_run_id: Scalars["uuid"];
  tenant_id?: Maybe<Scalars["uuid"]>;
  timestamp: Scalars["timestamptz"];
  updated: Scalars["timestamptz"];
  version?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "task_run_state" */
export type Task_Run_StateResultArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "task_run_state" */
export type Task_Run_StateSerialized_StateArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "task_run_state" */
export type Task_Run_State_Aggregate = {
  __typename?: "task_run_state_aggregate";
  aggregate?: Maybe<Task_Run_State_Aggregate_Fields>;
  nodes: Array<Task_Run_State>;
};

/** aggregate fields of "task_run_state" */
export type Task_Run_State_Aggregate_Fields = {
  __typename?: "task_run_state_aggregate_fields";
  avg?: Maybe<Task_Run_State_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Task_Run_State_Max_Fields>;
  min?: Maybe<Task_Run_State_Min_Fields>;
  stddev?: Maybe<Task_Run_State_Stddev_Fields>;
  stddev_pop?: Maybe<Task_Run_State_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Task_Run_State_Stddev_Samp_Fields>;
  sum?: Maybe<Task_Run_State_Sum_Fields>;
  var_pop?: Maybe<Task_Run_State_Var_Pop_Fields>;
  var_samp?: Maybe<Task_Run_State_Var_Samp_Fields>;
  variance?: Maybe<Task_Run_State_Variance_Fields>;
};

/** aggregate fields of "task_run_state" */
export type Task_Run_State_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Run_State_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "task_run_state" */
export type Task_Run_State_Aggregate_Order_By = {
  avg?: InputMaybe<Task_Run_State_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_Run_State_Max_Order_By>;
  min?: InputMaybe<Task_Run_State_Min_Order_By>;
  stddev?: InputMaybe<Task_Run_State_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Task_Run_State_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Task_Run_State_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Task_Run_State_Sum_Order_By>;
  var_pop?: InputMaybe<Task_Run_State_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Task_Run_State_Var_Samp_Order_By>;
  variance?: InputMaybe<Task_Run_State_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Task_Run_State_Append_Input = {
  result?: InputMaybe<Scalars["jsonb"]>;
  serialized_state?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "task_run_state" */
export type Task_Run_State_Arr_Rel_Insert_Input = {
  data: Array<Task_Run_State_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Task_Run_State_On_Conflict>;
};

/** aggregate avg on columns */
export type Task_Run_State_Avg_Fields = {
  __typename?: "task_run_state_avg_fields";
  version?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "task_run_state" */
export type Task_Run_State_Avg_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "task_run_state". All fields are combined with a logical 'AND'. */
export type Task_Run_State_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Run_State_Bool_Exp>>;
  _not?: InputMaybe<Task_Run_State_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Run_State_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  result?: InputMaybe<Jsonb_Comparison_Exp>;
  serialized_state?: InputMaybe<Jsonb_Comparison_Exp>;
  start_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  task_run?: InputMaybe<Task_Run_Bool_Exp>;
  task_run_id?: InputMaybe<Uuid_Comparison_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_run_state" */
export enum Task_Run_State_Constraint {
  /** unique or primary key constraint */
  TaskRunStatePkey = "task_run_state_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Task_Run_State_Delete_At_Path_Input = {
  result?: InputMaybe<Array<Scalars["String"]>>;
  serialized_state?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Task_Run_State_Delete_Elem_Input = {
  result?: InputMaybe<Scalars["Int"]>;
  serialized_state?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Task_Run_State_Delete_Key_Input = {
  result?: InputMaybe<Scalars["String"]>;
  serialized_state?: InputMaybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "task_run_state" */
export type Task_Run_State_Inc_Input = {
  version?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "task_run_state" */
export type Task_Run_State_Insert_Input = {
  created?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  message?: InputMaybe<Scalars["String"]>;
  result?: InputMaybe<Scalars["jsonb"]>;
  serialized_state?: InputMaybe<Scalars["jsonb"]>;
  start_time?: InputMaybe<Scalars["timestamptz"]>;
  state?: InputMaybe<Scalars["String"]>;
  task_run?: InputMaybe<Task_Run_Obj_Rel_Insert_Input>;
  task_run_id?: InputMaybe<Scalars["uuid"]>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  timestamp?: InputMaybe<Scalars["timestamptz"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
  version?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Task_Run_State_Max_Fields = {
  __typename?: "task_run_state_max_fields";
  created?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  message?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["timestamptz"]>;
  state?: Maybe<Scalars["String"]>;
  task_run_id?: Maybe<Scalars["uuid"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  timestamp?: Maybe<Scalars["timestamptz"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
  version?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "task_run_state" */
export type Task_Run_State_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  task_run_id?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Run_State_Min_Fields = {
  __typename?: "task_run_state_min_fields";
  created?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  message?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["timestamptz"]>;
  state?: Maybe<Scalars["String"]>;
  task_run_id?: Maybe<Scalars["uuid"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
  timestamp?: Maybe<Scalars["timestamptz"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
  version?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "task_run_state" */
export type Task_Run_State_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  task_run_id?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task_run_state" */
export type Task_Run_State_Mutation_Response = {
  __typename?: "task_run_state_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Run_State>;
};

/** on conflict condition type for table "task_run_state" */
export type Task_Run_State_On_Conflict = {
  constraint: Task_Run_State_Constraint;
  update_columns?: Array<Task_Run_State_Update_Column>;
  where?: InputMaybe<Task_Run_State_Bool_Exp>;
};

/** Ordering options when selecting data from "task_run_state". */
export type Task_Run_State_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  serialized_state?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  task_run?: InputMaybe<Task_Run_Order_By>;
  task_run_id?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task_run_state */
export type Task_Run_State_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Task_Run_State_Prepend_Input = {
  result?: InputMaybe<Scalars["jsonb"]>;
  serialized_state?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "task_run_state" */
export enum Task_Run_State_Select_Column {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Message = "message",
  /** column name */
  Result = "result",
  /** column name */
  SerializedState = "serialized_state",
  /** column name */
  StartTime = "start_time",
  /** column name */
  State = "state",
  /** column name */
  TaskRunId = "task_run_id",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Timestamp = "timestamp",
  /** column name */
  Updated = "updated",
  /** column name */
  Version = "version",
}

/** input type for updating data in table "task_run_state" */
export type Task_Run_State_Set_Input = {
  created?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  message?: InputMaybe<Scalars["String"]>;
  result?: InputMaybe<Scalars["jsonb"]>;
  serialized_state?: InputMaybe<Scalars["jsonb"]>;
  start_time?: InputMaybe<Scalars["timestamptz"]>;
  state?: InputMaybe<Scalars["String"]>;
  task_run_id?: InputMaybe<Scalars["uuid"]>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  timestamp?: InputMaybe<Scalars["timestamptz"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
  version?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Task_Run_State_Stddev_Fields = {
  __typename?: "task_run_state_stddev_fields";
  version?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "task_run_state" */
export type Task_Run_State_Stddev_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Task_Run_State_Stddev_Pop_Fields = {
  __typename?: "task_run_state_stddev_pop_fields";
  version?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "task_run_state" */
export type Task_Run_State_Stddev_Pop_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Task_Run_State_Stddev_Samp_Fields = {
  __typename?: "task_run_state_stddev_samp_fields";
  version?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "task_run_state" */
export type Task_Run_State_Stddev_Samp_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Task_Run_State_Sum_Fields = {
  __typename?: "task_run_state_sum_fields";
  version?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "task_run_state" */
export type Task_Run_State_Sum_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** update columns of table "task_run_state" */
export enum Task_Run_State_Update_Column {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Message = "message",
  /** column name */
  Result = "result",
  /** column name */
  SerializedState = "serialized_state",
  /** column name */
  StartTime = "start_time",
  /** column name */
  State = "state",
  /** column name */
  TaskRunId = "task_run_id",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Timestamp = "timestamp",
  /** column name */
  Updated = "updated",
  /** column name */
  Version = "version",
}

/** aggregate var_pop on columns */
export type Task_Run_State_Var_Pop_Fields = {
  __typename?: "task_run_state_var_pop_fields";
  version?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "task_run_state" */
export type Task_Run_State_Var_Pop_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Task_Run_State_Var_Samp_Fields = {
  __typename?: "task_run_state_var_samp_fields";
  version?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "task_run_state" */
export type Task_Run_State_Var_Samp_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Task_Run_State_Variance_Fields = {
  __typename?: "task_run_state_variance_fields";
  version?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "task_run_state" */
export type Task_Run_State_Variance_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev on columns */
export type Task_Run_Stddev_Fields = {
  __typename?: "task_run_stddev_fields";
  map_index?: Maybe<Scalars["Float"]>;
  version?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "task_run" */
export type Task_Run_Stddev_Order_By = {
  map_index?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Task_Run_Stddev_Pop_Fields = {
  __typename?: "task_run_stddev_pop_fields";
  map_index?: Maybe<Scalars["Float"]>;
  version?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "task_run" */
export type Task_Run_Stddev_Pop_Order_By = {
  map_index?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Task_Run_Stddev_Samp_Fields = {
  __typename?: "task_run_stddev_samp_fields";
  map_index?: Maybe<Scalars["Float"]>;
  version?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "task_run" */
export type Task_Run_Stddev_Samp_Order_By = {
  map_index?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Task_Run_Sum_Fields = {
  __typename?: "task_run_sum_fields";
  map_index?: Maybe<Scalars["Int"]>;
  version?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "task_run" */
export type Task_Run_Sum_Order_By = {
  map_index?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "task_run" */
export enum Task_Run_Update_Column {
  /** column name */
  CacheKey = "cache_key",
  /** column name */
  Created = "created",
  /** column name */
  EndTime = "end_time",
  /** column name */
  FlowRunId = "flow_run_id",
  /** column name */
  Heartbeat = "heartbeat",
  /** column name */
  Id = "id",
  /** column name */
  MapIndex = "map_index",
  /** column name */
  Name = "name",
  /** column name */
  SerializedState = "serialized_state",
  /** column name */
  StartTime = "start_time",
  /** column name */
  State = "state",
  /** column name */
  StateMessage = "state_message",
  /** column name */
  StateResult = "state_result",
  /** column name */
  StateStartTime = "state_start_time",
  /** column name */
  StateTimestamp = "state_timestamp",
  /** column name */
  TaskId = "task_id",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Updated = "updated",
  /** column name */
  Version = "version",
}

/** aggregate var_pop on columns */
export type Task_Run_Var_Pop_Fields = {
  __typename?: "task_run_var_pop_fields";
  map_index?: Maybe<Scalars["Float"]>;
  version?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "task_run" */
export type Task_Run_Var_Pop_Order_By = {
  map_index?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Task_Run_Var_Samp_Fields = {
  __typename?: "task_run_var_samp_fields";
  map_index?: Maybe<Scalars["Float"]>;
  version?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "task_run" */
export type Task_Run_Var_Samp_Order_By = {
  map_index?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Task_Run_Variance_Fields = {
  __typename?: "task_run_variance_fields";
  map_index?: Maybe<Scalars["Float"]>;
  version?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "task_run" */
export type Task_Run_Variance_Order_By = {
  map_index?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** select columns of table "task" */
export enum Task_Select_Column {
  /** column name */
  AutoGenerated = "auto_generated",
  /** column name */
  CacheKey = "cache_key",
  /** column name */
  Created = "created",
  /** column name */
  Description = "description",
  /** column name */
  FlowId = "flow_id",
  /** column name */
  Id = "id",
  /** column name */
  IsReferenceTask = "is_reference_task",
  /** column name */
  IsRootTask = "is_root_task",
  /** column name */
  IsTerminalTask = "is_terminal_task",
  /** column name */
  Mapped = "mapped",
  /** column name */
  MaxRetries = "max_retries",
  /** column name */
  Name = "name",
  /** column name */
  RetryDelay = "retry_delay",
  /** column name */
  Slug = "slug",
  /** column name */
  Tags = "tags",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Trigger = "trigger",
  /** column name */
  Type = "type",
  /** column name */
  Updated = "updated",
}

/** input type for updating data in table "task" */
export type Task_Set_Input = {
  auto_generated?: InputMaybe<Scalars["Boolean"]>;
  cache_key?: InputMaybe<Scalars["String"]>;
  created?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  flow_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_reference_task?: InputMaybe<Scalars["Boolean"]>;
  is_root_task?: InputMaybe<Scalars["Boolean"]>;
  is_terminal_task?: InputMaybe<Scalars["Boolean"]>;
  mapped?: InputMaybe<Scalars["Boolean"]>;
  max_retries?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  retry_delay?: InputMaybe<Scalars["interval"]>;
  slug?: InputMaybe<Scalars["String"]>;
  tags?: InputMaybe<Scalars["jsonb"]>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
  trigger?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Task_Stddev_Fields = {
  __typename?: "task_stddev_fields";
  max_retries?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "task" */
export type Task_Stddev_Order_By = {
  max_retries?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Task_Stddev_Pop_Fields = {
  __typename?: "task_stddev_pop_fields";
  max_retries?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "task" */
export type Task_Stddev_Pop_Order_By = {
  max_retries?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Task_Stddev_Samp_Fields = {
  __typename?: "task_stddev_samp_fields";
  max_retries?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "task" */
export type Task_Stddev_Samp_Order_By = {
  max_retries?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Task_Sum_Fields = {
  __typename?: "task_sum_fields";
  max_retries?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "task" */
export type Task_Sum_Order_By = {
  max_retries?: InputMaybe<Order_By>;
};

/** update columns of table "task" */
export enum Task_Update_Column {
  /** column name */
  AutoGenerated = "auto_generated",
  /** column name */
  CacheKey = "cache_key",
  /** column name */
  Created = "created",
  /** column name */
  Description = "description",
  /** column name */
  FlowId = "flow_id",
  /** column name */
  Id = "id",
  /** column name */
  IsReferenceTask = "is_reference_task",
  /** column name */
  IsRootTask = "is_root_task",
  /** column name */
  IsTerminalTask = "is_terminal_task",
  /** column name */
  Mapped = "mapped",
  /** column name */
  MaxRetries = "max_retries",
  /** column name */
  Name = "name",
  /** column name */
  RetryDelay = "retry_delay",
  /** column name */
  Slug = "slug",
  /** column name */
  Tags = "tags",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  Trigger = "trigger",
  /** column name */
  Type = "type",
  /** column name */
  Updated = "updated",
}

/** aggregate var_pop on columns */
export type Task_Var_Pop_Fields = {
  __typename?: "task_var_pop_fields";
  max_retries?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "task" */
export type Task_Var_Pop_Order_By = {
  max_retries?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Task_Var_Samp_Fields = {
  __typename?: "task_var_samp_fields";
  max_retries?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "task" */
export type Task_Var_Samp_Order_By = {
  max_retries?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Task_Variance_Fields = {
  __typename?: "task_variance_fields";
  max_retries?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "task" */
export type Task_Variance_Order_By = {
  max_retries?: InputMaybe<Order_By>;
};

/** columns and relationships of "tenant" */
export type Tenant = {
  __typename?: "tenant";
  created: Scalars["timestamptz"];
  /** An array relationship */
  flow_groups: Array<Flow_Group>;
  /** An aggregate relationship */
  flow_groups_aggregate: Flow_Group_Aggregate;
  /** An array relationship */
  flows: Array<Flow>;
  /** An aggregate relationship */
  flows_aggregate: Flow_Aggregate;
  id: Scalars["uuid"];
  info?: Maybe<Scalars["jsonb"]>;
  name: Scalars["String"];
  /** An array relationship */
  projects: Array<Project>;
  /** An aggregate relationship */
  projects_aggregate: Project_Aggregate;
  settings: Scalars["jsonb"];
  slug: Scalars["String"];
  updated: Scalars["timestamptz"];
};

/** columns and relationships of "tenant" */
export type TenantFlow_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Flow_Group_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Group_Order_By>>;
  where?: InputMaybe<Flow_Group_Bool_Exp>;
};

/** columns and relationships of "tenant" */
export type TenantFlow_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flow_Group_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Group_Order_By>>;
  where?: InputMaybe<Flow_Group_Bool_Exp>;
};

/** columns and relationships of "tenant" */
export type TenantFlowsArgs = {
  distinct_on?: InputMaybe<Array<Flow_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Order_By>>;
  where?: InputMaybe<Flow_Bool_Exp>;
};

/** columns and relationships of "tenant" */
export type TenantFlows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flow_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Flow_Order_By>>;
  where?: InputMaybe<Flow_Bool_Exp>;
};

/** columns and relationships of "tenant" */
export type TenantInfoArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "tenant" */
export type TenantProjectsArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

/** columns and relationships of "tenant" */
export type TenantProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

/** columns and relationships of "tenant" */
export type TenantSettingsArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "tenant" */
export type Tenant_Aggregate = {
  __typename?: "tenant_aggregate";
  aggregate?: Maybe<Tenant_Aggregate_Fields>;
  nodes: Array<Tenant>;
};

/** aggregate fields of "tenant" */
export type Tenant_Aggregate_Fields = {
  __typename?: "tenant_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Tenant_Max_Fields>;
  min?: Maybe<Tenant_Min_Fields>;
};

/** aggregate fields of "tenant" */
export type Tenant_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenant_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tenant_Append_Input = {
  info?: InputMaybe<Scalars["jsonb"]>;
  settings?: InputMaybe<Scalars["jsonb"]>;
};

/** Boolean expression to filter rows from the table "tenant". All fields are combined with a logical 'AND'. */
export type Tenant_Bool_Exp = {
  _and?: InputMaybe<Array<Tenant_Bool_Exp>>;
  _not?: InputMaybe<Tenant_Bool_Exp>;
  _or?: InputMaybe<Array<Tenant_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  flow_groups?: InputMaybe<Flow_Group_Bool_Exp>;
  flows?: InputMaybe<Flow_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  info?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  projects?: InputMaybe<Project_Bool_Exp>;
  settings?: InputMaybe<Jsonb_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenant" */
export enum Tenant_Constraint {
  /** unique or primary key constraint */
  TenantPkey = "tenant_pkey",
  /** unique or primary key constraint */
  TenantSlugKey = "tenant_slug_key",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tenant_Delete_At_Path_Input = {
  info?: InputMaybe<Array<Scalars["String"]>>;
  settings?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tenant_Delete_Elem_Input = {
  info?: InputMaybe<Scalars["Int"]>;
  settings?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tenant_Delete_Key_Input = {
  info?: InputMaybe<Scalars["String"]>;
  settings?: InputMaybe<Scalars["String"]>;
};

export type Tenant_Id_Payload = {
  __typename?: "tenant_id_payload";
  id?: Maybe<Scalars["UUID"]>;
};

/** input type for inserting data into table "tenant" */
export type Tenant_Insert_Input = {
  created?: InputMaybe<Scalars["timestamptz"]>;
  flow_groups?: InputMaybe<Flow_Group_Arr_Rel_Insert_Input>;
  flows?: InputMaybe<Flow_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["uuid"]>;
  info?: InputMaybe<Scalars["jsonb"]>;
  name?: InputMaybe<Scalars["String"]>;
  projects?: InputMaybe<Project_Arr_Rel_Insert_Input>;
  settings?: InputMaybe<Scalars["jsonb"]>;
  slug?: InputMaybe<Scalars["String"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Tenant_Max_Fields = {
  __typename?: "tenant_max_fields";
  created?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Tenant_Min_Fields = {
  __typename?: "tenant_min_fields";
  created?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  updated?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "tenant" */
export type Tenant_Mutation_Response = {
  __typename?: "tenant_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Tenant>;
};

/** input type for inserting object relation for remote table "tenant" */
export type Tenant_Obj_Rel_Insert_Input = {
  data: Tenant_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Tenant_On_Conflict>;
};

/** on conflict condition type for table "tenant" */
export type Tenant_On_Conflict = {
  constraint: Tenant_Constraint;
  update_columns?: Array<Tenant_Update_Column>;
  where?: InputMaybe<Tenant_Bool_Exp>;
};

/** Ordering options when selecting data from "tenant". */
export type Tenant_Order_By = {
  created?: InputMaybe<Order_By>;
  flow_groups_aggregate?: InputMaybe<Flow_Group_Aggregate_Order_By>;
  flows_aggregate?: InputMaybe<Flow_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  info?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  projects_aggregate?: InputMaybe<Project_Aggregate_Order_By>;
  settings?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenant */
export type Tenant_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tenant_Prepend_Input = {
  info?: InputMaybe<Scalars["jsonb"]>;
  settings?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "tenant" */
export enum Tenant_Select_Column {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Info = "info",
  /** column name */
  Name = "name",
  /** column name */
  Settings = "settings",
  /** column name */
  Slug = "slug",
  /** column name */
  Updated = "updated",
}

/** input type for updating data in table "tenant" */
export type Tenant_Set_Input = {
  created?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  info?: InputMaybe<Scalars["jsonb"]>;
  name?: InputMaybe<Scalars["String"]>;
  settings?: InputMaybe<Scalars["jsonb"]>;
  slug?: InputMaybe<Scalars["String"]>;
  updated?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "tenant" */
export enum Tenant_Update_Column {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Info = "info",
  /** column name */
  Name = "name",
  /** column name */
  Settings = "settings",
  /** column name */
  Slug = "slug",
  /** column name */
  Updated = "updated",
}

export type Test_Cloud_Hook_Input = {
  cloud_hook_id: Scalars["UUID"];
  flow_run_id?: InputMaybe<Scalars["UUID"]>;
  state_type?: InputMaybe<Test_Cloud_Hook_State_Type>;
};

export enum Test_Cloud_Hook_State_Type {
  Failed = "FAILED",
  Running = "RUNNING",
  Scheduled = "SCHEDULED",
  Submitted = "SUBMITTED",
  Success = "SUCCESS",
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["timestamptz"]>;
  _gt?: InputMaybe<Scalars["timestamptz"]>;
  _gte?: InputMaybe<Scalars["timestamptz"]>;
  _in?: InputMaybe<Array<Scalars["timestamptz"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["timestamptz"]>;
  _lte?: InputMaybe<Scalars["timestamptz"]>;
  _neq?: InputMaybe<Scalars["timestamptz"]>;
  _nin?: InputMaybe<Array<Scalars["timestamptz"]>>;
};

export type Update_Agent_Config_Input = {
  /** The ID of the agent config to update */
  agent_config_id: Scalars["UUID"];
  /** A new name for this agent config */
  name?: InputMaybe<Scalars["String"]>;
  /** A settings to set for this agent config */
  settings?: InputMaybe<Scalars["JSON"]>;
};

export type Update_Flow_Project_Input = {
  /** The ID of the flow the update */
  flow_id: Scalars["UUID"];
  /** The new project ID to associate with this flow */
  project_id: Scalars["UUID"];
};

export type Update_Flow_Run_Heartbeat_Input = {
  flow_run_id: Scalars["UUID"];
};

export type Update_Task_Run_Artifact_Input = {
  data: Scalars["JSON"];
  task_run_artifact_id: Scalars["UUID"];
};

export type Update_Task_Run_Heartbeat_Input = {
  task_run_id: Scalars["UUID"];
};

export type Update_Tenant_Name_Input = {
  name: Scalars["String"];
  tenant_id: Scalars["UUID"];
};

export type Update_Tenant_Settings_Input = {
  settings?: InputMaybe<Scalars["JSON"]>;
  tenant_id: Scalars["UUID"];
};

export type Update_Tenant_Slug_Input = {
  slug: Scalars["String"];
  tenant_id: Scalars["UUID"];
};

export type Utility_Downstream_Tasks_Args = {
  depth_limit?: InputMaybe<Scalars["Int"]>;
  start_task_ids?: InputMaybe<Scalars["_uuid"]>;
};

/** columns and relationships of "utility.traversal" */
export type Utility_Traversal = {
  __typename?: "utility_traversal";
  depth?: Maybe<Scalars["Int"]>;
  /** An object relationship */
  task?: Maybe<Task>;
  task_id?: Maybe<Scalars["uuid"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
};

/** aggregated selection of "utility.traversal" */
export type Utility_Traversal_Aggregate = {
  __typename?: "utility_traversal_aggregate";
  aggregate?: Maybe<Utility_Traversal_Aggregate_Fields>;
  nodes: Array<Utility_Traversal>;
};

/** aggregate fields of "utility.traversal" */
export type Utility_Traversal_Aggregate_Fields = {
  __typename?: "utility_traversal_aggregate_fields";
  avg?: Maybe<Utility_Traversal_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Utility_Traversal_Max_Fields>;
  min?: Maybe<Utility_Traversal_Min_Fields>;
  stddev?: Maybe<Utility_Traversal_Stddev_Fields>;
  stddev_pop?: Maybe<Utility_Traversal_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Utility_Traversal_Stddev_Samp_Fields>;
  sum?: Maybe<Utility_Traversal_Sum_Fields>;
  var_pop?: Maybe<Utility_Traversal_Var_Pop_Fields>;
  var_samp?: Maybe<Utility_Traversal_Var_Samp_Fields>;
  variance?: Maybe<Utility_Traversal_Variance_Fields>;
};

/** aggregate fields of "utility.traversal" */
export type Utility_Traversal_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Utility_Traversal_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Utility_Traversal_Avg_Fields = {
  __typename?: "utility_traversal_avg_fields";
  depth?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "utility.traversal". All fields are combined with a logical 'AND'. */
export type Utility_Traversal_Bool_Exp = {
  _and?: InputMaybe<Array<Utility_Traversal_Bool_Exp>>;
  _not?: InputMaybe<Utility_Traversal_Bool_Exp>;
  _or?: InputMaybe<Array<Utility_Traversal_Bool_Exp>>;
  depth?: InputMaybe<Int_Comparison_Exp>;
  task?: InputMaybe<Task_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "utility.traversal" */
export type Utility_Traversal_Inc_Input = {
  depth?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "utility.traversal" */
export type Utility_Traversal_Insert_Input = {
  depth?: InputMaybe<Scalars["Int"]>;
  task?: InputMaybe<Task_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars["uuid"]>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Utility_Traversal_Max_Fields = {
  __typename?: "utility_traversal_max_fields";
  depth?: Maybe<Scalars["Int"]>;
  task_id?: Maybe<Scalars["uuid"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type Utility_Traversal_Min_Fields = {
  __typename?: "utility_traversal_min_fields";
  depth?: Maybe<Scalars["Int"]>;
  task_id?: Maybe<Scalars["uuid"]>;
  tenant_id?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "utility.traversal" */
export type Utility_Traversal_Mutation_Response = {
  __typename?: "utility_traversal_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Utility_Traversal>;
};

/** Ordering options when selecting data from "utility.traversal". */
export type Utility_Traversal_Order_By = {
  depth?: InputMaybe<Order_By>;
  task?: InputMaybe<Task_Order_By>;
  task_id?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
};

/** select columns of table "utility.traversal" */
export enum Utility_Traversal_Select_Column {
  /** column name */
  Depth = "depth",
  /** column name */
  TaskId = "task_id",
  /** column name */
  TenantId = "tenant_id",
}

/** input type for updating data in table "utility.traversal" */
export type Utility_Traversal_Set_Input = {
  depth?: InputMaybe<Scalars["Int"]>;
  task_id?: InputMaybe<Scalars["uuid"]>;
  tenant_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type Utility_Traversal_Stddev_Fields = {
  __typename?: "utility_traversal_stddev_fields";
  depth?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Utility_Traversal_Stddev_Pop_Fields = {
  __typename?: "utility_traversal_stddev_pop_fields";
  depth?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Utility_Traversal_Stddev_Samp_Fields = {
  __typename?: "utility_traversal_stddev_samp_fields";
  depth?: Maybe<Scalars["Float"]>;
};

/** aggregate sum on columns */
export type Utility_Traversal_Sum_Fields = {
  __typename?: "utility_traversal_sum_fields";
  depth?: Maybe<Scalars["Int"]>;
};

/** aggregate var_pop on columns */
export type Utility_Traversal_Var_Pop_Fields = {
  __typename?: "utility_traversal_var_pop_fields";
  depth?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Utility_Traversal_Var_Samp_Fields = {
  __typename?: "utility_traversal_var_samp_fields";
  depth?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Utility_Traversal_Variance_Fields = {
  __typename?: "utility_traversal_variance_fields";
  depth?: Maybe<Scalars["Float"]>;
};

export type Utility_Upstream_Tasks_Args = {
  depth_limit?: InputMaybe<Scalars["Int"]>;
  start_task_ids?: InputMaybe<Scalars["_uuid"]>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["uuid"]>;
  _gt?: InputMaybe<Scalars["uuid"]>;
  _gte?: InputMaybe<Scalars["uuid"]>;
  _in?: InputMaybe<Array<Scalars["uuid"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["uuid"]>;
  _lte?: InputMaybe<Scalars["uuid"]>;
  _neq?: InputMaybe<Scalars["uuid"]>;
  _nin?: InputMaybe<Array<Scalars["uuid"]>>;
};

export type Write_Run_Log_Input = {
  flow_run_id: Scalars["UUID"];
  info?: InputMaybe<Scalars["JSON"]>;
  level?: InputMaybe<Log_Level>;
  message?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  task_run_id?: InputMaybe<Scalars["UUID"]>;
  timestamp?: InputMaybe<Scalars["DateTime"]>;
};

export type Write_Run_Logs_Input = {
  logs: Array<Write_Run_Log_Input>;
};

export type MutationsCreateFlowRunMutationVariables = Exact<{
  context?: InputMaybe<Scalars["JSON"]>;
  id: Scalars["UUID"];
  flowRunName?: InputMaybe<Scalars["String"]>;
  parameters?: InputMaybe<Scalars["JSON"]>;
  scheduledStartTime?: InputMaybe<Scalars["DateTime"]>;
  runConfig?: InputMaybe<Scalars["JSON"]>;
  labels?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type MutationsCreateFlowRunMutation = {
  __typename?: "Mutation";
  create_flow_run?: {
    __typename?: "flow_run_id_payload";
    id?: any | null;
  } | null;
};

export type SubscribeSchematicsFlowListSubscriptionVariables = Exact<{
  limit: Scalars["Int"];
  offset: Scalars["Int"];
}>;

export type SubscribeSchematicsFlowListSubscription = {
  __typename?: "Subscription";
  flow: Array<{ __typename?: "flow"; id: any; name: string }>;
};

export type SubscribeSchematicsFlowByPkSubscriptionVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type SubscribeSchematicsFlowByPkSubscription = {
  __typename?: "Subscription";
  flow_by_pk?: { __typename?: "flow"; id: any; name: string } | null;
};

export type TaskInfoFragment = {
  __typename: "task";
  id: any;
  name?: string | null;
  max_retries?: number | null;
  retry_delay?: any | null;
  trigger?: string | null;
  type?: string | null;
  upstream_edges: Array<{
    __typename?: "edge";
    upstream_task: { __typename?: "task"; id: any; name?: string | null };
    downstream_task: { __typename?: "task"; id: any; name?: string | null };
  }>;
  downstream_edges: Array<{
    __typename?: "edge";
    upstream_task: { __typename?: "task"; id: any; name?: string | null };
    downstream_task: { __typename?: "task"; id: any; name?: string | null };
  }>;
};

export type TaskRunInfoFragment = {
  __typename: "task_run";
  id: any;
  serialized_state?: any | null;
  state?: string | null;
  state_message?: string | null;
  state_result?: any | null;
  state_timestamp?: any | null;
  start_time?: any | null;
  end_time?: any | null;
  map_index: number;
  name?: string | null;
  task: {
    __typename: "task";
    id: any;
    name?: string | null;
    max_retries?: number | null;
    retry_delay?: any | null;
    trigger?: string | null;
    type?: string | null;
    upstream_edges: Array<{
      __typename?: "edge";
      upstream_task: { __typename?: "task"; id: any; name?: string | null };
      downstream_task: { __typename?: "task"; id: any; name?: string | null };
    }>;
    downstream_edges: Array<{
      __typename?: "edge";
      upstream_task: { __typename?: "task"; id: any; name?: string | null };
      downstream_task: { __typename?: "task"; id: any; name?: string | null };
    }>;
  };
};

export type FlowInfoFragment = {
  __typename?: "flow";
  id: any;
  name: string;
  description?: string | null;
  serialized_flow?: any | null;
  parameters?: any | null;
};

export type FlowRunInfoFragment = {
  __typename: "flow_run";
  id: any;
  name?: string | null;
  state?: string | null;
  start_time?: any | null;
  end_time?: any | null;
  parameters?: any | null;
  flow: {
    __typename?: "flow";
    id: any;
    name: string;
    description?: string | null;
    serialized_flow?: any | null;
    parameters?: any | null;
  };
  task_runs: Array<{
    __typename: "task_run";
    id: any;
    serialized_state?: any | null;
    state?: string | null;
    state_message?: string | null;
    state_result?: any | null;
    state_timestamp?: any | null;
    start_time?: any | null;
    end_time?: any | null;
    map_index: number;
    name?: string | null;
    task: {
      __typename: "task";
      id: any;
      name?: string | null;
      max_retries?: number | null;
      retry_delay?: any | null;
      trigger?: string | null;
      type?: string | null;
      upstream_edges: Array<{
        __typename?: "edge";
        upstream_task: { __typename?: "task"; id: any; name?: string | null };
        downstream_task: { __typename?: "task"; id: any; name?: string | null };
      }>;
      downstream_edges: Array<{
        __typename?: "edge";
        upstream_task: { __typename?: "task"; id: any; name?: string | null };
        downstream_task: { __typename?: "task"; id: any; name?: string | null };
      }>;
    };
  }>;
};

export type GetSchematicsFlowRunListQueryVariables = Exact<{
  limit: Scalars["Int"];
  offset: Scalars["Int"];
}>;

export type GetSchematicsFlowRunListQuery = {
  __typename?: "Query";
  flow_run: Array<{
    __typename: "flow_run";
    id: any;
    name?: string | null;
    state?: string | null;
    start_time?: any | null;
    end_time?: any | null;
    parameters?: any | null;
    flow: {
      __typename?: "flow";
      id: any;
      name: string;
      description?: string | null;
      serialized_flow?: any | null;
      parameters?: any | null;
    };
    task_runs: Array<{
      __typename: "task_run";
      id: any;
      serialized_state?: any | null;
      state?: string | null;
      state_message?: string | null;
      state_result?: any | null;
      state_timestamp?: any | null;
      start_time?: any | null;
      end_time?: any | null;
      map_index: number;
      name?: string | null;
      task: {
        __typename: "task";
        id: any;
        name?: string | null;
        max_retries?: number | null;
        retry_delay?: any | null;
        trigger?: string | null;
        type?: string | null;
        upstream_edges: Array<{
          __typename?: "edge";
          upstream_task: { __typename?: "task"; id: any; name?: string | null };
          downstream_task: {
            __typename?: "task";
            id: any;
            name?: string | null;
          };
        }>;
        downstream_edges: Array<{
          __typename?: "edge";
          upstream_task: { __typename?: "task"; id: any; name?: string | null };
          downstream_task: {
            __typename?: "task";
            id: any;
            name?: string | null;
          };
        }>;
      };
    }>;
  }>;
};

export const FlowInfoFragmentDoc = gql`
  fragment flowInfo on flow {
    id
    name
    description
    serialized_flow
    parameters
  }
`;
export const TaskInfoFragmentDoc = gql`
  fragment taskInfo on task {
    id
    name
    max_retries
    retry_delay
    trigger
    type
    upstream_edges {
      upstream_task {
        id
        name
      }
      downstream_task {
        id
        name
      }
    }
    downstream_edges {
      upstream_task {
        id
        name
      }
      downstream_task {
        id
        name
      }
    }
    __typename
  }
`;
export const TaskRunInfoFragmentDoc = gql`
  fragment taskRunInfo on task_run {
    id
    serialized_state
    state
    state_message
    state_result
    state_timestamp
    start_time
    end_time
    map_index
    name
    task {
      ...taskInfo
    }
    __typename
  }
  ${TaskInfoFragmentDoc}
`;
export const FlowRunInfoFragmentDoc = gql`
  fragment flowRunInfo on flow_run {
    id
    name
    state
    start_time
    end_time
    parameters
    flow {
      ...flowInfo
    }
    task_runs {
      ...taskRunInfo
    }
    __typename
  }
  ${FlowInfoFragmentDoc}
  ${TaskRunInfoFragmentDoc}
`;
export const MutationsCreateFlowRunDocument = gql`
  mutation MutationsCreateFlowRun(
    $context: JSON
    $id: UUID!
    $flowRunName: String
    $parameters: JSON
    $scheduledStartTime: DateTime
    $runConfig: JSON
    $labels: [String!]
  ) {
    create_flow_run(
      input: {
        context: $context
        flow_id: $id
        flow_run_name: $flowRunName
        parameters: $parameters
        scheduled_start_time: $scheduledStartTime
        run_config: $runConfig
        labels: $labels
      }
    ) {
      id
    }
  }
`;
export const SubscribeSchematicsFlowListDocument = gql`
  subscription subscribeSchematicsFlowList($limit: Int!, $offset: Int!) {
    flow(
      limit: $limit
      offset: $offset
      order_by: { created: desc }
      where: { archived: { _eq: false }, name: { _neq: "Welcome Flow" } }
    ) {
      id
      name
    }
  }
`;
export const SubscribeSchematicsFlowByPkDocument = gql`
  subscription subscribeSchematicsFlowByPK($id: uuid!) {
    flow_by_pk(id: $id) {
      id
      name
    }
  }
`;
export const GetSchematicsFlowRunListDocument = gql`
  query getSchematicsFlowRunList($limit: Int!, $offset: Int!) {
    flow_run(
      limit: $limit
      offset: $offset
      order_by: { created: desc }
      where: { name: { _neq: "Welcome Flow" } }
    ) {
      ...flowRunInfo
    }
  }
  ${FlowRunInfoFragmentDoc}
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType
) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return {
    MutationsCreateFlowRun(
      variables: MutationsCreateFlowRunMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<MutationsCreateFlowRunMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<MutationsCreateFlowRunMutation>(
            MutationsCreateFlowRunDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "MutationsCreateFlowRun",
        "mutation"
      );
    },
    subscribeSchematicsFlowList(
      variables: SubscribeSchematicsFlowListSubscriptionVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<SubscribeSchematicsFlowListSubscription> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SubscribeSchematicsFlowListSubscription>(
            SubscribeSchematicsFlowListDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "subscribeSchematicsFlowList",
        "subscription"
      );
    },
    subscribeSchematicsFlowByPK(
      variables: SubscribeSchematicsFlowByPkSubscriptionVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<SubscribeSchematicsFlowByPkSubscription> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SubscribeSchematicsFlowByPkSubscription>(
            SubscribeSchematicsFlowByPkDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "subscribeSchematicsFlowByPK",
        "subscription"
      );
    },
    getSchematicsFlowRunList(
      variables: GetSchematicsFlowRunListQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<GetSchematicsFlowRunListQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetSchematicsFlowRunListQuery>(
            GetSchematicsFlowRunListDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "getSchematicsFlowRunList",
        "query"
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
