import { GraphQLClient } from "graphql-request";
import { assertIsDefined } from "helpers";
import React, { useEffect } from "react";
import useSWR from "swr";
import { useFlowMutators } from "../states/Flow";
import {
  GetSchematicsFlowRunListDocument,
  GetSchematicsFlowRunListQuery,
  getSdk,
} from "../types";

interface Props {
  children?: React.ReactNode;
}

const graphql_url = process.env.NEXT_PUBLIC_PREFECT_GRAPHQL_URL;
assertIsDefined(graphql_url);
const client = new GraphQLClient(graphql_url);
const sdk = getSdk(client);

const PrefectProvider: React.FC<Props> = (props: Props) => {
  const { setFlows } = useFlowMutators();

  const flowsHandler: () => Promise<GetSchematicsFlowRunListQuery> =
    async () => {
      return sdk.getSchematicsFlowRunList({ limit: 1, offset: 0 });
    };

  const flows = useSWR<GetSchematicsFlowRunListQuery>(
    GetSchematicsFlowRunListDocument,
    flowsHandler,
    {
      // refreshInterval: 1000,
    }
  );

  useEffect(() => {
    if (flows.data) setFlows(flows.data);
  }, [flows, setFlows]);

  return <>{props.children}</>;
};
PrefectProvider.displayName = "PrefectProvider";

export { PrefectProvider };
