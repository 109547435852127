import { useUser } from "@auth0/nextjs-auth0";
import ArrowRight from "@mui/icons-material/ArrowRight";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Image from "next/image";
import NextLink from "next/link";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { logoColor } from "../colors";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

interface MenuObj {
  title: string;
  path: string;
}

const Header: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { user, isLoading } = useUser();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const router = useRouter();

  // console.log("user: " + user);
  // console.log(user);
  // console.log("isLoading: " + isLoading);

  const isLoggedIn = user;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const menu_list: MenuObj[] = [
    // { title: "Watermark", path: "/watermark" },
    { title: "資料ダウンロード", path: "/downloads" },
    //{ title: "動作デモ", path: "/new" },
  ];

  return (
    <Box>
      <AppBar position="fixed" color="default">
        <Container>
          <Toolbar>
            <Box>
              <NextLink href={"/"} passHref>
                <Button sx={{ padding: "0px" }}>
                  <Image
                    src="/learningbook-logo.png"
                    width="235.9"
                    height="28"
                    alt="logo"
                  />
                </Button>
              </NextLink>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "end",
                paddingRight: "1rem",
              }}
            >
              {menu_list.map((menu) => (
                <NextLink key={menu.title} href={menu.path} passHref>
                  <Button
                    sx={{
                      paddingX: "15px",
                      color: router.pathname == menu.path ? logoColor : "black",
                      ":hover": { color: logoColor },
                    }}
                  >
                    <Typography textAlign="center" fontSize={"14px"}>
                      {menu.title}
                    </Typography>
                  </Button>
                </NextLink>
              ))}
            </Box>

            <Box
              sx={{ display: { xs: "none", md: "flex" }, paddingRight: "15px" }}
            >
              {isLoggedIn ? (
                <NextLink href={"/api/auth/logout"} passHref>
                  <Button>{"Logout"}</Button>
                </NextLink>
              ) : (
                <NextLink href={"/api/auth/login"} passHref>
                  <Button>{"Login"}</Button>
                </NextLink>
              )}
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {menu_list.map((menu, i) => (
                  <NextLink key={i} href={menu.path} passHref>
                    <MenuItem key={menu.title} onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">{menu.title}</Typography>
                    </MenuItem>
                  </NextLink>
                ))}
                {isLoggedIn ? (
                  <NextLink href={"/api/auth/logout"} passHref>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">{"Logout"}</Typography>
                    </MenuItem>
                  </NextLink>
                ) : (
                  <NextLink href={"/api/auth/login"} passHref>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">{"Login"}</Typography>
                    </MenuItem>
                  </NextLink>
                )}
              </Menu>
            </Box>
            <IconButton
              onClick={handleDrawerOpen}
              edge="start"
              size="large"
              sx={{ display: { xs: "flex", md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer anchor="right" open={open} onClose={handleDrawerClose}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} size="large">
            <ChevronRightIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menu_list.map((menu, i) => (
            <NextLink key={i} href={menu.path} passHref>
              <ListItem
                id={menu.path}
                button
                key={menu.title}
                component={Link}
                onClick={handleDrawerClose}
              >
                <ListItemIcon>
                  <ArrowRight />
                </ListItemIcon>
                <ListItemText primary={menu.title} />
              </ListItem>
            </NextLink>
          ))}
        </List>
        <Divider />
        <List>
          {isLoggedIn ? (
            <NextLink href={"/api/auth/logout"} passHref>
              <ListItem
                button
                key="root"
                component={Link}
                onClick={handleDrawerClose}
              >
                <ListItemIcon>
                  <ArrowRight />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            </NextLink>
          ) : (
            <NextLink href={"/api/auth/login"} passHref>
              <ListItem
                button
                key="root"
                component={Link}
                onClick={handleDrawerClose}
              >
                <ListItemIcon>
                  <ArrowRight />
                </ListItemIcon>
                <ListItemText primary="Login" />
              </ListItem>
            </NextLink>
          )}
        </List>
        <Divider />
        <List>
          <NextLink href={"/"} passHref>
            <ListItem
              button
              key="root"
              component={Link}
              onClick={handleDrawerClose}
            >
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
          </NextLink>
        </List>
      </Drawer>
    </Box>
  );
};
export default Header;
