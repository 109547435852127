import { UserProvider } from "@auth0/nextjs-auth0";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { AppProps } from "next/app";
import Head from "next/head";
import React from "react";
import { RecoilRoot } from "recoil";
import "../../styles/App.css";
import "../../styles/index.css";
import Layout from "../components/Layout";
import { PrefectProvider } from "../providers/PrefectProvider";
import theme from "../theme";

const App: React.FC<AppProps> = ({ Component, pageProps }): JSX.Element => {
  return (
    <React.StrictMode>
      <RecoilRoot>
        <Head>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>
        <UserProvider>
          <PrefectProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Layout>
                <Component {...pageProps} />
              </Layout>
            </ThemeProvider>
          </PrefectProvider>
        </UserProvider>
      </RecoilRoot>
    </React.StrictMode>
  );
};

export default App;
