import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { createTheme } from "@mui/material/styles";

const breakpoints = createBreakpoints({
  values: { xs: 0, sm: 768, md: 992, lg: 1200, xl: 1536 },
});

const theme = createTheme({
  breakpoints: breakpoints,
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          [breakpoints.up("xs")]: {
            paddingLeft: "15px",
            paddingRight: "15px",
          },
          [breakpoints.up("sm")]: {
            width: "750px",
          },
          [breakpoints.up("md")]: {
            width: "970px",
          },
          [breakpoints.up("lg")]: {
            width: "1170px",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "0 0 3px rgb(0 0 0 / 20%)",
          backgroundColor: "white",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          padding: "11.5px",
          [breakpoints.up("sm")]: {
            paddingLeft: "0px",
            paddingRight: "0px",
            minHeight: "71px",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: [
            "Roboto",
            "Droid Sans",
            "游ゴシック",
            "YuGothic",
            "ヒラギノ角ゴ ProN W3",
            "Hiragino Kaku Gothic ProN",
            "メイリオ",
            "Meiryo",
            "ＭＳ Ｐゴシック",
            "sans-serif",
          ].join(","),
          fontWeightRegular: 400,
          fontSize: "16px",
          lineHeight: "1.8",
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
});

export default theme;
