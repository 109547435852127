import React from "react";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { FlowRunInfoFragment, GetSchematicsFlowRunListQuery } from "../types";

export type FlowState = {
  flows: FlowRunInfoFragment[];
};

const flowRecoilState = atom<FlowState>({
  key: "flowState",
  default: {
    flows: [],
  },
});

export const useFlowState = () => {
  return useRecoilValue(flowRecoilState);
};

export const useFlowMutators = () => {
  const setState = useSetRecoilState(flowRecoilState);

  const setFlows = React.useCallback(
    (flowQuery: GetSchematicsFlowRunListQuery) => {
      setState({ flows: flowQuery.flow_run });
    },
    [setState]
  );

  return { setFlows };
};
