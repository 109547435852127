export const themeColor = (i: number, alpha = 1.0): string => {
  const color = (r: number, g: number, b: number, a: number) =>
    "rgba(" + r + "," + g + "," + b + "," + a + ")";

  switch (i % 7) {
    case 0:
      return color(217, 38, 118, alpha);
    case 1:
      return color(88, 24, 216, alpha);
    case 2:
      return color(216, 88, 24, alpha);
    case 3:
      return color(23, 207, 145, alpha);
    case 4:
      return color(236, 199, 14, alpha);
    case 5:
      return color(42, 176, 203, alpha);

    case 6:
      return color(55, 66, 98, alpha);
  }

  return color(15, 126, 190, alpha);
};

export const logoColor = "#0086CC";
